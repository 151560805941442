.play-button {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary-color);
    transition: background 300ms;

    @media screen and (max-width: 768px) { width: 44px; height: 44px; }

    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 53%;
        border-width: 8px 0 8px 13.9px;
        border-color: transparent transparent transparent var(--primary-light-color);
        border-style: solid;
        transform: translate(-50%, -50%);
        transition: border-color 300ms;
    }

    &--light {
        background: var(--primary-light-color);
    }

    &--light:before {
        border-color: transparent transparent transparent var(--secondary-color);
    }

    &--light:hover {
        background: var(--secondary-color);
    }
    
    &--light:hover:before {
        border-color: transparent transparent transparent var(--primary-light-color);
    }
}