// ----------
// La page d'actus détail
// ----------

.page--news-detail {
    $prefix: ".page";

    #{$prefix}__container--similar-news {
        padding: 0 var(--x-spacing-200);
        margin-top: var(--y-spacing-190);
        padding-bottom: var(--y-spacing-150);
    }
}