:root{
    --share__color: var(--primary-color);
    --share__color-hover: var(--secondary-color);
}

.share {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: var(--share__color);
    transition: color 0.3s;
    font-family: var(--font-family-1);
    font-size: 1.4rem;
    font-weight: 700;

    &__icon {
        width: 12px;
        height: 12px;
        fill: var(--share__color);
        transition: fill 0.3s;
        margin-right: 15px;
        position: relative;
        top: -1px;
    }

    &:hover {
        color: var(--share__color-hover);

        .share__icon{
            fill: var(--share__color-hover);
        }
    }
}

.share-variant {
    position: absolute;
    top: 20px;
    right: var(--x-spacing-100);
    z-index: 10;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;

    @media screen and (max-width: 1600px) { right: var(--x-spacing-90); }
    @media screen and (max-width: 600px) { display: none; }
    
    &[data-fire] {
        opacity: 1;
        transform: none;
    }
    
    &__share-button {
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--primary-color);
        transition: color 300ms;
        display: flex;
    
        &:hover { color: var(--secondary-color); }
    }

    .header--light &__share-button {
        color: var(--primary-light-color);

        &:hover { color: var(--secondary-color); }
    }
    
    &__share-button-svg {
        width: 18px;
        height: 18px;
        fill: var(--primary-color);
        transition: fill 300ms;
        position: relative;
        top: -2px;
    }

    .header--light &__share-button-svg {
        fill: var(--primary-light-color);
    }
    
    &__share-button:hover &__share-button-svg { fill: var(--secondary-color); }
    
    &__share-button-span { 
        line-height: 18px;
        margin-left: 20px; 
    }
}
