//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.field--textarea {
    --field-label-padding: 0 0 5px 0;

    --field-label-font-family: var(--font-family-1);
    --field-label-font-size: 1.5rem;
    --field-label-font-weight: normal;
    --field-label-line-height: 1;
    --field-label-text-transform: inherit;
    --field-label-letter-spacing: inherit;
    --field-label-color: var(--primary-color);

    --field-note-color: var(--other-1);

    --field-padding: 8px 0;

    --field-font-family: var(--font-family-1);
    --field-font-size: 1.7rem;
    --field-font-weight: normal;
    --field-line-height: 1.6;
    --field-text-transform: inherit;
    --field-letter-spacing: inherit;
    
    --field-color: var(--primary-color);
    --field-border-color: var(--primary-color);
    --field-background: transparent;
    
    --field-background-inactive: transparent;
    --field-color-inactive: var(--secondary-light-color);
    --field-border-color-inactive: var(--primary-color);

    --field-error-color: var(--secondary-color);

    @media screen and (max-width: 600px) { --field-padding: 15px 0; }
}


// ------------
// Un textarea
// ------------
.field {
    &--textarea &__input {
        width: 100%;
        padding: var(--field-padding);
        font-family: var(--field-font-family);
        font-size: var(--field-font-size);
        font-weight: var(--field-font-weight);
        line-height: var(--field-line-height);
        text-transform: var(--field-text-transform);
        letter-spacing: var(--field-letter-spacing);
        color: var(--field-color);
        background: var(--field-background-inactive);
        border: 0;
        -webkit-appearance: none;
        border-radius: 0;
        -webkit-tap-highlight-color: var(--primary-color);
        box-shadow: inset 0 -1px 0 0 var(--field-border-color-inactive);
        transition: box-shadow 0.3s;
        outline: none;
        resize: none;
        min-height: 50px;
    }

    &--textarea &__label {
        display: block;
        padding: var(--field-label-padding);
        font-family: var(--field-label-font-family);
        font-size: var(--field-label-font-size);
        font-weight: var(--field-label-font-weight);
        line-height: var(--field-label-line-height);
        text-transform: var(--field-label-text-transform);
        letter-spacing: var(--field-label-letter-spacing);
        color: var(--field-label-color);
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    &--textarea &__input.error { box-shadow: inset 0 -1px 0 0 var(--field-error-color); }

    &--textarea &__input:focus { box-shadow: inset 0 -2px 0 0 var(--field-border-color); }

    &--textarea &__input.error.focus { box-shadow: inset 0 -2px 0 0 var(--field-error-color); }
}