// -------------------------
// Taille globale des fonts
// -------------------------
html {
    font-size: 62.5%;
    overflow-y: scroll;
}

body {
    margin-right: 0 !important;
    position: relative;
}

.page-without-footer {
    position: relative;
}


// ------
// Liens
// ------
a {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
}


// -------
// Images
// -------
picture {
    display: block;
    font-size: 0;

    img { width: 100%; height: 100%; }

    [data-object-fit='cover'] {
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }
}

.floating {
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.floating:hover {
    animation-play-state: paused;
}

@keyframes floating{
    0%,0.001% { transform: translateY(-5px); }
    50%       { transform: translateY(5px); }
    100%      { transform: translateY(-5px); }
}