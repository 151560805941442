.overlay-menu .header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.overlay-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 49;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }

    &__container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 100%;
        background: var(--primary-light-color);
        overflow-y: hidden;
		overflow-x: hidden;
        scrollbar-width: none;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        transition: bottom 800ms var(--ease-out);
    }

    &__container::-webkit-scrollbar {
        display: none;
    }

    &__grid {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        min-height: 100vh;
        padding-top: calc(var(--header--height) + 90px);
        padding-right: var(--x-spacing-100);
        padding-left: var(--x-spacing-100);
        padding-bottom: 120px;

        @media screen and (max-width: 1400px) {
            padding-top: calc(var(--header--height) + 90px);
            padding-bottom: 100px;
        }
        @media screen and (max-width: 1200px) {
            padding-top: calc(var(--header--height) + 80px);
            padding-bottom: 90px;
        }
        @media screen and (max-width: 950px) {
            padding-top: calc(var(--header--height) + 70px);
            padding-bottom: 40px;
        }
        @media screen and (max-width: 768px) {
            padding-top: calc(var(--header--height) + 60px);
            padding-bottom: 60px;
        }
        @media screen and (max-width: 600px) {
            display: block;
            padding-bottom: 50px;
        }
        @media screen and (max-width: 500px) {
            padding-bottom: 40px;
        }
    }

    // Le menu secondaire
    &__secondary-nav {
        width: 300px;
        opacity: 0;
        transform: translateY(50px);

        @media screen and (max-width: 1300px) { width: 270px; }
        @media screen and (max-width: 1200px) { width: 230px; }
        @media screen and (max-width: 1100px) { width: 200px; }
        @media screen and (max-width: 950px) {
            margin-top: 50px;
            width: 100%;
            order: 2;
        }
        @media screen and (max-width: 768px) { margin-top: 40px; }
        @media screen and (max-width: 600px) { margin-top: 0px; }
    }

    &__secondary-link-wrapper:not(:last-child) {
        margin-bottom: 10px;
    }

    &__secondary-link {
        font-family: var(--font-family-1);
        font-size: 1.5rem;
        line-height: 1.5;
        color: var(--primary-color);
        transition: color 300ms;
    }

    &__secondary-link:hover {
        color: var(--secondary-color);
    }

    // Le menu principal
    &__primary-nav {
        flex: 1;
        opacity: 0;
        transform: translateY(50px);

        @media screen and (max-width: 950px) {
            width: 100%;
            flex: initial;
            order: 1;
        }
        @media screen and (max-width: 600px) { margin-top: 40px; }
    }

    &__primary-nav-title {
        display: block;
        margin-bottom: 30px;    
        font-family: var(--font-family-1);
        font-size: 1.5rem;
        line-height: 1.5;
        color: var(--secondary-color);

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 700px) { margin-bottom: 20px; }
    }

    &__primary-nav-grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: 380px;

        @media screen and (max-width: 700px) { max-height: 100%; }
    }
    
    &__primary-link-wrapper {
        width: 50%;
        padding-right: 50px;
        margin-bottom: 15px;

        @media screen and (max-width: 1200px) { padding-right: 40px; }
        @media screen and (max-width: 1100px) { padding-right: 30px; }
        @media screen and (max-width: 700px) { padding-right: 0; width: 100%; }

        &--mobile {
            @media screen and (min-width: 601px) { display: none; }
        }
    }

    &__primary-link-wrapper:nth-child(5n+5) {
        margin-bottom: 0;

        @media screen and (max-width: 700px) { margin-bottom: 15px; }
    }

    &__primary-link-wrapper:last-child {
        margin-bottom: 0;
    }
    
    &__primary-link {
        display: inline-flex;
    }

    &__primary-link-svg-wrapper {
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        border: 1px solid #c8d1d1;
        border-radius: 50%;
        transition: border-color 400ms, background-color 400ms;

        @media screen and (max-width: 600px) { margin-right: 15px; }
    }

    &__primary-link:hover &__primary-link-svg-wrapper {
        border-color: var(--secondary-color);
        background-color: var(--secondary-color);
    }

    &__primary-link-svg {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: none;
        stroke: var(--primary-color);
        transition: stroke 400ms;
    }

    &__primary-link:hover &__primary-link-svg {
        stroke: var(--primary-light-color);
    }

    &__primary-link-text {
        flex: 1;
        margin-top: 6px;
        font-family: var(--font-family-2);
        font-size: 3rem;
        line-height: 1.2;
        color: var(--primary-color);
        transition: color 400ms;
        
        @media screen and (max-width: 1300px) { font-size: 2.8rem; margin-top: 7px; }
        @media screen and (max-width: 1200px) { font-size: 2.6rem; margin-top: 8px; }
        @media screen and (max-width: 1100px) { font-size: 2.5rem; }
        @media screen and (max-width: 600px)  { font-size: 2.4rem; }
        @media screen and (max-width: 500px)  { font-size: 2.3rem; }
        @media screen and (max-width: 400px)  { font-size: 2.2rem; }
    }

    &__primary-link:hover &__primary-link-text {
        color: var(--secondary-color);
    }
}

.show-overlay-menu:not(.opening-overlay-menu):not(.closing-overlay-menu) {
    .overlay-menu .overlay-menu__container {
        overflow-y: auto;
    }
}

.show-overlay-menu {
    .menu-button {
        &__line--1 { width: 0%; top: calc(50% - 1px); left: 50%; opacity: 0; }
        &__line--2 { transform: rotate(45deg); width: 100%; }
        &__line--3 { transform: rotate(-45deg); width: 100%; }
        &__line--4 { width: 0%; bottom: calc(50% - 1px); left: 50%; opacity: 0; }
    }

    .overlay-menu {
        visibility: visible;
        pointer-events: all;

        &__background {
            background: rgba(#000, 0.4);
        }
        
        &__container {
            bottom: 0%;
        }

        &__secondary-nav,
        &__primary-nav {
            opacity: 1;
            transform: none;
            transition: opacity 600ms linear, transform 600ms var(--ease-out);
        }

        &__secondary-nav {
            transition-delay: 200ms;

            @media screen and (max-width: 950px) { transition-delay: 300ms; }
        }

        &__primary-nav {
            transition-delay: 300ms;

            @media screen and (max-width: 950px) { transition-delay: 300ms; }
        }
    }
}

.show-overlay-menu.closing-overlay-menu {
    .menu-button {
        &__line--1 { top: 0px; width: 100%; opacity: 1; left: 0%; }
        &__line--2, &__line--3 { top: 50%; transform: none; }
        &__line--4 { bottom: 0px; width: 100%; opacity: 1; left: 0%; }
    }

    .overlay-menu {
        visibility: visible;
        pointer-events: all;

        &__background {
            background: rgba(#000, 0);
        }

        &__container {
            bottom: 100%;
        }

        &__secondary-nav,
        &__primary-nav {
            opacity: 1;
            transform: none;
            transition: opacity 300ms linear, transform 300ms var(--ease-out);
            transition-delay: 0ms;
        }
    }
}