//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.title--huge {

}


// --------------
// Un huge title
// --------------
.huge-title {
    font-family: var(--font-family-2);
    font-size: 11rem;
    line-height: 1.1;
    color: var(--primary-color);

    @media screen and (max-width: 1400px) { font-size: 10rem; }
    @media screen and (max-width: 1300px) { font-size: 9rem; }
    @media screen and (max-width: 1200px) { font-size: 8rem; }
    @media screen and (max-width: 1024px) { font-size: 7rem; }
    @media screen and (max-width: 768px)  { font-size: 6.5rem; }
    @media screen and (max-width: 600px)  { font-size: 6rem; }
    @media screen and (max-width: 500px)  { font-size: 5.5rem; }
    @media screen and (max-width: 450px)  { font-size: 5rem; }
    @media screen and (max-width: 400px)  { font-size: 4.5rem; }

    &--bold { font-weight: 700; }
    &--white { color: var(--primary-light-color); }
}