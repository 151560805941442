:root {
    --dynamic-main-color: var(--primary-color);
    --dynamic-main-color-rgb: var(--primary-rgb-color);

    --dynamic-p-font-line-height: 1.6em; //Doit inclure le 'em' autrement les bullets des listes ne seront pas alignées

    --dynamic-ol-indent: 50px;
    --dynamic-ol-indent-last: 40px;
    --dynamic-ul-indent: 20px;
    --dynamic-ul-bullet-color: var(--secondary-color);

    --dynamic-table-header-background: var(--primary-color);
    --dynamic-table-header-color: #fff;
    --dynamic-table-border-color: var(--other-3);
    --dynamic-table-secondary-header-background: var(--secondary-light-color);

    --dynamic-note-color: var(--other-1);
    --dynamic-quotes-color: var(--secondary-color);

    @media screen and (max-width: 768px) {
        --dynamic-ol-indent: 25px;
        --dynamic-ol-indent-last: 25px;
    }
}


.dynamic{

    > :first-child { margin-top: 0 !important; }
    > :last-child { margin-bottom: 0 !important; }

    h1{
        font-family: var(--font-family-2);
        font-size: 11rem;
        font-weight: 700;
        line-height: 1.1;
        color: var(--primary-color);
        margin-top: 100px;
        margin-bottom: 100px;

        @media screen and (max-width: 1400px) { font-size: 10rem; }
        @media screen and (max-width: 1300px) { font-size: 9rem; }
        @media screen and (max-width: 1200px) { font-size: 8rem; }
        @media screen and (max-width: 1024px) { 
            font-size: 7rem; 
            margin-top: 50px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 768px)  { font-size: 6.5rem; }
        @media screen and (max-width: 600px)  { font-size: 6rem; }
        @media screen and (max-width: 500px)  { font-size: 5.5rem; }
        @media screen and (max-width: 450px)  { font-size: 5rem; }
        @media screen and (max-width: 400px)  { font-size: 4.5rem; }
    }

    h2{
        font-family: var(--font-family-2);
        font-size: 5rem;
        font-weight: 700;
        line-height: 1.15;
        color: var(--primary-color);
        margin-top: 65px;
        margin-bottom: 30px;

        @media screen and (max-width: 1400px) { font-size: 4.8rem; }
        @media screen and (max-width: 1300px) { font-size: 4.5rem; }
        @media screen and (max-width: 1200px) { font-size: 4.3rem; }
        @media screen and (max-width: 1024px) { font-size: 4.2rem; }
        @media screen and (max-width: 768px)  { font-size: 4rem; }
        @media screen and (max-width: 600px)  { font-size: 3.8rem; }
        @media screen and (max-width: 500px)  { font-size: 3.5rem; }
        @media screen and (max-width: 450px)  { font-size: 3.2rem; }
        @media screen and (max-width: 400px)  { font-size: 3rem; }
    }

    h3{
        font-family: var(--font-family-1);
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.3;
        color: var(--primary-color);
        margin-top: 65px;
        margin-bottom: 30px;

        @media screen and (max-width: 1400px) { font-size: 2.8rem; }
        @media screen and (max-width: 1200px) { font-size: 2.5rem; }
        @media screen and (max-width: 1024px) { font-size: 2.3rem; }
        @media screen and (max-width: 768px) { font-size: 2.2rem; }
        @media screen and (max-width: 600px)  { font-size: 2rem; }
    }

    h4{
        font-family: var(--font-family-1);
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.4;
        color: var(--primary-color);
        margin-top: 65px;
        margin-bottom: 30px;

        @media screen and (max-width: 1400px) { font-size: 2.3rem; }
        @media screen and (max-width: 1024px) { font-size: 2.2rem; }
        @media screen and (max-width: 768px) { font-size: 2rem; }
        @media screen and (max-width: 600px)  { font-size: 1.8rem; }
    }

    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6{
        margin-top: 0;
    }

    p{
        --dynamic-p-margin: 25px;
        --dynamic-p-margin-under-button: 5px; //Marge sous les boutons si ils tombent sur 2 lignes
        --dynamic-p-margin-button: 35px; //Plus grosse marge pour paragraphe contenant des boutons

        font-family: var(--font-family-1);
        font-size: 1.7rem;
        line-height: 1.6;
        color: var(--primary-color);
        margin-top: var(--dynamic-p-margin);
        margin-bottom: var(--dynamic-p-margin);

        &.intro{
            font-family: var(--font-family-2);
            font-size: 3rem;
            line-height: 1.6;
            color: var(--primary-color);
            margin-top: 65px;
            margin-bottom: 65px;

            @media screen and (max-width: 1400px) { font-size: 2.8rem; }
            @media screen and (max-width: 1200px) { font-size: 2.6rem; }
            @media screen and (max-width: 1024px) { font-size: 2.4rem; }
            @media screen and (max-width: 768px)  { font-size: 2.2rem; }
            @media screen and (max-width: 600px)  { font-size: 2.1rem; }
            @media screen and (max-width: 450px)  { font-size: 2rem; }
        }

        //40px
        &.buttonMargin{
            margin-bottom: calc(var(--dynamic-p-margin) - var(--dynamic-p-margin-under-button));
            .button { // Ajouter une marge sous les boutons
                margin-bottom: var(--dynamic-p-margin-under-button)
            }
            &--first{ margin-top: var(--dynamic-p-margin-button) }
            &--last{ margin-bottom: calc(var(--dynamic-p-margin-button) - var(--dynamic-p-margin-under-button) ) }
        }
    }

    strong,b{
        font-weight: 700;
    }

    em,i{
        font-style: italic;
    }

    sup{
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: -0.3em;
    }
    sub{
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: 0.3em; 
    }

    a:not(.button):not(.a-style-document) {
        color: var(--dynamic-main-color);
        text-decoration: none;
        box-shadow: inset 0 -0.4em 0 0 rgba(var(--dynamic-main-color-rgb),0.2);
        transition: box-shadow 0.3s;
        display: inline;

        &:hover{
            box-shadow: inset 0 -1.1em 0 0 rgba(var(--dynamic-main-color-rgb),0.2);
        }

        //Icones liens externes et téléchargement
        svg{
            margin-left: 8px;
            width: 11px;
            height: 11px;
            stroke: var(--dynamic-main-color);
            fill: var(--dynamic-main-color);
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".zip"]{
            svg{
                width: 12px;
                height: 12px;
            }
        }
    }

    .a-style-document {
        font-family: var(--font-family-1);
        font-size: var(--document__in-rubric-font-size);
        font-weight: 600;
        line-height: 1.6;
        color: var(--dynamic-main-color);
        transition: color 300ms;
        text-decoration: none;
        display: block;
        position: relative;
        padding: 25px 0;
        min-height: var( --document__in-rubric-height);
        border-top: 1px solid var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
        display: flex;
        align-items: center;
        text-decoration: none;
        @media screen and (max-width: 600px) { padding: 20px 30px; }
        @media screen and (max-width: 500px) { padding: 20px 0; }

        &::before {
            content: "";
            width: 18px;
            height: 18px;
            background: url('../medias/images/icons/download-case-icon-1.svg') no-repeat;
            opacity: 1;
            transition: opacity 300ms;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);

            @media screen and (max-width: 600px) { right: 30px; }
            @media screen and (max-width: 500px) { right: 15px; }
        }

        &::after {
            content: "";
            width: 18px;
            height: 18px;
            background: url('../medias/images/icons/download-case-icon-2.svg') no-repeat;
            opacity: 0;
            transition: opacity 300ms;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);

            @media screen and (max-width: 600px) { right: 30px; }
            @media screen and (max-width: 500px) { right: 15px; }
        }

        &:hover {
            color: var(--document__color-hover);

            &::before { opacity: 0; }
            &::after { opacity: 1; }
        }

        svg {
            display: none;
        }
    }

    .a-style-document + .a-style-document {
        border-top: 0;
    }
    // li::before {
    //     display: none;
    // }



    .a-style-document + .a-style-document { border-top: none; }

    p > .a-style-document { font-size: 17px; }

    ol,
    ul{
        margin-top: 30px;
        margin-bottom: 30px;
        li{
            &:not(:first-child){ margin-top: 25px; }

            font-family: var(--font-family-1);
            font-size: 1.7rem;
            line-height: 1.6;
            color: var(--primary-color);

            ol,ul{
                margin-top: 20px;
                margin-bottom: 0;
                width: 100%;
                li{
                    &:not(:first-child){ margin-top: 20px; }
                }
            }
        }
    }

    ol{
        list-style: none;
        counter-reset: li;
        & > li{
            position: relative;
            padding-left: var(--dynamic-ol-indent);
            &::before{
                content: counter(li) ".";
                counter-increment: li;
                font-size: 1.7rem;
                color: var(--secondary-color);
                position: absolute;
                display: inline-block;
                left: 0;
                top: calc(var(--dynamic-p-font-line-height) / 2);
                transform: translate(0, -50%);

                // @media screen and (max-width: 425px) { font-size: 1.5rem; }
                @media screen and (max-width: 600px) { top: 0.75em; }
            }

            ol > li{ // <ol> 2e niveau
                &::before{ content: counter(li, lower-alpha) "."; }
                ol > li{ // <ol> 3e niveau
                    &::before{ content: counter(li, lower-roman) "."; }
                    padding-left: var(--dynamic-ol-indent-last);
                }
            }
        }
    }

    ul{
        & > li{
            padding-left: var(--dynamic-ul-indent);
            position: relative;
            &::before{
                content: '';
                width: 6px;
                height: 6px;
                border: 1px solid var(--dynamic-ul-bullet-color);
                background:var(--dynamic-ul-bullet-color);
                position: absolute;
                left: 0;
                transform: translate(0,-50%);
                border-radius: 50%;
                box-sizing: border-box;
                top: calc(var(--dynamic-p-font-line-height) / 2);
            }

            & > ul{
                li{
                    &::before{ background: transparent; }
                    & > ul{
                        li{
                            &::before{ background: var(--dynamic-ul-bullet-color); }
                        }
                    }
                }
            }
        }
    }

    blockquote{
        position: relative;
        margin-top: 35px;
        margin-bottom: 35px;
        padding-left: 60px;

        @media screen and (max-width: 600px) { padding-left: 55px; }
        @media screen and (max-width: 500px) { padding-left: 50px; }
        @media screen and (max-width: 400px) { padding-left: 45px; }

        &::before {
            content: "“";
            font-family: var(--font-family-2);
            font-size: 8.7rem;
            font-weight: 700;
            color: var(--dynamic-quotes-color); 
            position: absolute;
            top: 0;
            left: 0;

            @media screen and (max-width: 600px) { font-size: 8rem; }
            @media screen and (max-width: 500px) { font-size: 7.5rem; }
            @media screen and (max-width: 400px) { font-size: 7rem; }
        }

        p{
            font-family: var(--font-family-2);
            font-size: 2.5rem;
            line-height: 1.6em;
            padding-top: 5px;

            @media screen and (max-width: 1024px) { font-size: 2rem; }
            @media screen and (max-width: 768px) { font-size: 1.9rem; }
        }
    }

    .emphasis{
        border: 1px solid var(--dynamic-table-border-color);
        margin-top: 30px;
        margin-bottom: 65px;
        padding: 45px 40px;

        & > :first-child{ margin-top: 0; }
        & > :last-child{ margin-bottom: 0}
    }

    .table-wrapper{
        overflow-x: auto;
        margin-top: 30px;
        margin-bottom: 65px;

        table{
            overflow-x: hidden;
            width: 100%;
            min-width: 600px;
            border-collapse: collapse;
            tr{
                th,td{
                    font-family: var(--font-family-1); 
                    padding: 20px 35px;
                    line-height: 1.5625em; // 25px/16px
                    border: 1px solid var(--dynamic-table-border-color);
                    font-size: 1.7rem;
                    vertical-align: middle;
                    color: var(--dynamic-main-color);

                    @media screen and (max-width: 768px) { font-size: 1.6rem; }
                    @media screen and (max-width: 375px) { font-size: 1.5rem; }

                    & > :first-child{ margin-top: 0; }
                    //& > :last-child{ margin-bottom: 0}

                    & > p, & > pre, & > strong, & > em, & > a, & > span, & > sub, & > sup, & > ul, & > ol {
                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }
                th{
                    background-color: var(--dynamic-table-header-background);
                    font-weight: normal;
                    color: var(--dynamic-table-header-color);
                    border-top-color: var(--dynamic-table-header-background);
                    border-bottom-color: var(--dynamic-table-header-background);
                    text-align: left;

                    &:first-child{ border-left-color: var(--dynamic-table-header-background)}
                    &:last-child{ border-right-color: var(--dynamic-table-header-background)}
                }
                td{
                  &.secondary-header{
                    background-color: var(--dynamic-table-secondary-header-background);
                  }  
                }
            }
        }
    }

    pre{
        font-family: var(--font-family-1);
        white-space: normal;
        font-size: 1.4rem;
        line-height: 1.7;
        color: var(--dynamic-note-color);
        margin-top: 25px;
        margin-bottom: 25px;

        @media screen and (max-width: 600px) { font-size: 1.2rem; }
    }

    .table-wrapper,
    .emphasis{
        // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
        & + pre{
            margin-top: -45px;
        }
        // Modifier la marge pour les tableaux sous les tableaux
        & + .table-wrapper,
        & + .emphasis{
            margin-top: -35px;
        }
    }

    picture {
        width: 780px;
        height: 450px;
        margin-top: 110px;
    }

    /* ----- Image - No Caption ----- */

	img {

        position: relative;
        max-width: 100%;

        &.fr-dib {

			/* block */

            display: block;
			margin: 30px auto;
            float: none;
            vertical-align: top;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {

			/* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            margin: 10px 20px;
            max-width: calc(100% - (2 * 20px));

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
                max-width: calc(100% - 20px);
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
                max-width: calc(100% - 20px);
            }
        }
    }

	/* ----- Image - With Caption ----- */

    .fr-img-caption {

		img {
			width: 100%;
		}

        &.fr-dib {

			/* block */

            display: block;
			margin-left: auto;
			margin-right: auto;
            float: none;
            vertical-align: middle;
			text-align: center;

            &.fr-fil {
                margin-left: 0;
				text-align: left;
            }

            &.fr-fir {
                margin-right: 0;
				text-align: right;
            }

			.fr-img-wrap {

				.fr-inner {

                    font-family: var(--font-family-1);
                    font-size: 1.4rem;
                    line-height: 1.7;
                    color: var(--dynamic-note-color);
                    margin-top: 25px;
                    margin-bottom: 25px;
				}
			}
        }

        &.fr-dii {

			/* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            margin: 10px 20px;
            max-width: calc(100% - (2 * 20px));
			text-align: center;

            &.fr-fil {
                float: left;
                margin-left: 0;
				text-align: left;
                max-width: calc(100% - 20px);
            }

            &.fr-fir {
                float: right;
				margin-right: 0;
                text-align: right;
                max-width: calc(100% - 20px);
            }
        }
    }

    video {
        max-width: 560px;
        max-height: 315px;
        width: 100%;
    }

    .videoWrapper {
        max-width: 700px;

        .ytVideo {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
    
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

}