//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.footer {

}


// ----------
// Le footer
// ----------
.footer {
    &__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 80px var(--x-spacing-110) 100px;
        background: var(--primary-color);

        @media screen and (max-width: 1024px) { padding-top: 70px; padding-bottom: 70px; }
        @media screen and (max-width: 950px) { padding-top: 70px; padding-bottom: 60px; }
        @media screen and (max-width: 768px)  { padding-top: 60px; padding-bottom: 45px; }
        @media screen and (max-width: 600px)  { padding-top: 50px; padding-bottom: 40px; }
    }

    &__logo-item {
        width: 150px;

        @media screen and (max-width: 1200px) { width: 130px; }
        @media screen and (max-width: 950px)  {
            width: 100%;
            margin-bottom: 40px;
            text-align: center;
        }
        @media screen and (max-width: 600px) { margin-bottom: 30px; }
    }

    &__logo-link {

    }

    &__logo-img {
        width: 150px;

        @media screen and (max-width: 1200px) { width: 130px; }
        @media screen and (max-width: 950px)  { width: 150px; }
    }

    &__links-item {
        display: flex;
        flex: 1;
        padding-left: 15%;

        @media screen and (max-width: 1600px) { padding-left: 10%; }
        @media screen and (max-width: 1400px) { padding-left: 100px; }
        @media screen and (max-width: 1400px) { padding-left: 100px; }
        @media screen and (max-width: 1300px) { padding-left: 80px; }
        @media screen and (max-width: 1200px) { padding-left: 70px; }
        @media screen and (max-width: 1100px) { padding-left: 60px; }
        @media screen and (max-width: 950px)  {
            flex: initial;
            width: 100%;
            justify-content: center;
            padding-left: 0;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 600px) { margin-bottom: 30px; }
        @media screen and (max-width: 500px) { display: none; }
    }

    &__links-column {
        display: flex;
        width: 220px;
        flex-direction: column;
        
        @media screen and (max-width: 1400px) { width: 200px; }
        @media screen and (max-width: 1300px) { width: 180px; }
        @media screen and (max-width: 1100px) { width: 160px; }
        @media screen and (max-width: 950px)  {
            width: 200px;
            text-align: center;
        }
    }

    &__links-column:last-child {
        @media screen and (min-width: 951px) { width: auto; }

    }
    
    &__link {
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.5;
        color: var(--primary-light-color);
        transition: color 300ms;
    }

    &__link:hover {
        color: var(--other-1);
    }

    &__link-container:not(:last-child) {
        margin-bottom: 20px;

        @media screen and (max-width: 1100px) { margin-bottom: 15px; }
    }

    &__newsletter-item {
        width: 326px;

        // @media screen and (max-width: 1400px) { width: 320px; }
        @media screen and (max-width: 1200px) { width: 290px; }
        @media screen and (max-width: 950px)  { width: 100%; text-align: center; }
    }

    &__newsletter-subtitle {
        display: block;
        margin-bottom: 25px;
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.5;
        color: var(--primary-light-color);

        @media screen and (max-width: 1024px) { margin-bottom: 20px; }
        @media screen and (max-width: 768px) { margin-bottom: 15px; }
        @media screen and (max-width: 600px) { margin-bottom: 10px; }
    }

    &__newsletter-form {
        position: relative;

        @media screen and (max-width: 950px) { max-width: 400px; margin: 0 auto; }
    }

    &__newsletter-input {
        width: 100%;
        padding: 20px 25px;
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--primary-color);
        border: none;
        background: var(--primary-light-color);
        border-radius: 50px;
    }

    &__newsletter-form ::-webkit-input-placeholder { color: var(--primary-color); }
    &__newsletter-form ::-moz-placeholder { color: var(--primary-color); }
    &__newsletter-form :-ms-input-placeholder {  color: var(--primary-color); }
    &__newsletter-form :-moz-placeholder { color: var(--primary-color); }
    &__newsletter-input:focus::-webkit-input-placeholder { color: rgba(var(--primary-rgb-color), 0); }
    &__newsletter-input:focus::-moz-placeholder { color: rgba(var(--primary-rgb-color), 0); }
    &__newsletter-input:focus:-ms-input-placeholder {  color: rgba(var(--primary-rgb-color), 0); }
    &__newsletter-input:focus:-moz-placeholder { color: rgba(var(--primary-rgb-color), 0); }

    &__newsletter-submit {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
    }

    &__newsletter-submit-icon {
        position: relative;
        width: 14px;
        height: 14px;
        right: 0px;
        fill: none;
        stroke: var(--secondary-color);
        stroke-width: 3px;
        transition: right 300ms var(--ease-out);
    }

    &__newsletter-submit:hover &__newsletter-submit-icon {
        right: -5px;
    }

    &__secondary-logo-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 90px;
        width: 100%;

        @media screen and (max-width: 950px) { 
            margin-top: 50px; text-align: center; 
            flex-direction: column;
        }

        @media screen and (max-width: 600px) { margin-top: 30px; }
    }

    &__secondary-logo {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    &__secondary-other-logo { 
        margin-right: 120px; 
        transform: translateY(20px);

        @media screen and (max-width: 1024px) { margin-right: 80px; }

        @media screen and (max-width: 950px) { 
            margin-right: 0; 
            margin-bottom: 40px;
            transform: translateY(0);
        }
    }

    &__secondary-other-logo-text {
        width: 410px;
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.4;
        color: var(--primary-light-color);

        @media screen and (max-width: 950px) { width: 100%; }
    }

    &__secondary-logo-img {
        width: 70px;
        margin-right: 20px;
        margin-bottom: 20px;

        @media screen and (max-width: 600px) { margin-right: 15px; }
        @media screen and (max-width: 500px) { margin-right: 10px; }
    }

    &__secondary-other-logo-img {
        width: 130px;
        margin-bottom: 20px;
    }

    &__secondary-logo-img-2 {
        width: 290px;
    }

    &__secondary-logo-text {
        font-family: var(--font-family-2);
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.2;
        text-align: left;
        color: var(--primary-light-color);

        @media screen and (max-width: 768px) { font-size: 1.9rem; }
        @media screen and (max-width: 600px) { font-size: 1.8rem; }
        @media screen and (max-width: 500px) { font-size: 1.7rem; }
        @media screen and (max-width: 400px) { font-size: 1.6rem; }
    }

    .btnFooter {
        background: var(--primary-light-color);
        color: var(--primary-color);

        &:hover {
            background: var(--secondary-color);
            color: var(--primary-light-color);
        }
    }
}

.copyright {
    &__container {
        display: flex;
        padding: 35px var(--x-spacing-110);
        border-top: 1px solid rgba(var(--primary-light-rgb-color), 0.3);
        background: var(--primary-color);
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__texts-item {
        display: flex;

        @media screen and (max-width: 950px) {
            width: 100%;
            margin-bottom: 5px;
            text-align: center;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    &__text {
        margin-right: 50px;
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.5;
        color: var(--primary-light-color);

        @media screen and (max-width: 950px) { width: 100%; margin-right: 0; }
    }

    &__blanko-link {
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.5;
        color: var(--primary-light-color);
        transition: color 300ms;
    }

    &__blanko-link:hover {
        color: var(--other-1);
    }

    &__blanko-item {
        @media screen and (max-width: 950px) {
            width: 100%;
            text-align: center;
        }
    }
}