//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.news-block {

}


// --------------------
// Le bloc d'actualité
// --------------------
.news-block {
    position: relative;
    width: 100%;
    padding-top: 148%;
    will-change: transform;


    &__picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }

    &__picture:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(var(--primary-rgb-color),1) 5%, rgba(var(--primary-rgb-color),0) 60%);
        z-index: 1;
    }
    
    &__img {
        transition: transform 600ms var(--ease-out);
    }
    
    &:hover &__img {
        transform: scale(1.1);    
    }
    
    &__icon {
        position: absolute;
        top: 20px;
        right: 30px;
        width: 68px;
        height: 68px;
        
        @media screen and (max-width: 1300px) { width: 54px; height: 54px; right: 25px; bottom: 25px; }
        @media screen and (max-width: 1024px) { width: 48px; height: 48px; right: 20px; bottom: 20px; }
    }
    
    &__icon:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--primary-light-color);
        border-radius: 50%;
        transition: transform 600ms var(--ease-out);
    }

    &:hover &__icon:before {
        transform: scale(1.1);    
    }
    
    &__svg {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 50%;
        left: 50%;
        fill: none;
        stroke: var(--primary-color);
        stroke-width: 1px;
        transform: translate(-50%, -50%);
    }

    &__content {
        display: flex;
        position: absolute;
        padding: 100px 40px 50px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 1;

        @media screen and (max-width: 1300px) { padding: 100px 30px 40px; }
        @media screen and (max-width: 1200px) { padding: 100px 25px 30px; }
        @media screen and (max-width: 1100px) { padding: 100px 25px 25px; }
        @media screen and (max-width: 500px)  { padding: 100px 40px 50px; }
        @media screen and (max-width: 400px)  { padding: 100px 30px 35px; }
        @media screen and (max-width: 350px)  { padding: 100px 25px 25px  }
    }

    &__date {
        margin-bottom: 20px;
        font-family: var(--font-family-1);
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: var(--primary-light-color);

        @media screen and (max-width: 1200px) { margin-bottom: 10px; }
        @media screen and (max-width: 500px)  { margin-bottom: 15px; }
        @media screen and (max-width: 400px)  { margin-bottom: 10px; }
    }

    &__title {
        font-family: var(--font-family-1);
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.6;
        color: var(--primary-light-color);

        @media screen and (max-width: 1200px) { font-size: 1.9rem; }
        @media screen and (max-width: 1024px) { font-size: 1.8rem; }
        @media screen and (max-width: 900px)  { font-size: 1.7rem; }
        @media screen and (max-width: 500px)  { font-size: 2rem; }
        @media screen and (max-width: 400px)  { font-size: 1.9rem; }
    }
}