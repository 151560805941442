.field {
    --field-note-color: var(--other-1);
    --field-font-family: var(--font-family-1);
    --field-background-inactive: transparent;
    --field-color-inactive: var(--secondary-light-color);
    --field-border-color-inactive: var(--secondary-light-color);
    --field-error-color: var(--secondary-color);

    position: relative;
    margin-bottom: 50px;

    @media screen and (max-width: 1024px) { margin-bottom: 50px; }
    @media screen and (max-width: 768px) { margin-bottom: 40px; }

    // Erreur
    &__error {
        margin-top: 8px;
        font: 1.4rem/1 var(--field-font-family);
        color: var(--field-error-color);
    }

    &__note.dynamic {
        margin-top: 8px;
        color: var(--field-note-color);
        font-size: 1.4rem;
        p,li,table{
            font-size: inherit;
        }
    }

    // Placeholder
    ::-webkit-input-placeholder { color: var(--field-color-inactive); }
    ::-moz-placeholder { color: var(--field-color-inactive); }
    :-ms-input-placeholder {  color: var(--field-color-inactive); }
    :-moz-placeholder { color: var(--field-color-inactive); }

    // Titres
    &--title {
        font-family: var(--font-family-1);
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.15;
        color: var(--primary-color);
        margin-bottom: 30px;
        
        @media screen and (max-width: 1400px) { font-size: 2.3rem; }
        @media screen and (max-width: 1024px) { font-size: 2.2rem; }
        @media screen and (max-width: 768px) { font-size: 2rem; }
        @media screen and (max-width: 600px)  { font-size: 1.8rem; }
    }
}