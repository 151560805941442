:root{
    --back-to-top__size: 60px;
    --back-to-top__icon-size: 20px;
    --back-to-top__distance: 50px;

    @media screen and (max-width: 1024px){
        --back-to-top__size: 50px;
    }

    @media screen and (max-width: 500px){
        --back-to-top__icon-size: 18px;
    }
}


.back-to-top {
    position: absolute;
    top: 0;
    bottom: calc( var(--back-to-top__size) / -2 );
    width: 110px;
    right: 0;
    z-index: 20;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    opacity: 0;
    transition: opacity 0.3s ease;

    @media screen and (max-width: 1024px) { width: 100px; }
    @media screen and (max-width: 600px) { width: 75px; }
    @media screen and (max-width: 500px) { display: none; }

    &__button{
        width: var(--back-to-top__size);
        height: var(--back-to-top__size);
        position: sticky;
        bottom: var(--back-to-top__distance);
        align-self: flex-end;
        cursor: pointer;
        pointer-events: none;

        &::after{
            content: '';
            position: absolute;
            background-color: var(--secondary-color);
            border-radius: 50%;
            transform: translate(-50%,-50%);
            transition: background 300ms var(--ease-in-out);
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;
            z-index: 0;
            will-change: transform;
        }
    
        &:hover{
            &::after{
                background: var(--primary-color);
            }
        }
    }

    &__svg{
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--back-to-top__icon-size);
        height: var(--back-to-top__icon-size);
        fill: none;
        stroke-width: 2px;
        stroke: #fff;
        transform: translate(-50%,-50%) rotate(-90deg);
        z-index: 1;
    }
}