//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.field--radio{
    --box-dimension: 20px;
    --box-margin-right: 15px;
    
    --radio-checkbox-font-family: var(--font-family-1);
    --radio-checkbox-font-size: 1.7rem;
    --radio-checkbox-font-weight: normal;
    --radio-checkbox-line-height: 1;
    --radio-checkbox-text-transform: inherit;
    --radio-checkbox-letter-spacing: inherit;
    --radio-checkbox-color: var(--primary-color);

    --radio-box-background: white;
    --radio-box-background-active: white;
    --radio-hook-dimension: 12px;

    --radio-dot-dimension: 10px;
    --radio-dot-color: var(--secondary-color);
    --radio-dot-border: 1px solid var(--primary-color);
    --radio-dot-border-active: 1px solid var(--primary-color);

    --field-label-font-family: var(--font-family-1);
    --field-label-font-size: 1.5rem;
    --field-label-font-weight: normal;
    --field-label-line-height: 1;
    --field-label-text-transform: inherit;
    --field-label-letter-spacing: inherit;
    --field-label-color: var(--primary-color);
}


// ------------------
// Boutons radios
// ------------------
.field {
    
    &--radio &__main-label {
        display: block;
        margin-bottom: 30px;
        font-family: var(--field-label-font-family);
        font-size: var(--field-label-font-size);
        font-weight: var(--field-label-font-weight);
        line-height: var(--field-label-line-height);
        text-transform: var(--field-label-text-transform);
        letter-spacing: var(--field-label-letter-spacing);
        color: var(--field-label-color);
    }

    &--radio &__label {
        display: flex;
        align-items: center;
		position: relative;
        margin-bottom: 20px;
		cursor: pointer;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    &--radio &__options { & :last-child{ margin-bottom: 0; } }

    &--radio &__text {
        flex: 1;
        font-family: var(--radio-checkbox-font-family);
        font-size: var(--radio-checkbox-font-size);
        font-weight: var(--radio-checkbox-font-weight);
        line-height: var(--radio-checkbox-line-height);
        text-transform: var(--radio-checkbox-text-transform);
        letter-spacing: var(--radio-checkbox-letter-spacing);
        color: var(--radio-checkbox-color);
        order: 2;
        user-select: none;
    }

    &--radio &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &--radio &__input:checked ~ &__box {
        background: var(--radio-box-background-active);
        border: var(--radio-dot-border-active);
    }

    &--radio &__input:checked ~ &__box:before { transform: translate(-50%, -50%) scale(1); }

    &--radio &__box {
        position: relative;
        width: var(--box-dimension);
        height: var(--box-dimension);
        margin-right: var(--box-margin-right);
        order: 1;
        transition: background 225ms, border 225ms;
        border-radius: 50%;
        border: var(--radio-dot-border);
        background: var(--radio-box-background);

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms;
            width: var(--radio-dot-dimension);
            height: var(--radio-dot-dimension);
            background: var(--radio-dot-color);
            border-radius: 50%;
        }
    }

    &--radio-inline &__label {
        display: inline-flex;
        margin: 0 30px 20px 0;
    }

    &--radio-inline &__options { margin-bottom: -20px; }
}