// ------------------
// Les pages de détail
// ------------------

.page--detail {
    $prefix: ".page";

    #{$prefix}__container--download-case {
        padding: 0 var(--x-spacing-200);
        margin-top: var(--y-spacing-120);
        margin-bottom: var(--y-spacing-150);

        &:last-child() {
            margin-bottom: 0;
            padding-bottom: var(--y-spacing-150);
        }
    }

    #{$prefix}__container--block-intro {
        padding: 0 var(--x-spacing-200);
        margin-bottom: var(--y-spacing-100);

        &:last-child() {
            margin-bottom: 0;
            padding-bottom: var(--y-spacing-100);
        }

        @media screen and (max-width: 768px) { padding: 0; }
    }

    .main__content:last-child(){  
        margin-bottom: 0;
        padding-bottom: var(--y-spacing-100); 
    }
    .main__gallery:last-child() {
        margin-bottom: 0;
        padding-bottom: var(--y-spacing-100);
    }
    .main__rubrics:last-child() {
        margin-bottom: 0;
        padding-bottom: var(--y-spacing-100);
    }
    .main__documents:last-child() {
        margin-bottom: 0;
        padding-bottom: var(--y-spacing-100);
    }
    .main__form:last-child() {
        margin-bottom: 0;
        padding-bottom: var(--y-spacing-100);
    }
}