:root {
    --document__font-size: 3rem;
    --document__subtitle-font-size: 1.4rem;
    --document__in-rubric-font-size: 1.7em;

    --document__height: 145px;
    --document__in-rubric-height: 64px;
    --document__thumb-width: 140px;
    --document__thumb-height: 140px;
    --document__thumb-width-rubric: 60px;
    --document__thumb-height-rubric: 60px;

    --document__color: var(--primary-color);
    --document__color-hover:var(--secondary-color);

    @media screen and (max-width: 1024px) {
        --document__font-size: 2.2rem;
        --document__height: 120px;
    }

    @media screen and (max-width: 768px) {
        --document__font-size: 1.8rem;
        --document__height: 100px;
    }
}


// Documents normal
.documents {

    .document {
        position: relative;
        border-bottom: 1px solid var(--primary-color);
    
        &:first-child { border-top: 1px solid var(--primary-color); }
    
        &__link {
            position: relative;
            cursor: pointer;
            padding: 20px 40px;
            min-height: var(--document__height);
            display: flex;
            align-items: center;
            text-decoration: none;

            @media screen and (max-width: 600px) { padding: 20px 30px; }
            @media screen and (max-width: 500px) { padding: 20px 0; }
        }

        &__picture {
            width: var(--document__thumb-width);
            height: var(--document__thumb-height);
            border-radius: 50%;
            border: 1px solid var(--primary-color);
            margin-right: 30px;
            overflow: hidden;
            flex-shrink: 0;
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__content {
            padding-right: 75px;
        }

        &__subtitle {
            font-family: var(--font-family-1);
            font-size: var(--document__subtitle-font-size);
            color: var(--document__color);
            font-weight: 600;
            line-height: 1.7em;
            transition: color 0.3s;
        }
    
        &__title {
            font-family: var(--font-family-2);
            line-height: 1.6;
            color: var(--document__color);
            font-size: var(--document__font-size);
            font-weight: 700;
            transition: color 0.3s;
        }
    
        &__icon {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);

            @media screen and (max-width: 600px) { right: 30px; }
            @media screen and (max-width: 500px) { right: 15px; }
        }
    
        &__svg {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            fill: var(--document__color);
            transform: translate(-50%, -50%);
            transition: transform 300ms var(--ease__out-cubic), stroke 300ms;
            transition: fill 0.3s;
        }

        &__link:hover{
            .document__subtitle{
                color: var(--document__color-hover);
            }
            .document__title{
                color: var(--document__color-hover);
            }
            .document__svg {
                fill: var(--document__color-hover);
            }
        }
    }

    &--in-rubric{
        .document{
            border-color: var(--primary-color);
            &:first-child{ 
                border-color: var(--primary-color); 
                margin-top: 40px;
            }

            .document__picture {
                width: var(--document__thumb-width-rubric);
                height: var(--document__thumb-height-rubric);
            }
            .document__link {
                padding: 25px 0;
                min-height: var( --document__in-rubric-height);
            }
            .document__subtitle {
                font-family: var(--font-family-1);
            }
            .document__title {
                font-family: var(--font-family-1);
                font-size: var(--document__in-rubric-font-size);
                font-weight: 600;
            }
            .document__svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}