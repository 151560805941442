//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.intro {

}


// ----------------
// Un medium title
// ----------------
.intro {
    font-family: var(--font-family-2);
    font-size: 3rem;
    line-height: 1.6;
    color: var(--primary-color);

    @media screen and (max-width: 1400px) { font-size: 2.8rem; }
    @media screen and (max-width: 1200px) { font-size: 2.6rem; }
    @media screen and (max-width: 1024px) { font-size: 2.4rem; }
    @media screen and (max-width: 768px)  { font-size: 2.2rem; }
    @media screen and (max-width: 600px)  { font-size: 2.1rem; }
    @media screen and (max-width: 450px)  { font-size: 2rem; }
}