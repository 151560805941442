//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.field--search {
    
}


// ------------------
// Un menu déroulant
// ------------------
.field {

    &--search {
        text-align: right;
    
        @media screen and (max-width: 600px) { margin-bottom: 0; }
        ::-webkit-input-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
        ::-moz-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
        :-ms-input-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
        :-moz-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
    }

    &--search &__input {
        width: 100%;
        height: 90px;
        padding: 0 200px 0 60px;
        font: 4rem/1.3 var(--font-family-1);
        color: #fff;
        text-align: left;
        background: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        transition: border 300ms;

        @media screen and (max-width: 1100px) { height: 70px; padding-left: 50px; font-size: 3rem; }
        @media screen and (max-width: 600px)  { height: 50px; padding-left: 40px; padding-right: 30px; font-size: 2.3rem; }
        @media screen and (max-width: 500px)  { height: 50px; padding-left: 30px; padding-right: 30px; font-size: 2.1rem; }
        @media screen and (max-width: 425px)  { height: 50px; padding-left: 30px; padding-right: 30px; font-size: 1.9rem; }
    }
    
    &--search &__x {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        right: 160px;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        @media screen and (max-width: 600px) {
            top: 13px;
            right: 0;
            transform: none;
        }
        
        &:before, &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: #fff;

            @media screen and (max-width: 600px) { width: 20px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }
    
    &--search &__svg {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 0;
        fill: #fff;
        transform: translateY(-50%);

        @media screen and (max-width: 1100px) { width: 24px; height: 24px; }
        @media screen and (max-width: 600px)  {
            width: 18px;
            height: 18px;
            top: 16px;
            transform: none;
        }
    }

    &--search &__button {
        position: absolute;
        padding: 20px;
        top: 50%;
        right: 0;
        stroke: #fff;
        transform: translateY(-50%);

        @media screen and (max-width: 600px) {
            position: relative;
            width: 100%;
            margin-top: 20px;
            top: 0;
            text-align: center;
            transform: none;
        }
    }

    &--search[data-inputvalue]:not([data-inputvalue=""]) &__x {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms;
    }
}