// ----------
// Variables
// ----------

$colnb: 12; // Nombre de colonnes pour la grille
$gaps: 25px;  // Padding de chaque côté d'une colonne

:root {
    // Courbes de Bézier
    --ease-in: cubic-bezier(0.5, 0, 0.75, 0);
    --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
    --ease-in-out: cubic-bezier(0.76, 0, 0.24, 1);

    --font-family-1: "Roboto", sans-serif;
    --font-family-2: "NoeDisplay";

    --grid-gaps: 25px;
    @media screen and (max-width: 1200px) { --grid-gaps: 15px; }
    @media screen and (max-width: 768px) { --grid-gaps: 10px; }

    --grid-bigger-gaps: 40px;
    @media screen and (max-width: 1200px) { --grid-bigger-gaps: 30px; }
    @media screen and (max-width: 768px) { --grid-bigger-gaps: 20px; }

    --y-spacing-190: 190px;
    @media screen and (max-width: 1400px) { --y-spacing-190: 160px;  }
    @media screen and (max-width: 1200px) { --y-spacing-190: 140px;  }
    @media screen and (max-width: 1100px) { --y-spacing-190: 120px;  }
    @media screen and (max-width: 1024px) { --y-spacing-190: 110px;  }
    @media screen and (max-width: 768px)  { --y-spacing-190: 90px;  }
    @media screen and (max-width: 600px)  { --y-spacing-190: 80px;  }
    @media screen and (max-width: 500px)  { --y-spacing-190: 75px;  }

    --y-spacing-150: 150px;
    @media screen and (max-width: 1400px) { --y-spacing-150: 130px;  }
    @media screen and (max-width: 1200px) { --y-spacing-150: 120px;  }
    @media screen and (max-width: 1100px) { --y-spacing-150: 110px;  }
    @media screen and (max-width: 1024px) { --y-spacing-150: 100px;  }
    @media screen and (max-width: 768px)  { --y-spacing-150: 90px;  }
    @media screen and (max-width: 600px)  { --y-spacing-150: 70px;  }
    @media screen and (max-width: 500px)  { --y-spacing-150: 60px;  }

    --y-spacing-140: 140px;
    @media screen and (max-width: 1400px) { --y-spacing-140: 120px;  }
    @media screen and (max-width: 1200px) { --y-spacing-140: 110px;  }
    @media screen and (max-width: 1100px) { --y-spacing-140: 100px;  }
    @media screen and (max-width: 1024px) { --y-spacing-140: 90px;  }
    @media screen and (max-width: 768px)  { --y-spacing-140: 80px;  }
    @media screen and (max-width: 600px)  { --y-spacing-140: 70px;  }
    @media screen and (max-width: 500px)  { --y-spacing-140: 60px;  }

    --y-spacing-130: 130px;
    @media screen and (max-width: 1400px) { --y-spacing-130: 120px;  }
    @media screen and (max-width: 1200px) { --y-spacing-130: 110px;  }
    @media screen and (max-width: 1100px) { --y-spacing-130: 100px;  }
    @media screen and (max-width: 1024px) { --y-spacing-130: 90px;  }
    @media screen and (max-width: 768px)  { --y-spacing-130: 80px;  }
    @media screen and (max-width: 600px)  { --y-spacing-130: 70px;  }
    @media screen and (max-width: 500px)  { --y-spacing-130: 60px;  }

    --y-spacing-120: 120px;
    @media screen and (max-width: 1400px) { --y-spacing-120: 100px;  }
    @media screen and (max-width: 1200px) { --y-spacing-120: 90px;  }
    @media screen and (max-width: 1100px) { --y-spacing-120: 80px;  }
    @media screen and (max-width: 1024px) { --y-spacing-120: 60px;  }
    @media screen and (max-width: 768px)  { --y-spacing-120: 50px;  }

    --y-spacing-110: 110px;
    @media screen and (max-width: 1400px) { --y-spacing-110: 100px;  }
    @media screen and (max-width: 1200px) { --y-spacing-110: 90px;  }
    @media screen and (max-width: 1100px) { --y-spacing-110: 80px;  }
    @media screen and (max-width: 1024px) { --y-spacing-110: 60px;  }
    @media screen and (max-width: 768px)  { --y-spacing-110: 50px;  }

    --y-spacing-100: 100px;
    @media screen and (max-width: 1400px) { --y-spacing-100: 90px;  }
    @media screen and (max-width: 1200px) { --y-spacing-100: 80px;  }
    @media screen and (max-width: 1100px) { --y-spacing-100: 70px;  }
    @media screen and (max-width: 1024px) { --y-spacing-100: 60px;  }
    @media screen and (max-width: 768px)  { --y-spacing-100: 50px;  }

    --y-spacing-90: 90px;
    @media screen and (max-width: 1200px) { --y-spacing-90: 80px;  }
    @media screen and (max-width: 1100px) { --y-spacing-90: 70px;  }
    @media screen and (max-width: 1024px) { --y-spacing-90: 60px;  }
    @media screen and (max-width: 768px)  { --y-spacing-90: 50px;  }

    --y-spacing-80: 80px;
    @media screen and (max-width: 1400px) { --y-spacing-80: 70px; }
    @media screen and (max-width: 1200px) { --y-spacing-80: 60px; }
    @media screen and (max-width: 1024px) { --y-spacing-80: 50px; }

    --y-spacing-70: 70px;
    @media screen and (max-width: 1200px) { --y-spacing-70: 60px; }
    @media screen and (max-width: 1024px) { --y-spacing-70: 50px; }

    --y-spacing-60: 60px;
    @media screen and (max-width: 1024px) { --y-spacing-60: 50px; }
    @media screen and (max-width: 768px)  { --y-spacing-60: 40px; }

    --y-spacing-30: 30px;
    @media screen and (max-width: 1024px) { --y-spacing-60: 30px; }
    @media screen and (max-width: 768px)  { --y-spacing-60: 20px; }

    --y-spacing-50: 50px;
    @media screen and (max-width: 768px)  { --y-spacing-50: 40px; }

    --x-spacing-200: 15vw;
    @media screen and (max-width: 1600px) { --x-spacing-200: 200px; }
    @media screen and (max-width: 1300px) { --x-spacing-200: 160px;  }
    @media screen and (max-width: 1100px) { --x-spacing-200: 100px;  }
    @media screen and (max-width: 1024px) { --x-spacing-200: 50px;  }
    @media screen and (max-width: 768px)  { --x-spacing-200: 30px;  }
    @media screen and (max-width: 600px)  { --x-spacing-200: 25px;  }

    --x-spacing-180: 12vw;
    @media screen and (max-width: 1600px) { --x-spacing-180: 180px; }
    @media screen and (max-width: 1300px) { --x-spacing-180: 160px;  }
    @media screen and (max-width: 1100px) { --x-spacing-180: 100px;  }
    @media screen and (max-width: 1024px) { --x-spacing-180: 50px;  }
    @media screen and (max-width: 768px)  { --x-spacing-180: 30px;  }
    @media screen and (max-width: 600px)  { --x-spacing-180: 25px;  }

    --x-spacing-170: 12vw;
    @media screen and (max-width: 1600px) { --x-spacing-170: 170px; }
    @media screen and (max-width: 1300px) { --x-spacing-170: 150px;  }
    @media screen and (max-width: 1100px) { --x-spacing-170: 100px;  }
    @media screen and (max-width: 1024px) { --x-spacing-170: 50px;  }
    @media screen and (max-width: 768px)  { --x-spacing-170: 30px;  }
    @media screen and (max-width: 600px)  { --x-spacing-170: 25px;  }

    --x-spacing-120: 11vw;
    @media screen and (max-width: 1600px) { --x-spacing-120: 120px; }
    @media screen and (max-width: 1300px) { --x-spacing-120: 110px;  }
    @media screen and (max-width: 1100px) { --x-spacing-120: 100px;  }
    @media screen and (max-width: 1024px) { --x-spacing-120: 50px;  }
    @media screen and (max-width: 768px)  { --x-spacing-120: 30px;  }
    @media screen and (max-width: 600px)  { --x-spacing-120: 25px;  }

    --x-spacing-110: 11vw;
    @media screen and (max-width: 1600px) { --x-spacing-110: 110px; }
    @media screen and (max-width: 1300px) { --x-spacing-110: 100px;  }
    @media screen and (max-width: 1100px) { --x-spacing-110: 90px;  }
    @media screen and (max-width: 1024px) { --x-spacing-110: 50px;  }
    @media screen and (max-width: 768px)  { --x-spacing-110: 30px;  }
    @media screen and (max-width: 600px)  { --x-spacing-110: 25px;  }

    --x-spacing-100: 10vw;
    @media screen and (max-width: 1600px) { --x-spacing-100: 100px; }
    @media screen and (max-width: 1300px) { --x-spacing-100: 90px;  }
    @media screen and (max-width: 1100px) { --x-spacing-100: 80px;  }
    @media screen and (max-width: 1024px) { --x-spacing-100: 50px;  }
    @media screen and (max-width: 768px)  { --x-spacing-100: 30px;  }
    @media screen and (max-width: 600px)  { --x-spacing-100: 25px;  }

    --x-spacing-90: 10vw;
    @media screen and (max-width: 1600px) { --x-spacing-90: 90px; }
    @media screen and (max-width: 1300px) { --x-spacing-90: 80px;  }
    @media screen and (max-width: 1100px) { --x-spacing-90: 70px;  }
    @media screen and (max-width: 1024px) { --x-spacing-90: 50px;  }
    @media screen and (max-width: 768px)  { --x-spacing-90: 30px;  }
    @media screen and (max-width: 600px)  { --x-spacing-90: 25px;  }
}