// ----------
// Variables
// ----------
:root {
    // Thème par défaut ---------------------------------------------------
    --primary-color: #0c4654;
    --primary-rgb-color: 12, 70, 84;
    --secondary-color: #dc4c4c;
    --secondary-rgb-color: 221, 86, 86;
    --tertiary-color: #000;
    --tertiary-rgb-color: 0, 0, 0;
    
    --primary-light-color: #ffffff;
    --primary-light-rgb-color: 255, 255, 255;
    --secondary-light-color: #e8f1f1;
    --secondary-light-rgb-color: 232, 241, 241;
    --tertiary-light-color: #000;
    --tertiary-light-rgb-color: 0, 0, 0;
    
    --primary-dark-color: #000000;
    --primary-dark-rgb-color: 0, 0, 0;
    --secondary-dark-color: #000;
    --secondary-dark-rgb-color: 0, 0, 0;
    --tertiary-dark-color: #000;
    --tertiary-dark-rgb-color: 0, 0, 0;

    --other-1: #778d94; // Notes
    --other-3: #cedadd; // Bordure des tableaux
    --other-4: #0a3843; // Shape du page title (variante)
    --other-5: #6d9098; // Hover des liens (contact-box)
    // --------------------------------------------------------------------
}