.errors {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    &__container {
        background: var(--secondary-light-color);
        padding: 50px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px 25px;
        }
    }

    &__home {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    &__link {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        margin-right: 15px;
        transform: translateY(-2px);
    }

    &__svg {
        width: 100%;
        height: 100%;
        fill: var(--primary-color);
        stroke: none;

        &:hover { fill: var(--secondary-color); }
        transition: fill 300ms var(--ease-out);
    }

    &__title {
        color: var(--primary-color);
        text-align: center;
        padding-bottom: 50px;
    }

    &__text {
        color: var(--primary-color);
    }

    &__btn-home {
        margin-top: 50px;
        margin-left: 0!important;
    }
}