.site-block {
    display: block;

    @media screen and (max-width: 500px) { padding: 0 0 0 var(--site-block__block--side-padding);}

    &__picture-container {
        position: relative;
        margin-bottom: 30px;
        @media screen and (max-width: 500px) { margin-bottom: 20px; }
    }
    
    &__picture {
        height: 330px;
        position: relative;
        padding-top: 52%;
        overflow: hidden;
    }
    
    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 600ms var(--ease-out);
    }

    &:hover &__img { transform: scale(1.1); }
    
    &__icon-container {
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 68px;
        height: 68px;
        background: var(--primary-light-color);
        border-radius: 50%;
    
        @media screen and (max-width: 1300px) { width: 54px; height: 54px; right: 25px; bottom: 25px; }
        @media screen and (max-width: 1024px) { width: 48px; height: 48px; right: 20px; bottom: 20px; }
    }
    
    &__icon {
        position: absolute;
        width: 26px;
        height: 26px;
        top: 50%;
        left: 50%;
        fill: none;
        stroke: var(--primary-color);
        transform: translate(-50%, -50%);
    
        @media screen and (max-width: 1300px) { width: 22px; height: 22px; }
    }

    &__icon-container:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--primary-light-color);
        border-radius: 50%;
        transform: scale(1);
        transition: transform 600ms var(--ease-out);
    }

    &:hover &__icon-container:before {
        transform: scale(1.1);
    }
    
    &__subtitle {
        margin-bottom: 10px;
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.5;
        color: var(--secondary-color);
    }
}