.similar-news {

    &__title-item {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 700px) {
            flex-direction: column;
            justify-content: auto;
        }
    }

    &__shape {
        position: absolute;
        width: 260px;
        height: 260px;
        top: -70px;
        left: -90px;
        fill: var(--secondary-light-color);
        transform: rotate(60deg);
        
        @media screen and (max-width: 1200px) { width: 220px; height: 220px; left: -60px; }
        @media screen and (max-width: 1024px) { width: 180px; height: 180px; left: -30px; }
        @media screen and (max-width: 850px)  { width: 150px; height: 150px; left: -20px; top: -10px; }
        @media screen and (max-width: 500px)  { width: 110px; height: 110px; left: -10px; top: 0px; }
    }
    
    &__title {
        font-family: var(--font-family-2);
        font-size: 7rem;
        font-weight: 700;
        line-height: 1.1;
        color: var(--primary-color);
        position: relative;

        @media screen and (max-width: 768px) { font-size: 6.5rem; }
        @media screen and (max-width: 700px) { margin-bottom: 30px; }
        @media screen and (max-width: 600px) { font-size: 6rem; }
        @media screen and (max-width: 500px) { font-size: 5.5rem; }
        @media screen and (max-width: 450px) { font-size: 5rem; }
        @media screen and (max-width: 400px) { font-size: 4.5rem; }
    }

    &__button {
        font-family: var(--font-family-1);
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--secondary-color);
        transition: color 300ms;
        align-self: flex-end;
        padding-right: 25px;
        position: relative;

        &:hover { color: var(--primary-color);}

        @media screen and (max-width: 700px) { align-self: flex-start; }
    }

    &__button-icon {
        width: 10px;
        height: 17px;
        stroke: var(--secondary-color);
        transition: stroke 300ms;
        stroke-width: 2px;
        fill: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    &__button:hover &__button-icon { stroke: var(--primary-color); }

    &__news-list {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 700px) { margin-top: 50px; }
    }

    &__news {
        width: 100%;
        border-top: 1px solid var(--primary-color);
        

        &:last-child() { border-bottom: 1px solid var(--primary-color); }
    }

    &__link {
        padding: 20px 0;
        display: flex;
        align-items: center;
        position: relative;

        @media screen and (max-width: 600px) { padding: 35px 0; }
    }

    &__picture {
        width: 200px;
        height: 200px;
        min-width: 200px;
        min-height: 200px;
        border-radius: 50%;
        margin-right: 50px;
        overflow: hidden;

        @media screen and (max-width: 900px) {
            width: 140px;
            height: 140px;
            min-width: 140px;
            min-height: 140px;
        }

        @media screen and (max-width: 600px) { display: none; }
    }

    &__picture-img {
        transform: scale(1);
        transition: transform 600ms var(--ease-out);
    }

    &__link:hover &__picture-img { transform: scale(1.1); }

    &__content {
        display: flex;
        flex: 1;
        align-items: flex-end;
    }
    &__content-wrapper {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 768px) { padding-right: 40px; }
        @media screen and (max-width: 600px) { padding-right: 30px; }
    }

    &__surtitle {
        font-family: var(--font-family-1);
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--secondary-color);
        text-transform: uppercase;
        margin-bottom: 25px;

        @media screen and (max-width: 768px) { margin-bottom: 20px; }
        @media screen and (max-width: 600px) { margin-bottom: 15px; }
        @media screen and (max-width: 500px) { margin-bottom: 12px; }
    }

    &__news-title {
        font-family: var(--font-family-1);
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.3;
        color: var(--primary-color);
        transition: color 300ms;

        @media screen and (max-width: 768px) { font-size: 2.5rem; }
        @media screen and (max-width: 400px) { font-size: 2rem; }
    }

    &__icon {
        position: relative;
        width: 70px;
        height: 70px;
        
        @media screen and (max-width: 768px) {
            width: 50px;
            height: 50px;
        }

        @media screen and (max-width: 600px) {
            width: 40px;
            height: 40px;
        }
    }

    &__icon:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        background: var(--secondary-light-color);
        transition: transform 600ms var(--ease-out);
    }

    &__link:hover &__icon:before {
        transform: scale(1.1);
    }

    &__svg {
        width: 23px;
        height: 19px;
        fill: none;
        stroke: var(--primary-color);
        transition: stroke 300ms;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 768px) { width: 18px; height: 15px; }
        @media screen and (max-width: 600px) { width: 16px; height: 12px; }
    }

}