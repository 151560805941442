//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.large-title {

}


// ---------------
// Un large title
// ---------------
.large-title--bold {
    font-weight: 700;
}

.large-title {
    font-family: var(--font-family-2);
    font-size: 5rem;
    line-height: 1.15;
    color: var(--primary-color);

    @media screen and (max-width: 1400px) { font-size: 4.8rem; }
    @media screen and (max-width: 1300px) { font-size: 4.5rem; }
    @media screen and (max-width: 1200px) { font-size: 4.3rem; }
    @media screen and (max-width: 1024px) { font-size: 4.2rem; }
    @media screen and (max-width: 768px)  { font-size: 4rem; }
    @media screen and (max-width: 600px)  { font-size: 3.8rem; }
    @media screen and (max-width: 500px)  { font-size: 3.5rem; }
    @media screen and (max-width: 450px)  { font-size: 3.2rem; }
    @media screen and (max-width: 400px)  { font-size: 3rem; }

    &--bold { font-weight: 700; }
}