//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.paragraph {

}


// ----------------
// Un paragraphe
// ----------------
.paragraph {
    font-family: var(--font-family-1);
    font-size: 1.7rem;
    line-height: 1.6;
    color: var(--primary-color);
}