// ------------------
// La page d'accueil
// ------------------
.page--home {
    $prefix: ".page";

    #{$prefix}__container--image-with-text {
        padding: var(--y-spacing-190) 0;

        @media screen and (max-width: 768px) { padding-top: 0; }

        & > section {
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 800ms, transform 800ms var(--ease-out);
        }
    }

    #{$prefix}__container--image-with-text[data-fire] > section {
        opacity: 1;
        transform: none;
    }

    #{$prefix}__scrollfire > div {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms, transform 800ms var(--ease-out);
    }

    #{$prefix}__scrollfire[data-fire] > div {
        opacity: 1;
        transform: none;
    }

    #{$prefix}__container--line {
        position: relative;
        overflow: hidden;

        #{$prefix}__line {
            position: absolute;
            top: 20%;
            right: 0;
            left: 0;
            fill: none;
            stroke: var(--secondary-color);
            stroke-width: 2.5px;
            z-index: 5;
            pointer-events: none;

            @media screen and (max-width: 1500px) { top: 25%;   }
            @media screen and (max-width: 1024px) { top: 265px; }
            @media screen and (max-width: 900px)  { top: 380px; }
            @media screen and (max-width: 800px)  { top: 650px; stroke-width: 4px; }
            @media screen and (max-width: 700px)  { top: 600px; }
            @media screen and (max-width: 500px)  { top: 500px; left: -100px; right: -100px; }
            @media screen and (max-width: 375px)  { top: 400px; }
        }
    }

    #{$prefix}__container--site-slider {
        padding: 100px 0 var(--y-spacing-190);

        @media screen and (max-width: 850px) { padding-top: 40px; }
    }

    #{$prefix}__container--news-list {
        padding-top: var(--y-spacing-190);
    }

    #{$prefix}__container--title-on-shape {
        padding: var(--y-spacing-190) 0;
        @media screen and (max-width: 600px) { padding-top: 85px; }
    }
}
