.form {

    &__title {
        margin-bottom: 60px;

        @media screen and (max-width: 1024px) { margin-bottom: 50px; }
        @media screen and (max-width: 768px)  { margin-bottom: 40px; }
    }

    &__text { padding: 15px 0 var(--y-spacing-80);}

    &__grid-gaps{
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
        
        @media screen and (max-width: 1200px) {
            width: calc(100% + 50px);
            margin-right: -25px;
            margin-left: -25px;
        }
        @media screen and (max-width: 1024px) {
            width: calc(100% + 30px);
            margin-right: -15px;
            margin-left: -15px;
        }
        @media screen and (max-width: 900px) {
            width: calc(100% + 10px);
            margin-right: -5px;
            margin-left: -5px;
        }
    }
    
    &__column {
        padding-right: 40px;
        padding-left: 40px;

        @media screen and (max-width: 1200px) {
            padding-right: 25px;
            padding-left: 25px;
        }
        @media screen and (max-width: 1024px) {
            padding-right: 15px;
            padding-left: 15px;
        }
        @media screen and (max-width: 900px) {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    &__column--btn { padding-left: 0; }

    &__column--align-center {
        display: flex;
        justify-content: center;
    }

    ::-webkit-input-placeholder { color: rgba(var(--color__primary), 0.2); font-weight: normal; }
    ::-moz-placeholder { color: rgba(var(--color__primary), 0.2); font-weight: normal; }
    :-ms-input-placeholder { color: rgba(var(--color__primary), 0.2); font-weight: normal; }
    :-moz-placeholder { color: rgba(var(--color__primary), 0.2); font-weight: normal; }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,  
    input:-webkit-autofill:active  {
        box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: var(--color-primary);
        transition: none;
        border-bottom: 1px solid var(--secondary-light-color) !important;
    }
    input:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: var(--color-primary);
        transition: none;
        border-bottom: 2px solid var(--primary-color) !important;
    }
    .form__note {
        margin-top: 10px;
        font-family: var(--font-family-1);
        white-space: normal;
        font-size: 1.4rem;
        line-height: 1.7;
        color: var(--dynamic-note-color);
        
        a {
            color: var(--dynamic-main-color);
            text-decoration: none;
            box-shadow: inset 0 -0.4em 0 0 rgba(var(--dynamic-main-color-rgb),0.2);
            transition: box-shadow 0.3s;
            display: inline;

            &:hover{
                box-shadow: inset 0 -1.1em 0 0 rgba(var(--dynamic-main-color-rgb),0.2);
            }

            //Icones liens externes et téléchargement
            svg{
                margin-left: 8px;
                width: 11px;
                height: 11px;
                stroke: var(--dynamic-main-color);
                fill: var(--dynamic-main-color);
            }

            &[href$=".pdf"],
            &[href$=".doc"],
            &[href$=".zip"]{
                svg{
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}

.no-margin-top {
    margin-top: 0 !important;
}

#messageWrapper, #sengagerFormWrapper {
    h3 {
        @extend .medium-title;
        font-weight: 700;
    }
}