.image-with-text {
    &__container {
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) { flex-wrap: wrap; }
    }

    &__image-item {
        position: relative;
        width: 40%;
        z-index: 1;

        @media screen and (max-width: 1200px) { width: 45%; }
        @media screen and (max-width: 768px)  { width: 100%; }
    }

    &__picture {
        position: relative;
        padding-top: 121%;
        width: 100%;
        
        @media screen and (max-width: 768px) {
            height: 600px;
            padding-top: 0;
        }
        @media screen and (max-width: 600px) { height: 500px; }
        @media screen and (max-width: 500px) {
            height: auto;
            padding-top: 121%;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__content-item {
        display: flex;
        align-items: center;
        width: 60%;
        padding: 0 var(--x-spacing-110);
        
        @media screen and (max-width: 1200px) { width: 55%;  }
        @media screen and (max-width: 768px)  { width: 100%; }
    }
    
    &__content {
        position: relative;
        max-width: 450px;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
            margin-top: 60px;
            max-width: 100%;
        }
    }

    &__title {
        position: relative;
    }

    &__button {
        margin-top: 80px;
        @media screen and (max-width: 1400px) { margin-top: 70px; }
        @media screen and (max-width: 1300px) { margin-top: 60px; }
        @media screen and (max-width: 1200px) { margin-top: 50px; }
        @media screen and (max-width: 1100px) { margin-top: 40px; }
        @media screen and (max-width: 1024px) { margin-top: 30px; }
    }

    &__shape {
        position: absolute;
        width: 215px;
        top: -100px;
        left: -100px;
        fill: var(--secondary-light-color);

        @media screen and (max-width: 1400px) { width: 200px; top: -75px; left: -75px; }
        @media screen and (max-width: 1200px) { width: 160px; top: -60px; left: -60px; }
        @media screen and (max-width: 1024px) { width: 120px; top: -50px; left: -20px; }
        @media screen and (max-width: 768px)  { width: 90px;  top: -30px; left: -10px; }
    }
}