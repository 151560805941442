.showcase {

    &__container {
        padding: var(--y-spacing-150) var(--x-spacing-200);
        margin-top: var(--y-spacing-150);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: initial;
            justify-content: initial;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }

    &__shape {
        position: absolute;
        width: 215px;
        top: -50px;
        left: -100px;
        fill: #fff;

        @media screen and (max-width: 1600px) { width: 200px; }
        @media screen and (max-width: 1300px) { width: 180px; }
        @media screen and (max-width: 1100px) { width: 160px; left: 0; top: -25px;}
        @media screen and (max-width: 600px)  { width: 130px; }
        @media screen and (max-width: 400px)  { width: 100px; }
    }

    &__text {
        max-width: 600px;
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 1400px) { max-width: 500px; }
        @media screen and (max-width: 1100px) { padding-left: var(--x-spacing-100); }
        @media screen and (max-width: 900px) { margin-bottom: 50px; }
    }

    [data-fire] &__text {
        opacity: 1;
        transform: none;
    }

    &__category {
        font-weight: 700;
        margin-bottom: 30px;

        @media screen and (max-width: 900px) { margin-bottom: 15px; }
    }

    &__title {
        font-size: 7rem;
        margin-bottom: 50px;

        @media screen and (max-width: 900px) { margin-bottom: 30px; }
        @media screen and (max-width: 1200px) { font-size: 6rem; }
        @media screen and (max-width: 700px) { font-size: 5rem; }
        @media screen and (max-width: 500px) { font-size: 4rem; }
        @media screen and (max-width: 400px) { font-size: 3.5rem; }
    }

    &__button { pointer-events: none; }

    &__container:hover &__button { background: var(--primary-color); }

    &__picture-container {
        position: absolute;
        top: 50%;
        right: var(--x-spacing-200);
        transform: translateY(-50%);

        @media screen and (max-width: 900px) {  
            position: relative;
            top: auto;
            right: auto;
            transform: none;
        }
    }

    &__picture {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        width: 430px;
        height: 430px;
        min-width: 430px;
        min-height: 430px;
        border-radius: 50%;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        overflow: hidden;

        @media screen and (max-width: 1400px) {  
            width: 400px;
            height: 400px;
            min-width: 400px;
            min-height: 400px;
        }
        @media screen and (max-width: 1200px) {  
            width: 350px;
            height: 350px;
            min-width: 350px;
            min-height: 350px;
        }
        @media screen and (max-width: 900px) {  
            width: 430px;
            height: 430px;
            min-width: 430px;
            min-height: 430px;
        }
        @media screen and (max-width: 500px) {  
            width: 100%;
            height: 100%;
            min-width: 0;
            min-height: 0;
        }
    }

    [data-fire] &__picture {
        opacity: 1;
        transform: none;
    }

    &__img {  
        transform: scale(1);
        transition: transform 600ms var(--ease-out);
    }

    &__container:hover &__img { transform: scale(1.1); }

    &__shape-container {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 0;
        overflow: hidden;
        z-index: 1;
        pointer-events: none;
    }

    &__line { 
        width: 1564px;
        fill: none;
        stroke: var(--secondary-color); 
        stroke-width: 2px;
        position: absolute;
        right: -60px;
        bottom: -600px; 

        @media screen and (max-width: 1300px) { right: -100px;  }
        @media screen and (max-width: 1100px) { right: -160px;  }
        @media screen and (max-width: 1024px) { right: -190px;  }
        @media screen and (max-width: 900px)  { 
            right: -380px; 
            bottom: -500px;
        }
        @media screen and (max-width: 600px)  { 
            width: 1300px; 
            bottom: -400px;
        }
        @media screen and (max-width: 440px)  { 
            width: 1100px; 
            bottom: -350px;
            right: -300px;
        }
    }
}