.download-case {

    &__picture-container {
        width: 100%;
        height: 465px;
        padding: 25px 85px;
        position: relative;
        display: block;

        @media screen and (max-width: 1024px) { padding: 25px 50px; }
        @media screen and (max-width: 768px) { padding: 25px 30px; }
        @media screen and (max-width: 600px) { height: 365px; padding: 25px; }
    }

    &__picture {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__img { 
        transform: scale(1);
        transition: transform 600ms var(--ease-out); 
    }

    &__picture-container:hover &__img { transform: scale(1.1); }

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__text {
        max-width: 400px;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 600px) { 
            width: 100%; 
            margin-bottom: 30px;
        }
    }

    &__category, &__title { color: #fff; }

    &__category {
        font-family: var(--font-family-1);
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 30px;

        @media screen and (max-width: 600px) { margin-bottom: 20px; }
    }

    &__icon {
        width: 150px;
        height: 150px;
        min-width: 150px;
        min-height: 150px;
        border-radius: 50%;
        background: #fff;
        position: relative;
        z-index: 2;

        &::before {
            content: "";
            width: 150px;
            height: 150px;
            min-width: 150px;
            min-height: 150px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            transform: scale(1);
            transition: transform 600ms var(--ease-out);

            @media screen and (max-width: 900px) {
                width: 100px;
                height: 100px;
                min-width: 100px;
                min-height: 100px;
            }
    
            @media screen and (max-width: 600px) {
                width: 80px;
                height: 80px;
                min-width: 80px;
                min-height: 80px;
            }
        }

        @media screen and (max-width: 900px) {
            width: 100px;
            height: 100px;
            min-width: 100px;
            min-height: 100px;
        }

        @media screen and (max-width: 600px) {
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
        }
    }

    &__picture-container:hover &__icon::before { transform: scale(1.1); }

    &__svg {
        width: 35px;
        height: 35px;
        stroke: var(--primary-color);
        stroke-width: 2px;
        fill: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        will-change: transform;

        @media screen and (max-width: 900px) {
            width: 30px;
            height: 30px;
        }
    }
}