// ----------
// Les varaibles locales
// ----------

.page--climatic-sites {
    --sites-item-gaps: 85px;
    --sites-item-list-column-number: 2;

    @media screen and (max-width: 1400px) { --sites-item-gaps: 30px; }
    @media screen and (max-width: 1200px) { --sites-item-gaps: 15px; }
    @media screen and (max-width: 1024px) { --sites-item-gaps: 10px; }
    @media screen and (max-width: 700px)  { --sites-item-list-column-number: 1; --sites-item-gaps: 1%; }
}

// ----------
// La page de chantiers climatiques
// ----------
.page--climatic-sites {
    $prefix: ".page";

    #{$prefix}__container--sites-section { 
        padding: 0 var(--x-spacing-120);
        margin-bottom: var(--y-spacing-100);

        &:last-child() {
            margin-bottom: 0;
            padding-bottom: var(--y-spacing-100);
        }

        .sites-list {
            display: flex;
            flex-wrap: wrap;
        }

        .sites-grid {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-flow: row wrap;
            width: 100%;
        
            &.sites-gaps {
                width: calc(100% + var(--sites-item-gaps) + var(--sites-item-gaps));
                margin-left: calc(var(--sites-item-gaps) * -1);
                margin-right: calc(var(--sites-item-gaps) * -1);
        
                .column {
                    width: calc(100% / var(--sites-item-list-column-number));
                    padding-right: var(--sites-item-gaps);
                    padding-left: var(--sites-item-gaps);

                    &:nth-last-child(n+3) { margin-bottom: var(--y-spacing-120); }
                }
            }
        }
    }

    #{$prefix}__container--download-case {
        padding: 0 var(--x-spacing-200);
        margin-top: var(--y-spacing-120);
        margin-bottom: var(--y-spacing-150);

        &:last-child() {
            margin-bottom: 0;
            padding-bottom: var(--y-spacing-150);
        }
    }

    #{$prefix}__container--block-intro {
        padding: 0 var(--x-spacing-200);
        margin-bottom: var(--y-spacing-100);

        &:last-child() {
            margin-bottom: 0;
            padding-bottom: var(--y-spacing-100);
        }

        @media screen and (max-width: 768px) { padding: 0; }
    }
}