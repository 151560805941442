// ----------
// La page d'actus liste
// ----------

.page--news-list {
    $prefix: ".page";

    #{$prefix}__container--news-list {
        padding-top: var(--y-spacing-120);
        padding-bottom: var(--y-spacing-140);

        @media screen and (max-width: 1024px) { padding-bottom: 60px; }
        @media screen and (max-width: 768px)  { padding-bottom: 50px; }
        @media screen and (max-width: 600px)  { padding-bottom: 40px; }
    }

    #{$prefix}__container--news-list {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 200ms, transform 800ms var(--ease-out) 200ms;
    }
    #{$prefix}__container--news-list[data-fire] {
        opacity: 1;
        transform: none;
    }

    .page__container--title-on-shape {
        padding-bottom: var(--y-spacing-190);
    }
}