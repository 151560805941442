@charset "UTF-8";
/*** ----- IMPORTS  ----- ***/
@import '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '../libraries/overlay.min.css';
@import 'https://use.typekit.net/dss2fka.css';
:root {
  --ease-in: cubic-bezier(0.5, 0, 0.75, 0);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-in-out: cubic-bezier(0.76, 0, 0.24, 1);
  --font-family-1: "Roboto", sans-serif;
  --font-family-2: "NoeDisplay";
  --grid-gaps: 25px;
  --grid-bigger-gaps: 40px;
  --y-spacing-190: 190px;
  --y-spacing-150: 150px;
  --y-spacing-140: 140px;
  --y-spacing-130: 130px;
  --y-spacing-120: 120px;
  --y-spacing-110: 110px;
  --y-spacing-100: 100px;
  --y-spacing-90: 90px;
  --y-spacing-80: 80px;
  --y-spacing-70: 70px;
  --y-spacing-60: 60px;
  --y-spacing-30: 30px;
  --y-spacing-50: 50px;
  --x-spacing-200: 15vw;
  --x-spacing-180: 12vw;
  --x-spacing-170: 12vw;
  --x-spacing-120: 11vw;
  --x-spacing-110: 11vw;
  --x-spacing-100: 10vw;
  --x-spacing-90: 10vw;
}
@media screen and (max-width: 1200px) {
  :root {
    --grid-gaps: 15px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --grid-gaps: 10px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --grid-bigger-gaps: 30px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --grid-bigger-gaps: 20px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-190: 160px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-190: 140px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-190: 120px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-190: 110px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-190: 90px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --y-spacing-190: 80px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-190: 75px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-150: 130px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-150: 120px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-150: 110px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-150: 100px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-150: 90px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --y-spacing-150: 70px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-150: 60px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-140: 120px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-140: 110px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-140: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-140: 90px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-140: 80px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --y-spacing-140: 70px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-140: 60px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-130: 120px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-130: 110px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-130: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-130: 90px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-130: 80px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --y-spacing-130: 70px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-spacing-130: 60px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-120: 100px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-120: 90px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-120: 80px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-120: 60px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-120: 50px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-110: 100px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-110: 90px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-110: 80px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-110: 60px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-110: 50px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-100: 90px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-100: 80px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-100: 70px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-100: 60px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-100: 50px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-90: 80px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --y-spacing-90: 70px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-90: 60px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-90: 50px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --y-spacing-80: 70px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-80: 60px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-80: 50px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-spacing-70: 60px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-70: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-60: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-60: 40px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-spacing-60: 30px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-60: 20px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-spacing-50: 40px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-200: 200px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --x-spacing-200: 160px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --x-spacing-200: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-200: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-200: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-200: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-180: 180px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --x-spacing-180: 160px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --x-spacing-180: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-180: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-180: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-180: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-170: 170px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --x-spacing-170: 150px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --x-spacing-170: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-170: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-170: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-170: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-120: 120px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --x-spacing-120: 110px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --x-spacing-120: 100px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-120: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-120: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-120: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-110: 110px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --x-spacing-110: 100px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --x-spacing-110: 90px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-110: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-110: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-110: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-100: 100px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --x-spacing-100: 90px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --x-spacing-100: 80px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-100: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-100: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-100: 25px;
  }
}
@media screen and (max-width: 1600px) {
  :root {
    --x-spacing-90: 90px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    --x-spacing-90: 80px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --x-spacing-90: 70px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-spacing-90: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-spacing-90: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-spacing-90: 25px;
  }
}

:root {
  --primary-color: #0c4654;
  --primary-rgb-color: 12, 70, 84;
  --secondary-color: #dc4c4c;
  --secondary-rgb-color: 221, 86, 86;
  --tertiary-color: #000;
  --tertiary-rgb-color: 0, 0, 0;
  --primary-light-color: #ffffff;
  --primary-light-rgb-color: 255, 255, 255;
  --secondary-light-color: #e8f1f1;
  --secondary-light-rgb-color: 232, 241, 241;
  --tertiary-light-color: #000;
  --tertiary-light-rgb-color: 0, 0, 0;
  --primary-dark-color: #000000;
  --primary-dark-rgb-color: 0, 0, 0;
  --secondary-dark-color: #000;
  --secondary-dark-rgb-color: 0, 0, 0;
  --tertiary-dark-color: #000;
  --tertiary-dark-rgb-color: 0, 0, 0;
  --other-1: #778d94;
  --other-3: #cedadd;
  --other-4: #0a3843;
  --other-5: #6d9098;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*** Blanko ***/
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

input:focus {
  outline: none;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important;
}

.slick-loading .slick-list {
  background: none;
}

.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
  display: none !important;
}

@font-face {
  font-family: "NoeDisplay";
  src: url("../fonts/noe-display/noe-display-regular.eot");
  src: url("../fonts/noe-display/noe-display-regular.woff2") format("woff2"), url("../fonts/noe-display/noe-display-regular.woff") format("woff"), url("../fonts/noe-display/noe-display-regular.ttf") format("truetype"), url("../fonts/noe-display/noe-display-regular.svg#MaisonNeue-Demi") format("svg"), url("../fonts/noe-display/noe-display-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NoeDisplay";
  src: url("../fonts/noe-display/noedisplay-bold.eot");
  src: url("../fonts/noe-display/noedisplay-bold.woff2") format("woff2"), url("../fonts/noe-display/noedisplay-bold.woff") format("woff"), url("../fonts/noe-display/noedisplay-bold.ttf") format("truetype"), url("../fonts/noe-display/noedisplay-bold.svg#MaisonNeue-Demi") format("svg"), url("../fonts/noe-display/noedisplay-bold.eot?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 62.5%;
  overflow-y: scroll;
}

body {
  margin-right: 0 !important;
  position: relative;
}

.page-without-footer {
  position: relative;
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

picture {
  display: block;
  font-size: 0;
}
picture img {
  width: 100%;
  height: 100%;
}
picture [data-object-fit=cover] {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.floating {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.floating:hover {
  animation-play-state: paused;
}

@keyframes floating {
  0%, 0.001% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.grid.gaps {
  width: calc(100% + var(--grid-gaps) + var(--grid-gaps));
  margin-left: calc(var(--grid-gaps) * -1);
  margin-right: calc(var(--grid-gaps) * -1);
}
.grid.gaps .column {
  padding-right: var(--grid-gaps);
  padding-left: var(--grid-gaps);
}
.grid.biggerGaps {
  width: calc(100% + var(--grid-bigger-gaps) + var(--grid-bigger-gaps));
  margin-left: calc(var(--grid-bigger-gaps) * -1);
  margin-right: calc(var(--grid-bigger-gaps) * -1);
}
.grid.biggerGaps .column {
  padding-right: var(--grid-bigger-gaps);
  padding-left: var(--grid-bigger-gaps);
}

.col1 {
  width: 8.3333333333%;
}

.col2 {
  width: 16.6666666667%;
}

.col3 {
  width: 25%;
}

.col4 {
  width: 33.3333333333%;
}

.col5 {
  width: 41.6666666667%;
}

.col6 {
  width: 50%;
}

.col7 {
  width: 58.3333333333%;
}

.col8 {
  width: 66.6666666667%;
}

.col9 {
  width: 75%;
}

.col10 {
  width: 83.3333333333%;
}

.col11 {
  width: 91.6666666667%;
}

.col12 {
  width: 100%;
}

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 8.3333333333%;
  }

  .col2_1800 {
    width: 16.6666666667%;
  }

  .col3_1800 {
    width: 25%;
  }

  .col4_1800 {
    width: 33.3333333333%;
  }

  .col5_1800 {
    width: 41.6666666667%;
  }

  .col6_1800 {
    width: 50%;
  }

  .col7_1800 {
    width: 58.3333333333%;
  }

  .col8_1800 {
    width: 66.6666666667%;
  }

  .col9_1800 {
    width: 75%;
  }

  .col10_1800 {
    width: 83.3333333333%;
  }

  .col11_1800 {
    width: 91.6666666667%;
  }

  .col12_1800 {
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 8.3333333333%;
  }

  .col2_1700 {
    width: 16.6666666667%;
  }

  .col3_1700 {
    width: 25%;
  }

  .col4_1700 {
    width: 33.3333333333%;
  }

  .col5_1700 {
    width: 41.6666666667%;
  }

  .col6_1700 {
    width: 50%;
  }

  .col7_1700 {
    width: 58.3333333333%;
  }

  .col8_1700 {
    width: 66.6666666667%;
  }

  .col9_1700 {
    width: 75%;
  }

  .col10_1700 {
    width: 83.3333333333%;
  }

  .col11_1700 {
    width: 91.6666666667%;
  }

  .col12_1700 {
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 8.3333333333%;
  }

  .col2_1600 {
    width: 16.6666666667%;
  }

  .col3_1600 {
    width: 25%;
  }

  .col4_1600 {
    width: 33.3333333333%;
  }

  .col5_1600 {
    width: 41.6666666667%;
  }

  .col6_1600 {
    width: 50%;
  }

  .col7_1600 {
    width: 58.3333333333%;
  }

  .col8_1600 {
    width: 66.6666666667%;
  }

  .col9_1600 {
    width: 75%;
  }

  .col10_1600 {
    width: 83.3333333333%;
  }

  .col11_1600 {
    width: 91.6666666667%;
  }

  .col12_1600 {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 8.3333333333%;
  }

  .col2_1500 {
    width: 16.6666666667%;
  }

  .col3_1500 {
    width: 25%;
  }

  .col4_1500 {
    width: 33.3333333333%;
  }

  .col5_1500 {
    width: 41.6666666667%;
  }

  .col6_1500 {
    width: 50%;
  }

  .col7_1500 {
    width: 58.3333333333%;
  }

  .col8_1500 {
    width: 66.6666666667%;
  }

  .col9_1500 {
    width: 75%;
  }

  .col10_1500 {
    width: 83.3333333333%;
  }

  .col11_1500 {
    width: 91.6666666667%;
  }

  .col12_1500 {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 8.3333333333%;
  }

  .col2_1400 {
    width: 16.6666666667%;
  }

  .col3_1400 {
    width: 25%;
  }

  .col4_1400 {
    width: 33.3333333333%;
  }

  .col5_1400 {
    width: 41.6666666667%;
  }

  .col6_1400 {
    width: 50%;
  }

  .col7_1400 {
    width: 58.3333333333%;
  }

  .col8_1400 {
    width: 66.6666666667%;
  }

  .col9_1400 {
    width: 75%;
  }

  .col10_1400 {
    width: 83.3333333333%;
  }

  .col11_1400 {
    width: 91.6666666667%;
  }

  .col12_1400 {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 8.3333333333%;
  }

  .col2_1300 {
    width: 16.6666666667%;
  }

  .col3_1300 {
    width: 25%;
  }

  .col4_1300 {
    width: 33.3333333333%;
  }

  .col5_1300 {
    width: 41.6666666667%;
  }

  .col6_1300 {
    width: 50%;
  }

  .col7_1300 {
    width: 58.3333333333%;
  }

  .col8_1300 {
    width: 66.6666666667%;
  }

  .col9_1300 {
    width: 75%;
  }

  .col10_1300 {
    width: 83.3333333333%;
  }

  .col11_1300 {
    width: 91.6666666667%;
  }

  .col12_1300 {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 8.3333333333%;
  }

  .col2_1200 {
    width: 16.6666666667%;
  }

  .col3_1200 {
    width: 25%;
  }

  .col4_1200 {
    width: 33.3333333333%;
  }

  .col5_1200 {
    width: 41.6666666667%;
  }

  .col6_1200 {
    width: 50%;
  }

  .col7_1200 {
    width: 58.3333333333%;
  }

  .col8_1200 {
    width: 66.6666666667%;
  }

  .col9_1200 {
    width: 75%;
  }

  .col10_1200 {
    width: 83.3333333333%;
  }

  .col11_1200 {
    width: 91.6666666667%;
  }

  .col12_1200 {
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 8.3333333333%;
  }

  .col2_1100 {
    width: 16.6666666667%;
  }

  .col3_1100 {
    width: 25%;
  }

  .col4_1100 {
    width: 33.3333333333%;
  }

  .col5_1100 {
    width: 41.6666666667%;
  }

  .col6_1100 {
    width: 50%;
  }

  .col7_1100 {
    width: 58.3333333333%;
  }

  .col8_1100 {
    width: 66.6666666667%;
  }

  .col9_1100 {
    width: 75%;
  }

  .col10_1100 {
    width: 83.3333333333%;
  }

  .col11_1100 {
    width: 91.6666666667%;
  }

  .col12_1100 {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 8.3333333333%;
  }

  .col2_1024 {
    width: 16.6666666667%;
  }

  .col3_1024 {
    width: 25%;
  }

  .col4_1024 {
    width: 33.3333333333%;
  }

  .col5_1024 {
    width: 41.6666666667%;
  }

  .col6_1024 {
    width: 50%;
  }

  .col7_1024 {
    width: 58.3333333333%;
  }

  .col8_1024 {
    width: 66.6666666667%;
  }

  .col9_1024 {
    width: 75%;
  }

  .col10_1024 {
    width: 83.3333333333%;
  }

  .col11_1024 {
    width: 91.6666666667%;
  }

  .col12_1024 {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .col1_900 {
    width: 8.3333333333%;
  }

  .col2_900 {
    width: 16.6666666667%;
  }

  .col3_900 {
    width: 25%;
  }

  .col4_900 {
    width: 33.3333333333%;
  }

  .col5_900 {
    width: 41.6666666667%;
  }

  .col6_900 {
    width: 50%;
  }

  .col7_900 {
    width: 58.3333333333%;
  }

  .col8_900 {
    width: 66.6666666667%;
  }

  .col9_900 {
    width: 75%;
  }

  .col10_900 {
    width: 83.3333333333%;
  }

  .col11_900 {
    width: 91.6666666667%;
  }

  .col12_900 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .col1_768 {
    width: 8.3333333333%;
  }

  .col2_768 {
    width: 16.6666666667%;
  }

  .col3_768 {
    width: 25%;
  }

  .col4_768 {
    width: 33.3333333333%;
  }

  .col5_768 {
    width: 41.6666666667%;
  }

  .col6_768 {
    width: 50%;
  }

  .col7_768 {
    width: 58.3333333333%;
  }

  .col8_768 {
    width: 66.6666666667%;
  }

  .col9_768 {
    width: 75%;
  }

  .col10_768 {
    width: 83.3333333333%;
  }

  .col11_768 {
    width: 91.6666666667%;
  }

  .col12_768 {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .col1_700 {
    width: 8.3333333333%;
  }

  .col2_700 {
    width: 16.6666666667%;
  }

  .col3_700 {
    width: 25%;
  }

  .col4_700 {
    width: 33.3333333333%;
  }

  .col5_700 {
    width: 41.6666666667%;
  }

  .col6_700 {
    width: 50%;
  }

  .col7_700 {
    width: 58.3333333333%;
  }

  .col8_700 {
    width: 66.6666666667%;
  }

  .col9_700 {
    width: 75%;
  }

  .col10_700 {
    width: 83.3333333333%;
  }

  .col11_700 {
    width: 91.6666666667%;
  }

  .col12_700 {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .col1_600 {
    width: 8.3333333333%;
  }

  .col2_600 {
    width: 16.6666666667%;
  }

  .col3_600 {
    width: 25%;
  }

  .col4_600 {
    width: 33.3333333333%;
  }

  .col5_600 {
    width: 41.6666666667%;
  }

  .col6_600 {
    width: 50%;
  }

  .col7_600 {
    width: 58.3333333333%;
  }

  .col8_600 {
    width: 66.6666666667%;
  }

  .col9_600 {
    width: 75%;
  }

  .col10_600 {
    width: 83.3333333333%;
  }

  .col11_600 {
    width: 91.6666666667%;
  }

  .col12_600 {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .col1_500 {
    width: 8.3333333333%;
  }

  .col2_500 {
    width: 16.6666666667%;
  }

  .col3_500 {
    width: 25%;
  }

  .col4_500 {
    width: 33.3333333333%;
  }

  .col5_500 {
    width: 41.6666666667%;
  }

  .col6_500 {
    width: 50%;
  }

  .col7_500 {
    width: 58.3333333333%;
  }

  .col8_500 {
    width: 66.6666666667%;
  }

  .col9_500 {
    width: 75%;
  }

  .col10_500 {
    width: 83.3333333333%;
  }

  .col11_500 {
    width: 91.6666666667%;
  }

  .col12_500 {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .col1_425 {
    width: 8.3333333333%;
  }

  .col2_425 {
    width: 16.6666666667%;
  }

  .col3_425 {
    width: 25%;
  }

  .col4_425 {
    width: 33.3333333333%;
  }

  .col5_425 {
    width: 41.6666666667%;
  }

  .col6_425 {
    width: 50%;
  }

  .col7_425 {
    width: 58.3333333333%;
  }

  .col8_425 {
    width: 66.6666666667%;
  }

  .col9_425 {
    width: 75%;
  }

  .col10_425 {
    width: 83.3333333333%;
  }

  .col11_425 {
    width: 91.6666666667%;
  }

  .col12_425 {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .col1_375 {
    width: 8.3333333333%;
  }

  .col2_375 {
    width: 16.6666666667%;
  }

  .col3_375 {
    width: 25%;
  }

  .col4_375 {
    width: 33.3333333333%;
  }

  .col5_375 {
    width: 41.6666666667%;
  }

  .col6_375 {
    width: 50%;
  }

  .col7_375 {
    width: 58.3333333333%;
  }

  .col8_375 {
    width: 66.6666666667%;
  }

  .col9_375 {
    width: 75%;
  }

  .col10_375 {
    width: 83.3333333333%;
  }

  .col11_375 {
    width: 91.6666666667%;
  }

  .col12_375 {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .col1_320 {
    width: 8.3333333333%;
  }

  .col2_320 {
    width: 16.6666666667%;
  }

  .col3_320 {
    width: 25%;
  }

  .col4_320 {
    width: 33.3333333333%;
  }

  .col5_320 {
    width: 41.6666666667%;
  }

  .col6_320 {
    width: 50%;
  }

  .col7_320 {
    width: 58.3333333333%;
  }

  .col8_320 {
    width: 66.6666666667%;
  }

  .col9_320 {
    width: 75%;
  }

  .col10_320 {
    width: 83.3333333333%;
  }

  .col11_320 {
    width: 91.6666666667%;
  }

  .col12_320 {
    width: 100%;
  }
}
.first-loading {
  display: none;
}

.default-transition {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.default-transition__container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: var(--primary-light-color);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.page {
  position: relative;
}
.page .blue-background {
  background: var(--secondary-light-color);
}

.page--home .page__container--image-with-text {
  padding: var(--y-spacing-190) 0;
}
@media screen and (max-width: 768px) {
  .page--home .page__container--image-with-text {
    padding-top: 0;
  }
}
.page--home .page__container--image-with-text > section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out);
}
.page--home .page__container--image-with-text[data-fire] > section {
  opacity: 1;
  transform: none;
}
.page--home .page__scrollfire > div {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out);
}
.page--home .page__scrollfire[data-fire] > div {
  opacity: 1;
  transform: none;
}
.page--home .page__container--line {
  position: relative;
  overflow: hidden;
}
.page--home .page__container--line .page__line {
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 2.5px;
  z-index: 5;
  pointer-events: none;
}
@media screen and (max-width: 1500px) {
  .page--home .page__container--line .page__line {
    top: 25%;
  }
}
@media screen and (max-width: 1024px) {
  .page--home .page__container--line .page__line {
    top: 265px;
  }
}
@media screen and (max-width: 900px) {
  .page--home .page__container--line .page__line {
    top: 380px;
  }
}
@media screen and (max-width: 800px) {
  .page--home .page__container--line .page__line {
    top: 650px;
    stroke-width: 4px;
  }
}
@media screen and (max-width: 700px) {
  .page--home .page__container--line .page__line {
    top: 600px;
  }
}
@media screen and (max-width: 500px) {
  .page--home .page__container--line .page__line {
    top: 500px;
    left: -100px;
    right: -100px;
  }
}
@media screen and (max-width: 375px) {
  .page--home .page__container--line .page__line {
    top: 400px;
  }
}
.page--home .page__container--site-slider {
  padding: 100px 0 var(--y-spacing-190);
}
@media screen and (max-width: 850px) {
  .page--home .page__container--site-slider {
    padding-top: 40px;
  }
}
.page--home .page__container--news-list {
  padding-top: var(--y-spacing-190);
}
.page--home .page__container--title-on-shape {
  padding: var(--y-spacing-190) 0;
}
@media screen and (max-width: 600px) {
  .page--home .page__container--title-on-shape {
    padding-top: 85px;
  }
}

.page--stylesheet .page__container--download-case {
  padding: 0 var(--x-spacing-200);
  margin-top: var(--y-spacing-120);
  margin-bottom: var(--y-spacing-150);
}
.page--stylesheet .page__container--download-case:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-150);
}
.page--stylesheet .page__container--block-intro {
  padding: 0 var(--x-spacing-200);
  margin-bottom: var(--y-spacing-100);
}
.page--stylesheet .page__container--block-intro:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
@media screen and (max-width: 768px) {
  .page--stylesheet .page__container--block-intro {
    padding: 0;
  }
}
.page--stylesheet .main__content:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--stylesheet .main__gallery:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--stylesheet .main__rubrics:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--stylesheet .main__documents:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--stylesheet .main__form:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}

.page--detail .page__container--download-case {
  padding: 0 var(--x-spacing-200);
  margin-top: var(--y-spacing-120);
  margin-bottom: var(--y-spacing-150);
}
.page--detail .page__container--download-case:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-150);
}
.page--detail .page__container--block-intro {
  padding: 0 var(--x-spacing-200);
  margin-bottom: var(--y-spacing-100);
}
.page--detail .page__container--block-intro:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
@media screen and (max-width: 768px) {
  .page--detail .page__container--block-intro {
    padding: 0;
  }
}
.page--detail .main__content:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--detail .main__gallery:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--detail .main__rubrics:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--detail .main__documents:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--detail .main__form:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}

.page--contact .page__container--contact-box {
  margin-bottom: var(--y-spacing-150);
}

.page--form .page__container--block-intro {
  padding: 0 var(--x-spacing-200);
  margin-bottom: var(--y-spacing-100);
}
.page--form .page__container--block-intro:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
@media screen and (max-width: 768px) {
  .page--form .page__container--block-intro {
    padding: 0;
  }
}

.page--news-list .page__container--news-list {
  padding-top: var(--y-spacing-120);
  padding-bottom: var(--y-spacing-140);
}
@media screen and (max-width: 1024px) {
  .page--news-list .page__container--news-list {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .page--news-list .page__container--news-list {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 600px) {
  .page--news-list .page__container--news-list {
    padding-bottom: 40px;
  }
}
.page--news-list .page__container--news-list {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 200ms, transform 800ms var(--ease-out) 200ms;
}
.page--news-list .page__container--news-list[data-fire] {
  opacity: 1;
  transform: none;
}
.page--news-list .page__container--title-on-shape {
  padding-bottom: var(--y-spacing-190);
}

.page--news-detail .page__container--similar-news {
  padding: 0 var(--x-spacing-200);
  margin-top: var(--y-spacing-190);
  padding-bottom: var(--y-spacing-150);
}

.page--climatic-sites {
  --sites-item-gaps: 85px;
  --sites-item-list-column-number: 2;
}
@media screen and (max-width: 1400px) {
  .page--climatic-sites {
    --sites-item-gaps: 30px;
  }
}
@media screen and (max-width: 1200px) {
  .page--climatic-sites {
    --sites-item-gaps: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .page--climatic-sites {
    --sites-item-gaps: 10px;
  }
}
@media screen and (max-width: 700px) {
  .page--climatic-sites {
    --sites-item-list-column-number: 1;
    --sites-item-gaps: 1%;
  }
}

.page--climatic-sites .page__container--sites-section {
  padding: 0 var(--x-spacing-120);
  margin-bottom: var(--y-spacing-100);
}
.page--climatic-sites .page__container--sites-section:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
.page--climatic-sites .page__container--sites-section .sites-list {
  display: flex;
  flex-wrap: wrap;
}
.page--climatic-sites .page__container--sites-section .sites-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.page--climatic-sites .page__container--sites-section .sites-grid.sites-gaps {
  width: calc(100% + var(--sites-item-gaps) + var(--sites-item-gaps));
  margin-left: calc(var(--sites-item-gaps) * -1);
  margin-right: calc(var(--sites-item-gaps) * -1);
}
.page--climatic-sites .page__container--sites-section .sites-grid.sites-gaps .column {
  width: calc(100% / var(--sites-item-list-column-number));
  padding-right: var(--sites-item-gaps);
  padding-left: var(--sites-item-gaps);
}
.page--climatic-sites .page__container--sites-section .sites-grid.sites-gaps .column:nth-last-child(n+3) {
  margin-bottom: var(--y-spacing-120);
}
.page--climatic-sites .page__container--download-case {
  padding: 0 var(--x-spacing-200);
  margin-top: var(--y-spacing-120);
  margin-bottom: var(--y-spacing-150);
}
.page--climatic-sites .page__container--download-case:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-150);
}
.page--climatic-sites .page__container--block-intro {
  padding: 0 var(--x-spacing-200);
  margin-bottom: var(--y-spacing-100);
}
.page--climatic-sites .page__container--block-intro:last-child() {
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-100);
}
@media screen and (max-width: 768px) {
  .page--climatic-sites .page__container--block-intro {
    padding: 0;
  }
}

.page--signataires .page__container--block-intro {
  padding: var(--y-spacing-120) var(--x-spacing-200);
  margin-bottom: var(--y-spacing-100);
}
@media screen and (max-width: 768px) {
  .page--signataires .page__container--block-intro {
    padding: var(--y-spacing-120) 0;
  }
}

.main {
  background-color: white;
  position: relative;
}
.main__share {
  margin-left: auto;
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  .main__share {
    display: none;
  }
}
.main__content {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  padding: 0 var(--x-spacing-200);
  margin: var(--y-spacing-100) 0;
  position: relative;
}
[data-fire] .main__content {
  opacity: 1;
  transform: none;
}
.main__gallery, .main__rubrics, .main__documents, .main__form {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  padding: 0 var(--x-spacing-200);
  margin: var(--y-spacing-100) 0;
  background: white;
}
.main__gallery .main__title, .main__rubrics .main__title, .main__documents .main__title, .main__form .main__title {
  font-weight: 700;
  color: var(--primary-color);
  margin-top: var(--y-spacing-50);
  margin-bottom: var(--y-spacing-60);
}
.main__gallery .main__title:first-child, .main__rubrics .main__title:first-child, .main__documents .main__title:first-child, .main__form .main__title:first-child {
  padding-top: 0;
}
[data-fire] .main__gallery, [data-fire] .main__rubrics, [data-fire] .main__documents, [data-fire] .main__form {
  opacity: 1;
  transform: none;
}
.main__documents:not(:last-child) {
  padding-bottom: 50px;
}
.main__documents-desc {
  margin-top: -20px;
  margin-bottom: var(--y-spacing-80);
}
.main__components h2 {
  font-family: var(--font-family-2);
  font-size: 5rem;
  line-height: 1.15;
  color: var(--primary-color);
  margin-bottom: var(--y-spacing-50);
}
@media screen and (max-width: 1400px) {
  .main__components h2 {
    font-size: 4.8rem;
  }
}
@media screen and (max-width: 1300px) {
  .main__components h2 {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 1200px) {
  .main__components h2 {
    font-size: 4.3rem;
  }
}
@media screen and (max-width: 1024px) {
  .main__components h2 {
    font-size: 4.2rem;
  }
}
@media screen and (max-width: 768px) {
  .main__components h2 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .main__components h2 {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 500px) {
  .main__components h2 {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 450px) {
  .main__components h2 {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 400px) {
  .main__components h2 {
    font-size: 3rem;
  }
}

.show-back-to-top .back-to-top {
  opacity: 1;
}
.show-back-to-top .back-to-top__button {
  pointer-events: all;
}

.overlay-menu .header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.overlay-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 49;
}
.overlay-menu__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 800ms;
  z-index: -1;
}
.overlay-menu__container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 100%;
  background: var(--primary-light-color);
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: none;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  transition: bottom 800ms var(--ease-out);
}
.overlay-menu__container::-webkit-scrollbar {
  display: none;
}
.overlay-menu__grid {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  min-height: 100vh;
  padding-top: calc(var(--header--height) + 90px);
  padding-right: var(--x-spacing-100);
  padding-left: var(--x-spacing-100);
  padding-bottom: 120px;
}
@media screen and (max-width: 1400px) {
  .overlay-menu__grid {
    padding-top: calc(var(--header--height) + 90px);
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .overlay-menu__grid {
    padding-top: calc(var(--header--height) + 80px);
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 950px) {
  .overlay-menu__grid {
    padding-top: calc(var(--header--height) + 70px);
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .overlay-menu__grid {
    padding-top: calc(var(--header--height) + 60px);
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu__grid {
    display: block;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 500px) {
  .overlay-menu__grid {
    padding-bottom: 40px;
  }
}
.overlay-menu__secondary-nav {
  width: 300px;
  opacity: 0;
  transform: translateY(50px);
}
@media screen and (max-width: 1300px) {
  .overlay-menu__secondary-nav {
    width: 270px;
  }
}
@media screen and (max-width: 1200px) {
  .overlay-menu__secondary-nav {
    width: 230px;
  }
}
@media screen and (max-width: 1100px) {
  .overlay-menu__secondary-nav {
    width: 200px;
  }
}
@media screen and (max-width: 950px) {
  .overlay-menu__secondary-nav {
    margin-top: 50px;
    width: 100%;
    order: 2;
  }
}
@media screen and (max-width: 768px) {
  .overlay-menu__secondary-nav {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu__secondary-nav {
    margin-top: 0px;
  }
}
.overlay-menu__secondary-link-wrapper:not(:last-child) {
  margin-bottom: 10px;
}
.overlay-menu__secondary-link {
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--primary-color);
  transition: color 300ms;
}
.overlay-menu__secondary-link:hover {
  color: var(--secondary-color);
}
.overlay-menu__primary-nav {
  flex: 1;
  opacity: 0;
  transform: translateY(50px);
}
@media screen and (max-width: 950px) {
  .overlay-menu__primary-nav {
    width: 100%;
    flex: initial;
    order: 1;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu__primary-nav {
    margin-top: 40px;
  }
}
.overlay-menu__primary-nav-title {
  display: block;
  margin-bottom: 30px;
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--secondary-color);
}
@media screen and (max-width: 1024px) {
  .overlay-menu__primary-nav-title {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 700px) {
  .overlay-menu__primary-nav-title {
    margin-bottom: 20px;
  }
}
.overlay-menu__primary-nav-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 380px;
}
@media screen and (max-width: 700px) {
  .overlay-menu__primary-nav-grid {
    max-height: 100%;
  }
}
.overlay-menu__primary-link-wrapper {
  width: 50%;
  padding-right: 50px;
  margin-bottom: 15px;
}
@media screen and (max-width: 1200px) {
  .overlay-menu__primary-link-wrapper {
    padding-right: 40px;
  }
}
@media screen and (max-width: 1100px) {
  .overlay-menu__primary-link-wrapper {
    padding-right: 30px;
  }
}
@media screen and (max-width: 700px) {
  .overlay-menu__primary-link-wrapper {
    padding-right: 0;
    width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .overlay-menu__primary-link-wrapper--mobile {
    display: none;
  }
}
.overlay-menu__primary-link-wrapper:nth-child(5n+5) {
  margin-bottom: 0;
}
@media screen and (max-width: 700px) {
  .overlay-menu__primary-link-wrapper:nth-child(5n+5) {
    margin-bottom: 15px;
  }
}
.overlay-menu__primary-link-wrapper:last-child {
  margin-bottom: 0;
}
.overlay-menu__primary-link {
  display: inline-flex;
}
.overlay-menu__primary-link-svg-wrapper {
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border: 1px solid #c8d1d1;
  border-radius: 50%;
  transition: border-color 400ms, background-color 400ms;
}
@media screen and (max-width: 600px) {
  .overlay-menu__primary-link-svg-wrapper {
    margin-right: 15px;
  }
}
.overlay-menu__primary-link:hover .overlay-menu__primary-link-svg-wrapper {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
}
.overlay-menu__primary-link-svg {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: none;
  stroke: var(--primary-color);
  transition: stroke 400ms;
}
.overlay-menu__primary-link:hover .overlay-menu__primary-link-svg {
  stroke: var(--primary-light-color);
}
.overlay-menu__primary-link-text {
  flex: 1;
  margin-top: 6px;
  font-family: var(--font-family-2);
  font-size: 3rem;
  line-height: 1.2;
  color: var(--primary-color);
  transition: color 400ms;
}
@media screen and (max-width: 1300px) {
  .overlay-menu__primary-link-text {
    font-size: 2.8rem;
    margin-top: 7px;
  }
}
@media screen and (max-width: 1200px) {
  .overlay-menu__primary-link-text {
    font-size: 2.6rem;
    margin-top: 8px;
  }
}
@media screen and (max-width: 1100px) {
  .overlay-menu__primary-link-text {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .overlay-menu__primary-link-text {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 500px) {
  .overlay-menu__primary-link-text {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 400px) {
  .overlay-menu__primary-link-text {
    font-size: 2.2rem;
  }
}
.overlay-menu__primary-link:hover .overlay-menu__primary-link-text {
  color: var(--secondary-color);
}

.show-overlay-menu:not(.opening-overlay-menu):not(.closing-overlay-menu) .overlay-menu .overlay-menu__container {
  overflow-y: auto;
}

.show-overlay-menu .menu-button__line--1 {
  width: 0%;
  top: calc(50% - 1px);
  left: 50%;
  opacity: 0;
}
.show-overlay-menu .menu-button__line--2 {
  transform: rotate(45deg);
  width: 100%;
}
.show-overlay-menu .menu-button__line--3 {
  transform: rotate(-45deg);
  width: 100%;
}
.show-overlay-menu .menu-button__line--4 {
  width: 0%;
  bottom: calc(50% - 1px);
  left: 50%;
  opacity: 0;
}
.show-overlay-menu .overlay-menu {
  visibility: visible;
  pointer-events: all;
}
.show-overlay-menu .overlay-menu__background {
  background: rgba(0, 0, 0, 0.4);
}
.show-overlay-menu .overlay-menu__container {
  bottom: 0%;
}
.show-overlay-menu .overlay-menu__secondary-nav, .show-overlay-menu .overlay-menu__primary-nav {
  opacity: 1;
  transform: none;
  transition: opacity 600ms linear, transform 600ms var(--ease-out);
}
.show-overlay-menu .overlay-menu__secondary-nav {
  transition-delay: 200ms;
}
@media screen and (max-width: 950px) {
  .show-overlay-menu .overlay-menu__secondary-nav {
    transition-delay: 300ms;
  }
}
.show-overlay-menu .overlay-menu__primary-nav {
  transition-delay: 300ms;
}
@media screen and (max-width: 950px) {
  .show-overlay-menu .overlay-menu__primary-nav {
    transition-delay: 300ms;
  }
}

.show-overlay-menu.closing-overlay-menu .menu-button__line--1 {
  top: 0px;
  width: 100%;
  opacity: 1;
  left: 0%;
}
.show-overlay-menu.closing-overlay-menu .menu-button__line--2, .show-overlay-menu.closing-overlay-menu .menu-button__line--3 {
  top: 50%;
  transform: none;
}
.show-overlay-menu.closing-overlay-menu .menu-button__line--4 {
  bottom: 0px;
  width: 100%;
  opacity: 1;
  left: 0%;
}
.show-overlay-menu.closing-overlay-menu .overlay-menu {
  visibility: visible;
  pointer-events: all;
}
.show-overlay-menu.closing-overlay-menu .overlay-menu__background {
  background: rgba(0, 0, 0, 0);
}
.show-overlay-menu.closing-overlay-menu .overlay-menu__container {
  bottom: 100%;
}
.show-overlay-menu.closing-overlay-menu .overlay-menu__secondary-nav, .show-overlay-menu.closing-overlay-menu .overlay-menu__primary-nav {
  opacity: 1;
  transform: none;
  transition: opacity 300ms linear, transform 300ms var(--ease-out);
  transition-delay: 0ms;
}

.overlay-share {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 15;
}
.overlay-share__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 800ms;
  z-index: -1;
}
.overlay-share__container {
  position: absolute;
  padding: 85px;
  width: 520px;
  top: 50%;
  left: 50%;
  background: #fff;
  text-align: center;
  opacity: 0;
  z-index: 151;
  pointer-events: none;
  transform: translateX(-50%);
  transition: opacity 800ms var(--ease-in-out), transform 800ms var(--ease-in-out);
}
@media screen and (max-width: 1200px) {
  .overlay-share__container {
    width: 490px;
  }
}
@media screen and (max-width: 1024px) {
  .overlay-share__container {
    width: 400px;
    padding: 50px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-share__container {
    width: auto;
    right: 20px;
    left: 20px;
    padding: 50px 20px;
    transform: none;
  }
}
.overlay-share__title {
  margin-bottom: 20px;
}
.overlay-share__close {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.overlay-share__close:hover:before, .overlay-share__close:hover:after {
  background: var(--secondary-color);
}
.overlay-share__close:before, .overlay-share__close:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: var(--primary-color);
  transition: background 300ms;
}
.overlay-share__close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.overlay-share__close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.overlay-share__list {
  position: relative;
  margin-top: 16px;
}
.overlay-share__list-li {
  display: inline-block;
}
.overlay-share__list-li:not(:last-child) {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  .overlay-share__list-li:not(:last-child) {
    margin-right: 10px;
  }
}
.overlay-share__link {
  position: relative;
  width: 45px;
  height: 45px;
  background: var(--primary-color);
  border-radius: 50%;
  transition: background 300ms;
  display: block;
  cursor: pointer;
}
.overlay-share__link:hover {
  background: var(--secondary-color);
}
.overlay-share__svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  fill: #fff;
  transform: translate(-50%, -50%);
}

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-share .overlay-share {
  pointer-events: all;
  visibility: visible;
}
.show-overlay-share .overlay-share__background {
  background: rgba(0, 0, 0, 0.4);
}
.show-overlay-share .overlay-share__container {
  opacity: 1;
  pointer-events: all;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .show-overlay-share .overlay-share__container {
    transform: translateY(-50%);
  }
}

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-share.closing-overlay-share .overlay-share__background {
  background: rgba(0, 0, 0, 0);
}
.show-overlay-share.closing-overlay-share .overlay-share__container {
  opacity: 0;
  transform: translateX(-50%);
}
@media screen and (max-width: 600px) {
  .show-overlay-share.closing-overlay-share .overlay-share__container {
    transform: none;
  }
}

.huge-title {
  font-family: var(--font-family-2);
  font-size: 11rem;
  line-height: 1.1;
  color: var(--primary-color);
}
@media screen and (max-width: 1400px) {
  .huge-title {
    font-size: 10rem;
  }
}
@media screen and (max-width: 1300px) {
  .huge-title {
    font-size: 9rem;
  }
}
@media screen and (max-width: 1200px) {
  .huge-title {
    font-size: 8rem;
  }
}
@media screen and (max-width: 1024px) {
  .huge-title {
    font-size: 7rem;
  }
}
@media screen and (max-width: 768px) {
  .huge-title {
    font-size: 6.5rem;
  }
}
@media screen and (max-width: 600px) {
  .huge-title {
    font-size: 6rem;
  }
}
@media screen and (max-width: 500px) {
  .huge-title {
    font-size: 5.5rem;
  }
}
@media screen and (max-width: 450px) {
  .huge-title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 400px) {
  .huge-title {
    font-size: 4.5rem;
  }
}
.huge-title--bold {
  font-weight: 700;
}
.huge-title--white {
  color: var(--primary-light-color);
}

.large-title--bold {
  font-weight: 700;
}

.large-title {
  font-family: var(--font-family-2);
  font-size: 5rem;
  line-height: 1.15;
  color: var(--primary-color);
}
@media screen and (max-width: 1400px) {
  .large-title {
    font-size: 4.8rem;
  }
}
@media screen and (max-width: 1300px) {
  .large-title {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 1200px) {
  .large-title {
    font-size: 4.3rem;
  }
}
@media screen and (max-width: 1024px) {
  .large-title {
    font-size: 4.2rem;
  }
}
@media screen and (max-width: 768px) {
  .large-title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .large-title {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 500px) {
  .large-title {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 450px) {
  .large-title {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 400px) {
  .large-title {
    font-size: 3rem;
  }
}
.large-title--bold {
  font-weight: 700;
}

.medium-title, #messageWrapper h3, #sengagerFormWrapper h3, .overlay-share__title {
  font-family: var(--font-family-1);
  font-size: 3rem;
  line-height: 1.15;
  color: var(--primary-color);
}
@media screen and (max-width: 1200px) {
  .medium-title, #messageWrapper h3, #sengagerFormWrapper h3, .overlay-share__title {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 1024px) {
  .medium-title, #messageWrapper h3, #sengagerFormWrapper h3, .overlay-share__title {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 768px) {
  .medium-title, #messageWrapper h3, #sengagerFormWrapper h3, .overlay-share__title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .medium-title, #messageWrapper h3, #sengagerFormWrapper h3, .overlay-share__title {
    font-size: 2.4rem;
  }
}
.medium-title--bold {
  font-weight: 700;
}
.medium-title--white {
  color: var(--primary-light-color);
}

.small-title {
  font-family: var(--font-family-1);
  font-size: 2.5rem;
  line-height: 1.15;
  color: var(--primary-color);
}
@media screen and (max-width: 1400px) {
  .small-title {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 1024px) {
  .small-title {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 768px) {
  .small-title {
    font-size: 2rem;
  }
}
.small-title--bold {
  font-weight: 700;
}

.intro {
  font-family: var(--font-family-2);
  font-size: 3rem;
  line-height: 1.6;
  color: var(--primary-color);
}
@media screen and (max-width: 1400px) {
  .intro {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 1200px) {
  .intro {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 1024px) {
  .intro {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 768px) {
  .intro {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 600px) {
  .intro {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 450px) {
  .intro {
    font-size: 2rem;
  }
}

.paragraph {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.6;
  color: var(--primary-color);
}

.field {
  --field-note-color: var(--other-1);
  --field-font-family: var(--font-family-1);
  --field-background-inactive: transparent;
  --field-color-inactive: var(--secondary-light-color);
  --field-border-color-inactive: var(--secondary-light-color);
  --field-error-color: var(--secondary-color);
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .field {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .field {
    margin-bottom: 40px;
  }
}
.field__error {
  margin-top: 8px;
  font: 1.4rem/1 var(--field-font-family);
  color: var(--field-error-color);
}
.field__note.dynamic {
  margin-top: 8px;
  color: var(--field-note-color);
  font-size: 1.4rem;
}
.field__note.dynamic p, .field__note.dynamic li, .field__note.dynamic table {
  font-size: inherit;
}
.field ::-webkit-input-placeholder {
  color: var(--field-color-inactive);
}
.field ::-moz-placeholder {
  color: var(--field-color-inactive);
}
.field :-ms-input-placeholder {
  color: var(--field-color-inactive);
}
.field :-moz-placeholder {
  color: var(--field-color-inactive);
}
.field--title {
  font-family: var(--font-family-1);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.15;
  color: var(--primary-color);
  margin-bottom: 30px;
}
@media screen and (max-width: 1400px) {
  .field--title {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 1024px) {
  .field--title {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 768px) {
  .field--title {
    font-size: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .field--title {
    font-size: 1.8rem;
  }
}

.field--input {
  --field-label-padding: 0 0 5px 0;
  --field-label-font-family: var(--font-family-1);
  --field-label-font-size: 1.5rem;
  --field-label-font-weight: normal;
  --field-label-line-height: 1;
  --field-label-text-transform: inherit;
  --field-label-letter-spacing: inherit;
  --field-label-color: var(--primary-color);
  --field-note-color: var(--other-1);
  --field-padding: 8px 0;
  --field-font-family: var(--font-family-1);
  --field-font-size: 1.7rem;
  --field-font-weight: normal;
  --field-line-height: 1.6;
  --field-text-transform: inherit;
  --field-letter-spacing: inherit;
  --field-color: var(--primary-color);
  --field-border-color: var(--primary-color);
  --field-background: transparent;
  --field-background-inactive: transparent;
  --field-color-inactive: var(--secondary-light-color);
  --field-border-color-inactive: var(--primary-color);
  --field-error-color: var(--secondary-color);
}

.field--input .field__input {
  width: 100%;
  padding: var(--field-padding);
  font-family: var(--field-font-family);
  font-size: var(--field-font-size);
  font-weight: var(--field-font-weight);
  line-height: var(--field-line-height);
  text-transform: var(--field-text-transform);
  letter-spacing: var(--field-letter-spacing);
  color: var(--field-color);
  background: var(--field-background-inactive);
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-tap-highlight-color: var(--primary-color);
  box-shadow: inset 0 -1px 0 0 var(--field-border-color-inactive);
  transition: box-shadow 0.3s;
}
.field--input .field__input::placeholder {
  color: #a5b2b6;
}
.field--input .field__label {
  display: block;
  padding: var(--field-label-padding);
  font-family: var(--field-label-font-family);
  font-size: var(--field-label-font-size);
  font-weight: var(--field-label-font-weight);
  line-height: var(--field-label-line-height);
  text-transform: var(--field-label-text-transform);
  letter-spacing: var(--field-label-letter-spacing);
  color: var(--field-label-color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.field--input .field__input.error {
  box-shadow: inset 0 -1px 0 0 var(--field-error-color);
}
.field--input .field__input:focus {
  box-shadow: inset 0 -2px 0 0 var(--field-border-color);
}
.field--input .field__input.error.focus {
  box-shadow: inset 0 -2px 0 0 var(--field-error-color);
}

.field--textarea {
  --field-label-padding: 0 0 5px 0;
  --field-label-font-family: var(--font-family-1);
  --field-label-font-size: 1.5rem;
  --field-label-font-weight: normal;
  --field-label-line-height: 1;
  --field-label-text-transform: inherit;
  --field-label-letter-spacing: inherit;
  --field-label-color: var(--primary-color);
  --field-note-color: var(--other-1);
  --field-padding: 8px 0;
  --field-font-family: var(--font-family-1);
  --field-font-size: 1.7rem;
  --field-font-weight: normal;
  --field-line-height: 1.6;
  --field-text-transform: inherit;
  --field-letter-spacing: inherit;
  --field-color: var(--primary-color);
  --field-border-color: var(--primary-color);
  --field-background: transparent;
  --field-background-inactive: transparent;
  --field-color-inactive: var(--secondary-light-color);
  --field-border-color-inactive: var(--primary-color);
  --field-error-color: var(--secondary-color);
}
@media screen and (max-width: 600px) {
  .field--textarea {
    --field-padding: 15px 0;
  }
}

.field--textarea .field__input {
  width: 100%;
  padding: var(--field-padding);
  font-family: var(--field-font-family);
  font-size: var(--field-font-size);
  font-weight: var(--field-font-weight);
  line-height: var(--field-line-height);
  text-transform: var(--field-text-transform);
  letter-spacing: var(--field-letter-spacing);
  color: var(--field-color);
  background: var(--field-background-inactive);
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-tap-highlight-color: var(--primary-color);
  box-shadow: inset 0 -1px 0 0 var(--field-border-color-inactive);
  transition: box-shadow 0.3s;
  outline: none;
  resize: none;
  min-height: 50px;
}
.field--textarea .field__label {
  display: block;
  padding: var(--field-label-padding);
  font-family: var(--field-label-font-family);
  font-size: var(--field-label-font-size);
  font-weight: var(--field-label-font-weight);
  line-height: var(--field-label-line-height);
  text-transform: var(--field-label-text-transform);
  letter-spacing: var(--field-label-letter-spacing);
  color: var(--field-label-color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.field--textarea .field__input.error {
  box-shadow: inset 0 -1px 0 0 var(--field-error-color);
}
.field--textarea .field__input:focus {
  box-shadow: inset 0 -2px 0 0 var(--field-border-color);
}
.field--textarea .field__input.error.focus {
  box-shadow: inset 0 -2px 0 0 var(--field-error-color);
}

.field--checkbox {
  --box-dimension: 20px;
  --box-margin-right: 15px;
  --radio-checkbox-font-family: var(--font-family-1);
  --radio-checkbox-font-size: 1.7rem;
  --radio-checkbox-font-weight: normal;
  --radio-checkbox-line-height: 1;
  --radio-checkbox-text-transform: inherit;
  --radio-checkbox-letter-spacing: inherit;
  --radio-checkbox-color: var(--primary-color);
  --radio-hook-dimension: 12px;
  --radio-dot-dimension: 10px;
  --radio-dot-color: var(--secondary-color);
  --radio-dot-border: 1px solid var(--primary-color);
  --radio-dot-border-active: 1px solid var(--primary-color);
  --checkbox-box-background: white;
  --checkbox-box-background-active: var(--secondary-color);
  --checkbox-border: 1px solid var(--primary-color);
  --checkbox-border-active: 1px solid var(--secondary-color);
  --field-label-font-family: var(--font-family-1);
  --field-label-font-size: 1.5rem;
  --field-label-font-weight: normal;
  --field-label-line-height: 1;
  --field-label-text-transform: inherit;
  --field-label-letter-spacing: inherit;
  --field-label-color: var(--primary-color);
}

.field--checkbox {
  margin-bottom: 40px;
}
.field--checkbox .field__main-label {
  display: block;
  margin-bottom: 30px;
  font-family: var(--field-label-font-family);
  font-size: var(--field-label-font-size);
  font-weight: var(--field-label-font-weight);
  line-height: var(--field-label-line-height);
  text-transform: var(--field-label-text-transform);
  letter-spacing: var(--field-label-letter-spacing);
  color: var(--field-label-color);
}
.field--checkbox .field__label {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.field--checkbox .field__options :last-child {
  margin-bottom: 0;
}
.field--checkbox .field__text {
  flex: 1;
  font-family: var(--radio-checkbox-font-family);
  font-size: var(--radio-checkbox-font-size);
  font-weight: var(--radio-checkbox-font-weight);
  line-height: var(--radio-checkbox-line-height);
  text-transform: var(--radio-checkbox-text-transform);
  letter-spacing: var(--radio-checkbox-letter-spacing);
  color: var(--radio-checkbox-color);
  order: 2;
  user-select: none;
}
.field--checkbox .field__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.field--checkbox .field__input:checked ~ .field__box {
  background: var(--checkbox-box-background-active);
  border: var(--checkbox-border-active);
}
.field--checkbox .field__input:checked ~ .field__box:before {
  transform: translate(-50%, -50%) scale(1);
}
.field--checkbox .field__box {
  position: relative;
  width: var(--box-dimension);
  height: var(--box-dimension);
  margin-right: var(--box-margin-right);
  order: 1;
  transition: background 225ms, border 225ms;
  border-radius: 3px;
  border: var(--checkbox-border);
  background: var(--checkbox-box-background);
  align-self: flex-start;
}
.field--checkbox .field__box:before {
  content: "";
  width: var(--radio-hook-dimension);
  height: var(--radio-hook-dimension);
  background: url("../medias/images/icons/checkmark.svg") no-repeat center/contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms;
}
.field--checkbox-inline .field__label {
  display: inline-flex;
  margin: 0 30px 20px 0;
}
.field--checkbox-inline .field__options {
  margin-bottom: -20px;
}

.field--radio {
  --box-dimension: 20px;
  --box-margin-right: 15px;
  --radio-checkbox-font-family: var(--font-family-1);
  --radio-checkbox-font-size: 1.7rem;
  --radio-checkbox-font-weight: normal;
  --radio-checkbox-line-height: 1;
  --radio-checkbox-text-transform: inherit;
  --radio-checkbox-letter-spacing: inherit;
  --radio-checkbox-color: var(--primary-color);
  --radio-box-background: white;
  --radio-box-background-active: white;
  --radio-hook-dimension: 12px;
  --radio-dot-dimension: 10px;
  --radio-dot-color: var(--secondary-color);
  --radio-dot-border: 1px solid var(--primary-color);
  --radio-dot-border-active: 1px solid var(--primary-color);
  --field-label-font-family: var(--font-family-1);
  --field-label-font-size: 1.5rem;
  --field-label-font-weight: normal;
  --field-label-line-height: 1;
  --field-label-text-transform: inherit;
  --field-label-letter-spacing: inherit;
  --field-label-color: var(--primary-color);
}

.field--radio .field__main-label {
  display: block;
  margin-bottom: 30px;
  font-family: var(--field-label-font-family);
  font-size: var(--field-label-font-size);
  font-weight: var(--field-label-font-weight);
  line-height: var(--field-label-line-height);
  text-transform: var(--field-label-text-transform);
  letter-spacing: var(--field-label-letter-spacing);
  color: var(--field-label-color);
}
.field--radio .field__label {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.field--radio .field__options :last-child {
  margin-bottom: 0;
}
.field--radio .field__text {
  flex: 1;
  font-family: var(--radio-checkbox-font-family);
  font-size: var(--radio-checkbox-font-size);
  font-weight: var(--radio-checkbox-font-weight);
  line-height: var(--radio-checkbox-line-height);
  text-transform: var(--radio-checkbox-text-transform);
  letter-spacing: var(--radio-checkbox-letter-spacing);
  color: var(--radio-checkbox-color);
  order: 2;
  user-select: none;
}
.field--radio .field__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.field--radio .field__input:checked ~ .field__box {
  background: var(--radio-box-background-active);
  border: var(--radio-dot-border-active);
}
.field--radio .field__input:checked ~ .field__box:before {
  transform: translate(-50%, -50%) scale(1);
}
.field--radio .field__box {
  position: relative;
  width: var(--box-dimension);
  height: var(--box-dimension);
  margin-right: var(--box-margin-right);
  order: 1;
  transition: background 225ms, border 225ms;
  border-radius: 50%;
  border: var(--radio-dot-border);
  background: var(--radio-box-background);
}
.field--radio .field__box:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms;
  width: var(--radio-dot-dimension);
  height: var(--radio-dot-dimension);
  background: var(--radio-dot-color);
  border-radius: 50%;
}
.field--radio-inline .field__label {
  display: inline-flex;
  margin: 0 30px 20px 0;
}
.field--radio-inline .field__options {
  margin-bottom: -20px;
}

.field--file {
  --file-padding: 20px 60px 20px 20px;
  --file-border-top: 0;
  --file-border-right: var(--file-border-top);
  --file-border-bottom: var(--file-border-top);
  --file-border-left: var(--file-border-top);
  --file-font-family: var(--font-family-1);
  --file-font-size: 1.4rem;
  --file-font-weight: 600;
  --file-line-height: 1.5;
  --file-text-transform: inherit;
  --file-letter-spacing: inherit;
  --file-background-color: var(--secondary-light-color);
  --file-over-background: var(--primary-color);
  --file-color: var(--primary-color);
  --file-over-color: #fff;
  --file-x-svg-dimension: 18px;
  --file-x-svg-top: calc(50% - (var(--file-x-svg-dimension) / 2));
  --file-x-svg-right: 20px;
  --file-x-color: var(--file-color);
  --file-x-over-color: var(--secondary-color);
  --file-svg-color: var(--file-color);
  --file-svg-over-color: var(--file-over-color);
}

.field--file .field__input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}
.field--file .field__label {
  display: block;
  position: relative;
  width: 100%;
  padding: var(--file-padding);
  border-top: var(--file-border-top);
  border-right: var(--file-border-right);
  border-bottom: var(--file-border-bottom);
  border-left: var(--file-border-left);
  cursor: pointer;
  background-color: var(--file-background-color);
  transition: color 300ms, background 300ms;
}
.field--file .field__label:hover {
  background: var(--file-over-background);
  color: var(--file-over-color);
}
.field--file .field__text {
  font-family: var(--file-font-family);
  font-size: var(--file-font-size);
  font-weight: var(--file-font-weight);
  line-height: var(--file-line-height);
  text-transform: var(--file-text-transform);
  letter-spacing: var(--file-letter-spacing);
  color: var(--file-color);
  transition: color 300ms;
}
.field--file .field__svg {
  position: absolute;
  width: var(--file-x-svg-dimension);
  height: var(--file-x-svg-dimension);
  top: var(--file-x-svg-top);
  right: var(--file-x-svg-right);
  fill: var(--file-svg-color);
  transition: fill 300ms, opacity 300ms;
}
.field--file .field__x {
  position: absolute;
  width: var(--file-x-svg-dimension);
  height: var(--file-x-svg-dimension);
  top: 22px;
  right: var(--file-x-svg-right);
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  pointer-events: none;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
.field--file .field__x:before, .field__x:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: var(--file-x-color);
  transition: background 300ms;
}
@media screen and (max-width: 600px) {
  .field--file .field__x:before, .field__x:after {
    width: 20px;
  }
}
.field--file .field__x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.field--file .field__x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.field--file[data-file]:not([data-file=""]) .field__label {
  pointer-events: none;
}
.field--file[data-file]:not([data-file=""]) .field__svg {
  opacity: 0;
}
.field--file[data-file]:not([data-file=""]) .field__x {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.field--file .field__label:hover .field__text {
  color: var(--file-over-color);
}
.field--file .field__label:hover .field__svg {
  fill: var(--file-svg-over-color);
}
.field--file .field__x:hover:before, .field--file .field__x:hover:after {
  background: var(--file-x-over-color);
}

.field--select {
  --tail-label-padding: 20px 40px 20px 0;
  --tail-label-font-family: var(--font-family-1);
  --tail-label-font-size: 1.7rem;
  --tail-label-font-weight: normal;
  --tail-label-line-height: 1.5;
  --tail-label-text-transform: inherit;
  --tail-label-letter-spacing: inherit;
  --tail-label-color: var(--primary-color);
  --tail-label-color-active: var(--primary-rgb-color);
  --tail-label-background: transparent;
  --tail-label-background-active: transparent;
  --tail-label-background-hover: transparent;
  --tail-label-border: 1px solid var(--primary-color);
  --tail-label-border-active: 1px solid var(--primary-rgb-color);
  --tail-label-transition: background 500ms, border-color 500ms;
  --tail-label-pointer-color: var(--secondary-color);
  --tail-label-pointer-color-active: var(--secondary-color);
  --tail-option-padding: 20px 40px;
  --tail-option-font-family: var(--font-family-1);
  --tail-option-font-size: 1.7rem;
  --tail-option-font-weight: 400;
  --tail-option-line-height: 1.5;
  --tail-option-text-transform: inherit;
  --tail-option-letter-spacing: inherit;
  --tail-option-color: var(--primary-color);
  --tail-option-background: transparent;
  --tail-option-color-hover: var(--primary-color);
  --tail-option-background-hover: var(--secondary-light-color);
  --tail-option-color-selected: var(--secondary-color);
  --tail-option-background-selected: var(--secondary-light-color);
  --field-label-font-family: var(--font-family-1);
  --field-label-font-size: 1.5rem;
  --field-label-font-weight: normal;
  --field-label-line-height: 1;
  --field-label-text-transform: inherit;
  --field-label-letter-spacing: inherit;
  --field-label-color: var(--primary-color);
}

.field--select .field__label {
  display: block;
  padding: var(--field-label-padding);
  font-family: var(--field-label-font-family);
  font-size: var(--field-label-font-size);
  font-weight: var(--field-label-font-weight);
  line-height: var(--field-label-line-height);
  color: var(--field-label-color);
}
.field--select .tail-select {
  position: relative;
  z-index: 1;
}
.field--select .tail-select svg {
  position: absolute;
  width: 10px;
  height: 18px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: transform 500ms, fill 500ms;
  stroke: var(--tail-label-pointer-color);
  stroke-width: 2px;
  fill: none;
}
.field--category .tail-select svg {
  stroke: var(--primary-color);
  right: 40px;
}
@media screen and (max-width: 768px) {
  .field--category .tail-select svg {
    right: 30px;
  }
}
.field--select .select-label {
  padding: var(--tail-label-padding);
  border: 0;
  border-bottom: var(--tail-label-border);
  background: var(--tail-label-background);
  transition: var(--tail-label-transition);
  cursor: pointer;
  user-select: none;
}
.field--select .select-label:hover {
  background: var(--tail-label-background-hover);
}
.field--category .select-label {
  border: var(--tail-label-border);
  transition: border 2000ms;
  border-radius: 100px;
  padding-right: 60px;
  padding-left: 40px;
}
@media screen and (max-width: 768px) {
  .field--category .select-label {
    padding-right: 30px;
    padding-right: 50px;
  }
}
.field--select .label-inner {
  display: block;
  position: relative;
  font-family: var(--tail-label-font-family);
  font-size: var(--tail-label-font-size);
  font-weight: var(--tail-label-font-weight);
  line-height: var(--tail-label-line-height);
  text-transform: var(--tail-label-text-transform);
  letter-spacing: var(--tail-label-letter-spacing);
  color: var(--tail-label-color);
}
.field--category .label-inner {
  font-size: 1.4rem;
  font-weight: 600;
}
.field--select .tail-select.no-classes svg {
  transform: translateY(-50%) rotate(90deg);
}
.field--select .select-dropdown {
  padding-top: 65px;
  position: absolute;
  width: 100%;
  top: 0;
  left: -40px;
  right: -40px;
  opacity: 0;
  background: #fff;
  border-top: 1px solid var(--color8);
  z-index: -1;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 500ms var(--ease-in-out), visibility 0ms ease 500ms;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
  width: auto;
}
.field--category .select-dropdown {
  border-radius: 30px;
  right: 0;
  left: 0;
}
.field--select .dropdown-inner {
  overflow: auto;
  max-height: 0;
  transition: max-height 500ms var(--ease-in-out);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.field--category .dropdown-inner {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.field--category .dropdown-inner::-webkit-scrollbar {
  display: none;
}
.field--select .dropdown-option {
  padding: var(--tail-option-padding);
  font-family: var(--tail-option-font-family);
  font-size: var(--tail-option-font-size);
  font-weight: var(--tail-option-font-weight);
  line-height: var(--tail-option-line-height);
  text-transform: var(--tail-option-text-transform);
  letter-spacing: var(--tail-option-letter-spacing);
  color: var(--tail-option-color);
  background: var(--tail-option-background);
  cursor: pointer;
  transition: background 300ms, color 300ms;
  user-select: none;
}
.field--select .dropdown-option:hover {
  color: var(--tail-option-color-hover);
  background: var(--tail-option-background-hover);
}
.field--select .dropdown-option.selected {
  color: var(--tail-option-color-selected);
  background: var(--tail-option-background-selected);
}
.field--category .dropdown-option {
  font-size: 1.4rem;
  font-weight: 600;
}
.field--select select {
  width: 100%;
  padding: var(--tail-label-padding);
  font-family: var(--tail-label-font-family);
  font-size: var(--tail-label-font-size);
  font-weight: var(--tail-label-font-weight);
  line-height: var(--tail-label-line-height);
  text-transform: var(--tail-label-text-transform);
  letter-spacing: var(--tail-label-letter-spacing);
  color: var(--tail-label-color);
  border: none;
  border-bottom: var(--tail-label-border);
  background: transparent;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  background: url("../medias/images/icons/chevron-select-natif.svg") no-repeat calc(100% - 20px) 50%/12px 12px;
}
.field--category select {
  font-size: 1.4rem;
  font-weight: 600;
  border: var(--tail-label-border);
  border-radius: 100px;
  padding-right: 40px;
  padding-left: 40px;
  background: url("../medias/images/icons/chevron-category.svg") no-repeat calc(100% - 40px) 50%/12px 12px;
}
@media screen and (max-width: 768px) {
  .field--category select {
    padding-right: 30px;
    padding-left: 30px;
    background-position-x: calc(100% - 30px);
  }
}
@media screen and (max-width: 600px) {
  .field--category select {
    padding-right: 25px;
    padding-left: 25px;
    background-position-x: calc(100% - 25px);
  }
}
@media screen and (max-width: 500px) {
  .field--category select {
    padding: 15px 20px;
    background-position-x: calc(100% - 20px);
  }
}
.field select::-ms-expand {
  display: none;
}
.field--select .tail-select.active svg {
  transform: translateY(-50%) rotate(-90deg);
  stroke: var(--tail-label-pointer-color-active);
}
.field--select .tail-select.active .select-label {
  background: var(--tail-label-background-active);
  border-bottom: 1px solid rgba(var(--tail-label-color-active), 0.5);
}
.field--select .tail-select.active .label-inner {
  color: rgba(var(--tail-label-color-active), 0.5);
}
.field--select .tail-select.active .select-dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: opacity 500ms var(--ease-in-out);
}
.field--select .tail-select.active .dropdown-inner {
  max-height: 195px !important;
}
.field--category .tail-select.active svg {
  stroke: var(--primary-color);
}
.field--category .tail-select.active .select-label {
  border: 1px solid #fff;
  transition: border 300ms;
  border-radius: 30px;
  height: 63px;
}

.field--search {
  text-align: right;
}
@media screen and (max-width: 600px) {
  .field--search {
    margin-bottom: 0;
  }
}
.field--search ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
}
.field--search ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
}
.field--search :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
}
.field--search :-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
}
.field--search .field__input {
  width: 100%;
  height: 90px;
  padding: 0 200px 0 60px;
  font: 4rem/1.3 var(--font-family-1);
  color: #fff;
  text-align: left;
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  transition: border 300ms;
}
@media screen and (max-width: 1100px) {
  .field--search .field__input {
    height: 70px;
    padding-left: 50px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .field--search .field__input {
    height: 50px;
    padding-left: 40px;
    padding-right: 30px;
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 500px) {
  .field--search .field__input {
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 425px) {
  .field--search .field__input {
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 1.9rem;
  }
}
.field--search .field__x {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  right: 160px;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
@media screen and (max-width: 600px) {
  .field--search .field__x {
    top: 13px;
    right: 0;
    transform: none;
  }
}
.field--search .field__x:before, .field--search .field__x:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #fff;
}
@media screen and (max-width: 600px) {
  .field--search .field__x:before, .field--search .field__x:after {
    width: 20px;
  }
}
.field--search .field__x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.field--search .field__x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.field--search .field__svg {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 0;
  fill: #fff;
  transform: translateY(-50%);
}
@media screen and (max-width: 1100px) {
  .field--search .field__svg {
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 600px) {
  .field--search .field__svg {
    width: 18px;
    height: 18px;
    top: 16px;
    transform: none;
  }
}
.field--search .field__button {
  position: absolute;
  padding: 20px;
  top: 50%;
  right: 0;
  stroke: #fff;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .field--search .field__button {
    position: relative;
    width: 100%;
    margin-top: 20px;
    top: 0;
    text-align: center;
    transform: none;
  }
}
.field--search[data-inputvalue]:not([data-inputvalue=""]) .field__x {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms;
}

.button {
  display: inline-flex;
  padding: 20px 40px;
  background: var(--secondary-color);
  align-items: center;
  border-radius: 50px;
  transition: background 300ms;
}
@media screen and (max-width: 1200px) {
  .button {
    padding: 18px 35px;
  }
}
@media screen and (max-width: 1024px) {
  .button {
    padding: 17px 32px;
  }
}
@media screen and (max-width: 900px) {
  .button {
    padding: 16px 30px;
  }
}
.button:hover {
  background: var(--primary-color);
}
.button__svg {
  width: 30px;
  height: 25px;
  fill: none;
  stroke: var(--primary-light-color);
  stroke-width: 2px;
  margin-right: 10px;
  transition: stroke 300ms;
}
.button[target=_blank] svg, .button[href$=".pdf"] svg, .button[href$=".doc"] svg, .button[href$=".zip"] svg {
  content: "";
  fill: #fff;
  display: inline-block;
  margin-left: 10px;
  transition: fill 0.3s var(--ease__in-out);
  width: 13px;
  height: 20px;
  flex-shrink: 0;
}
.button[href$=".pdf"] svg, .button[href$=".doc"] svg, .button[href$=".zip"] svg {
  width: 12px;
  height: 12px;
}
.button, .button__text {
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  color: var(--primary-light-color);
  transition: color 300ms, background 300ms;
}
@media screen and (max-width: 1024px) {
  .button, .button__text {
    font-size: 1.4rem;
  }
}

.button--white-hover:hover, .header--light .header__button:hover {
  background: var(--primary-light-color);
}
.button--white-hover:hover .button__svg, .header--light .header__button:hover .button__svg {
  stroke: var(--secondary-color);
}
.button--white-hover:hover .button__text, .header--light .header__button:hover .button__text {
  color: var(--secondary-color);
}

.link {
  display: inline-flex;
  position: relative;
  padding-right: 25px;
  align-items: center;
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: var(--secondary-color);
  transition: color 300ms;
}
.link:before, .link:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 14px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: opacity 300ms;
}
.link:before {
  background: url("../medias/images/icons/orange-pointer.svg") no-repeat center/contain;
  opacity: 1;
}
.link:after {
  background: url("../medias/images/icons/blue-pointer.svg") no-repeat center/contain;
  opacity: 0;
}
.link:hover {
  color: var(--primary-color);
}
.link:hover:before {
  opacity: 0;
}
.link:hover:after {
  opacity: 1;
}

.menu-button {
  position: relative;
  width: 36px;
  height: 18px;
}
@media screen and (max-width: 1024px) {
  .menu-button {
    width: 34px;
  }
}
.menu-button__lines {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.menu-button__line {
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--primary-color);
  opacity: 1;
  left: 0%;
  transform: rotate(0deg);
  transition: 300ms;
}
.menu-button:hover .menu-button__line {
  background: var(--secondary-color);
}
.menu-button__line--1 {
  top: 0px;
}
.menu-button__line--2, .menu-button__line--3 {
  top: 50%;
}
.menu-button__line--4 {
  bottom: 0px;
}

.menu-button--light .menu-button__line, .header--light .header__menu .menu-button__line {
  background: var(--primary-light-color);
}

.play-button {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--primary-color);
  transition: background 300ms;
}
@media screen and (max-width: 768px) {
  .play-button {
    width: 44px;
    height: 44px;
  }
}
.play-button:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 53%;
  border-width: 8px 0 8px 13.9px;
  border-color: transparent transparent transparent var(--primary-light-color);
  border-style: solid;
  transform: translate(-50%, -50%);
  transition: border-color 300ms;
}
.play-button--light {
  background: var(--primary-light-color);
}
.play-button--light:before {
  border-color: transparent transparent transparent var(--secondary-color);
}
.play-button--light:hover {
  background: var(--secondary-color);
}
.play-button--light:hover:before {
  border-color: transparent transparent transparent var(--primary-light-color);
}

:root {
  --back-to-top__size: 60px;
  --back-to-top__icon-size: 20px;
  --back-to-top__distance: 50px;
}
@media screen and (max-width: 1024px) {
  :root {
    --back-to-top__size: 50px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --back-to-top__icon-size: 18px;
  }
}

.back-to-top {
  position: absolute;
  top: 0;
  bottom: calc(var(--back-to-top__size) / -2);
  width: 110px;
  right: 0;
  z-index: 20;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease;
}
@media screen and (max-width: 1024px) {
  .back-to-top {
    width: 100px;
  }
}
@media screen and (max-width: 600px) {
  .back-to-top {
    width: 75px;
  }
}
@media screen and (max-width: 500px) {
  .back-to-top {
    display: none;
  }
}
.back-to-top__button {
  width: var(--back-to-top__size);
  height: var(--back-to-top__size);
  position: sticky;
  bottom: var(--back-to-top__distance);
  align-self: flex-end;
  cursor: pointer;
  pointer-events: none;
}
.back-to-top__button::after {
  content: "";
  position: absolute;
  background-color: var(--secondary-color);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: background 300ms var(--ease-in-out);
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 0;
  will-change: transform;
}
.back-to-top__button:hover::after {
  background: var(--primary-color);
}
.back-to-top__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--back-to-top__icon-size);
  height: var(--back-to-top__icon-size);
  fill: none;
  stroke-width: 2px;
  stroke: #fff;
  transform: translate(-50%, -50%) rotate(-90deg);
  z-index: 1;
}

:root {
  --header__container--padding-top: 40px;
  --header__logo-img--height: 100px;
  --header--height: calc(var(--header__container--padding-top) + var(--header__logo-img--height));
}
@media screen and (max-width: 1200px) {
  :root {
    --header__container--padding-top: 35px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --header__container--padding-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --header__container--padding-top: 25px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --header__container--padding-top: 20px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --header__logo-img--height: 85px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --header__logo-img--height: 75px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --header__logo-img--height: 65px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --header__logo-img--height: 58px;
  }
}

.header {
  position: relative;
  height: var(--header--height);
  z-index: 2;
}
.header__container {
  display: flex;
  padding: var(--header__container--padding-top) var(--x-spacing-100) 0;
  align-items: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out);
}
[data-fire] .header__container {
  opacity: 1;
  transform: none;
}
.header__logo-img {
  height: var(--header__logo-img--height);
}
.header__links-item {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.header__link {
  position: relative;
  margin-right: 40px;
}
@media screen and (max-width: 1200px) {
  .header__link {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .header__link {
    margin-right: 25px;
  }
}
@media screen and (max-width: 900px) {
  .header__link {
    display: none;
  }
}
.header__link--count {
  padding-right: 25px;
}
.header__text {
  padding: 3px 0;
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;
  color: var(--primary-color);
  transition: box-shadow 300ms, color 300ms;
}
@media screen and (max-width: 1024px) {
  .header__text {
    font-size: 1.4rem;
  }
}
.header__link:hover .header__text {
  color: var(--secondary-color);
  box-shadow: inset 0 -1px 0 0 var(--secondary-color);
}
.header__count {
  position: absolute;
  width: 21px;
  height: 21px;
  top: -11px;
  right: 0;
  font-family: var(--font-family-1);
  font-size: 1rem;
  font-weight: 700;
  line-height: 21px;
  color: var(--primary-light-color);
  text-align: center;
  z-index: 1;
}
.header__count:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  border-radius: 50%;
  z-index: -1;
  transition: background-color 300ms;
}
.header__link:hover .header__count:before {
  background: var(--secondary-color);
}
.header__button {
  margin-right: 30px;
}
@media screen and (max-width: 1024px) {
  .header__button {
    margin-right: 25px;
  }
}
@media screen and (max-width: 500px) {
  .header__button {
    display: none;
  }
}

.header--light {
  background-color: var(--primary-color);
}
.header--light .header__text {
  color: var(--primary-light-color);
}
.header--light .header__link:hover .header__text {
  color: var(--secondary-color);
  box-shadow: inset 0 -1px 0 0 var(--secondary-color);
}
.header--light .header__count {
  color: var(--primary-color);
  transition: color 300ms;
}
.header--light .header__count:before {
  background-color: var(--primary-light-color);
}
.header--light .header__link:hover .header__count {
  color: var(--primary-light-color);
}
.header--light .header__link:hover .header__count:before {
  background: var(--secondary-color);
}
.footer__container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 80px var(--x-spacing-110) 100px;
  background: var(--primary-color);
}
@media screen and (max-width: 1024px) {
  .footer__container {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 950px) {
  .footer__container {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .footer__container {
    padding-top: 60px;
    padding-bottom: 45px;
  }
}
@media screen and (max-width: 600px) {
  .footer__container {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}
.footer__logo-item {
  width: 150px;
}
@media screen and (max-width: 1200px) {
  .footer__logo-item {
    width: 130px;
  }
}
@media screen and (max-width: 950px) {
  .footer__logo-item {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .footer__logo-item {
    margin-bottom: 30px;
  }
}
.footer__logo-img {
  width: 150px;
}
@media screen and (max-width: 1200px) {
  .footer__logo-img {
    width: 130px;
  }
}
@media screen and (max-width: 950px) {
  .footer__logo-img {
    width: 150px;
  }
}
.footer__links-item {
  display: flex;
  flex: 1;
  padding-left: 15%;
}
@media screen and (max-width: 1600px) {
  .footer__links-item {
    padding-left: 10%;
  }
}
@media screen and (max-width: 1400px) {
  .footer__links-item {
    padding-left: 100px;
  }
}
@media screen and (max-width: 1400px) {
  .footer__links-item {
    padding-left: 100px;
  }
}
@media screen and (max-width: 1300px) {
  .footer__links-item {
    padding-left: 80px;
  }
}
@media screen and (max-width: 1200px) {
  .footer__links-item {
    padding-left: 70px;
  }
}
@media screen and (max-width: 1100px) {
  .footer__links-item {
    padding-left: 60px;
  }
}
@media screen and (max-width: 950px) {
  .footer__links-item {
    flex: initial;
    width: 100%;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .footer__links-item {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 500px) {
  .footer__links-item {
    display: none;
  }
}
.footer__links-column {
  display: flex;
  width: 220px;
  flex-direction: column;
}
@media screen and (max-width: 1400px) {
  .footer__links-column {
    width: 200px;
  }
}
@media screen and (max-width: 1300px) {
  .footer__links-column {
    width: 180px;
  }
}
@media screen and (max-width: 1100px) {
  .footer__links-column {
    width: 160px;
  }
}
@media screen and (max-width: 950px) {
  .footer__links-column {
    width: 200px;
    text-align: center;
  }
}
@media screen and (min-width: 951px) {
  .footer__links-column:last-child {
    width: auto;
  }
}
.footer__link {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.5;
  color: var(--primary-light-color);
  transition: color 300ms;
}
.footer__link:hover {
  color: var(--other-1);
}
.footer__link-container:not(:last-child) {
  margin-bottom: 20px;
}
@media screen and (max-width: 1100px) {
  .footer__link-container:not(:last-child) {
    margin-bottom: 15px;
  }
}
.footer__newsletter-item {
  width: 326px;
}
@media screen and (max-width: 1200px) {
  .footer__newsletter-item {
    width: 290px;
  }
}
@media screen and (max-width: 950px) {
  .footer__newsletter-item {
    width: 100%;
    text-align: center;
  }
}
.footer__newsletter-subtitle {
  display: block;
  margin-bottom: 25px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.5;
  color: var(--primary-light-color);
}
@media screen and (max-width: 1024px) {
  .footer__newsletter-subtitle {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .footer__newsletter-subtitle {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 600px) {
  .footer__newsletter-subtitle {
    margin-bottom: 10px;
  }
}
.footer__newsletter-form {
  position: relative;
}
@media screen and (max-width: 950px) {
  .footer__newsletter-form {
    max-width: 400px;
    margin: 0 auto;
  }
}
.footer__newsletter-input {
  width: 100%;
  padding: 20px 25px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--primary-color);
  border: none;
  background: var(--primary-light-color);
  border-radius: 50px;
}
.footer__newsletter-form ::-webkit-input-placeholder {
  color: var(--primary-color);
}
.footer__newsletter-form ::-moz-placeholder {
  color: var(--primary-color);
}
.footer__newsletter-form :-ms-input-placeholder {
  color: var(--primary-color);
}
.footer__newsletter-form :-moz-placeholder {
  color: var(--primary-color);
}
.footer__newsletter-input:focus::-webkit-input-placeholder {
  color: rgba(var(--primary-rgb-color), 0);
}
.footer__newsletter-input:focus::-moz-placeholder {
  color: rgba(var(--primary-rgb-color), 0);
}
.footer__newsletter-input:focus:-ms-input-placeholder {
  color: rgba(var(--primary-rgb-color), 0);
}
.footer__newsletter-input:focus:-moz-placeholder {
  color: rgba(var(--primary-rgb-color), 0);
}
.footer__newsletter-submit {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
.footer__newsletter-submit-icon {
  position: relative;
  width: 14px;
  height: 14px;
  right: 0px;
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 3px;
  transition: right 300ms var(--ease-out);
}
.footer__newsletter-submit:hover .footer__newsletter-submit-icon {
  right: -5px;
}
.footer__secondary-logo-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 90px;
  width: 100%;
}
@media screen and (max-width: 950px) {
  .footer__secondary-logo-item {
    margin-top: 50px;
    text-align: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .footer__secondary-logo-item {
    margin-top: 30px;
  }
}
.footer__secondary-logo {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.footer__secondary-other-logo {
  margin-right: 120px;
  transform: translateY(20px);
}
@media screen and (max-width: 1024px) {
  .footer__secondary-other-logo {
    margin-right: 80px;
  }
}
@media screen and (max-width: 950px) {
  .footer__secondary-other-logo {
    margin-right: 0;
    margin-bottom: 40px;
    transform: translateY(0);
  }
}
.footer__secondary-other-logo-text {
  width: 410px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.4;
  color: var(--primary-light-color);
}
@media screen and (max-width: 950px) {
  .footer__secondary-other-logo-text {
    width: 100%;
  }
}
.footer__secondary-logo-img {
  width: 70px;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
  .footer__secondary-logo-img {
    margin-right: 15px;
  }
}
@media screen and (max-width: 500px) {
  .footer__secondary-logo-img {
    margin-right: 10px;
  }
}
.footer__secondary-other-logo-img {
  width: 130px;
  margin-bottom: 20px;
}
.footer__secondary-logo-img-2 {
  width: 290px;
}
.footer__secondary-logo-text {
  font-family: var(--font-family-2);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  color: var(--primary-light-color);
}
@media screen and (max-width: 768px) {
  .footer__secondary-logo-text {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 600px) {
  .footer__secondary-logo-text {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 500px) {
  .footer__secondary-logo-text {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 400px) {
  .footer__secondary-logo-text {
    font-size: 1.6rem;
  }
}
.footer .btnFooter {
  background: var(--primary-light-color);
  color: var(--primary-color);
}
.footer .btnFooter:hover {
  background: var(--secondary-color);
  color: var(--primary-light-color);
}

.copyright__container {
  display: flex;
  padding: 35px var(--x-spacing-110);
  border-top: 1px solid rgba(var(--primary-light-rgb-color), 0.3);
  background: var(--primary-color);
  flex-wrap: wrap;
  justify-content: space-between;
}
.copyright__texts-item {
  display: flex;
}
@media screen and (max-width: 950px) {
  .copyright__texts-item {
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.copyright__text {
  margin-right: 50px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.5;
  color: var(--primary-light-color);
}
@media screen and (max-width: 950px) {
  .copyright__text {
    width: 100%;
    margin-right: 0;
  }
}
.copyright__blanko-link {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.5;
  color: var(--primary-light-color);
  transition: color 300ms;
}
.copyright__blanko-link:hover {
  color: var(--other-1);
}
@media screen and (max-width: 950px) {
  .copyright__blanko-item {
    width: 100%;
    text-align: center;
  }
}

:root {
  --dynamic-main-color: var(--primary-color);
  --dynamic-main-color-rgb: var(--primary-rgb-color);
  --dynamic-p-font-line-height: 1.6em;
  --dynamic-ol-indent: 50px;
  --dynamic-ol-indent-last: 40px;
  --dynamic-ul-indent: 20px;
  --dynamic-ul-bullet-color: var(--secondary-color);
  --dynamic-table-header-background: var(--primary-color);
  --dynamic-table-header-color: #fff;
  --dynamic-table-border-color: var(--other-3);
  --dynamic-table-secondary-header-background: var(--secondary-light-color);
  --dynamic-note-color: var(--other-1);
  --dynamic-quotes-color: var(--secondary-color);
}
@media screen and (max-width: 768px) {
  :root {
    --dynamic-ol-indent: 25px;
    --dynamic-ol-indent-last: 25px;
  }
}

.dynamic {
  /* ----- Image - No Caption ----- */
  /* ----- Image - With Caption ----- */
}
.dynamic > :first-child {
  margin-top: 0 !important;
}
.dynamic > :last-child {
  margin-bottom: 0 !important;
}
.dynamic h1 {
  font-family: var(--font-family-2);
  font-size: 11rem;
  font-weight: 700;
  line-height: 1.1;
  color: var(--primary-color);
  margin-top: 100px;
  margin-bottom: 100px;
}
@media screen and (max-width: 1400px) {
  .dynamic h1 {
    font-size: 10rem;
  }
}
@media screen and (max-width: 1300px) {
  .dynamic h1 {
    font-size: 9rem;
  }
}
@media screen and (max-width: 1200px) {
  .dynamic h1 {
    font-size: 8rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h1 {
    font-size: 7rem;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h1 {
    font-size: 6.5rem;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h1 {
    font-size: 6rem;
  }
}
@media screen and (max-width: 500px) {
  .dynamic h1 {
    font-size: 5.5rem;
  }
}
@media screen and (max-width: 450px) {
  .dynamic h1 {
    font-size: 5rem;
  }
}
@media screen and (max-width: 400px) {
  .dynamic h1 {
    font-size: 4.5rem;
  }
}
.dynamic h2 {
  font-family: var(--font-family-2);
  font-size: 5rem;
  font-weight: 700;
  line-height: 1.15;
  color: var(--primary-color);
  margin-top: 65px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1400px) {
  .dynamic h2 {
    font-size: 4.8rem;
  }
}
@media screen and (max-width: 1300px) {
  .dynamic h2 {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 1200px) {
  .dynamic h2 {
    font-size: 4.3rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h2 {
    font-size: 4.2rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 500px) {
  .dynamic h2 {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 450px) {
  .dynamic h2 {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 400px) {
  .dynamic h2 {
    font-size: 3rem;
  }
}
.dynamic h3 {
  font-family: var(--font-family-1);
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3;
  color: var(--primary-color);
  margin-top: 65px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1400px) {
  .dynamic h3 {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 1200px) {
  .dynamic h3 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h3 {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h3 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h3 {
    font-size: 2rem;
  }
}
.dynamic h4 {
  font-family: var(--font-family-1);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.4;
  color: var(--primary-color);
  margin-top: 65px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1400px) {
  .dynamic h4 {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h4 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h4 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h4 {
    font-size: 1.8rem;
  }
}
.dynamic h2 + h3,
.dynamic h3 + h4,
.dynamic h4 + h5,
.dynamic h5 + h6 {
  margin-top: 0;
}
.dynamic p {
  --dynamic-p-margin: 25px;
  --dynamic-p-margin-under-button: 5px;
  --dynamic-p-margin-button: 35px;
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.6;
  color: var(--primary-color);
  margin-top: var(--dynamic-p-margin);
  margin-bottom: var(--dynamic-p-margin);
}
.dynamic p.intro {
  font-family: var(--font-family-2);
  font-size: 3rem;
  line-height: 1.6;
  color: var(--primary-color);
  margin-top: 65px;
  margin-bottom: 65px;
}
@media screen and (max-width: 1400px) {
  .dynamic p.intro {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 1200px) {
  .dynamic p.intro {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic p.intro {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic p.intro {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 600px) {
  .dynamic p.intro {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 450px) {
  .dynamic p.intro {
    font-size: 2rem;
  }
}
.dynamic p.buttonMargin {
  margin-bottom: calc(var(--dynamic-p-margin) - var(--dynamic-p-margin-under-button));
}
.dynamic p.buttonMargin .button {
  margin-bottom: var(--dynamic-p-margin-under-button);
}
.dynamic p.buttonMargin--first {
  margin-top: var(--dynamic-p-margin-button);
}
.dynamic p.buttonMargin--last {
  margin-bottom: calc(var(--dynamic-p-margin-button) - var(--dynamic-p-margin-under-button));
}
.dynamic strong, .dynamic b {
  font-weight: 700;
}
.dynamic em, .dynamic i {
  font-style: italic;
}
.dynamic sup {
  font-size: 0.7em;
  vertical-align: baseline;
  position: relative;
  top: -0.3em;
}
.dynamic sub {
  font-size: 0.7em;
  vertical-align: baseline;
  position: relative;
  top: 0.3em;
}
.dynamic a:not(.button):not(.a-style-document) {
  color: var(--dynamic-main-color);
  text-decoration: none;
  box-shadow: inset 0 -0.4em 0 0 rgba(var(--dynamic-main-color-rgb), 0.2);
  transition: box-shadow 0.3s;
  display: inline;
}
.dynamic a:not(.button):not(.a-style-document):hover {
  box-shadow: inset 0 -1.1em 0 0 rgba(var(--dynamic-main-color-rgb), 0.2);
}
.dynamic a:not(.button):not(.a-style-document) svg {
  margin-left: 8px;
  width: 11px;
  height: 11px;
  stroke: var(--dynamic-main-color);
  fill: var(--dynamic-main-color);
}
.dynamic a:not(.button):not(.a-style-document)[href$=".pdf"] svg, .dynamic a:not(.button):not(.a-style-document)[href$=".doc"] svg, .dynamic a:not(.button):not(.a-style-document)[href$=".zip"] svg {
  width: 12px;
  height: 12px;
}
.dynamic .a-style-document {
  font-family: var(--font-family-1);
  font-size: var(--document__in-rubric-font-size);
  font-weight: 600;
  line-height: 1.6;
  color: var(--dynamic-main-color);
  transition: color 300ms;
  text-decoration: none;
  display: block;
  position: relative;
  padding: 25px 0;
  min-height: var(--document__in-rubric-height);
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .dynamic .a-style-document {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .a-style-document {
    padding: 20px 0;
  }
}
.dynamic .a-style-document::before {
  content: "";
  width: 18px;
  height: 18px;
  background: url("../medias/images/icons/download-case-icon-1.svg") no-repeat;
  opacity: 1;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .dynamic .a-style-document::before {
    right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .a-style-document::before {
    right: 15px;
  }
}
.dynamic .a-style-document::after {
  content: "";
  width: 18px;
  height: 18px;
  background: url("../medias/images/icons/download-case-icon-2.svg") no-repeat;
  opacity: 0;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .dynamic .a-style-document::after {
    right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .a-style-document::after {
    right: 15px;
  }
}
.dynamic .a-style-document:hover {
  color: var(--document__color-hover);
}
.dynamic .a-style-document:hover::before {
  opacity: 0;
}
.dynamic .a-style-document:hover::after {
  opacity: 1;
}
.dynamic .a-style-document svg {
  display: none;
}
.dynamic .a-style-document + .a-style-document {
  border-top: 0;
}
.dynamic .a-style-document + .a-style-document {
  border-top: none;
}
.dynamic p > .a-style-document {
  font-size: 17px;
}
.dynamic ol,
.dynamic ul {
  margin-top: 30px;
  margin-bottom: 30px;
}
.dynamic ol li,
.dynamic ul li {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.6;
  color: var(--primary-color);
}
.dynamic ol li:not(:first-child),
.dynamic ul li:not(:first-child) {
  margin-top: 25px;
}
.dynamic ol li ol, .dynamic ol li ul,
.dynamic ul li ol,
.dynamic ul li ul {
  margin-top: 20px;
  margin-bottom: 0;
  width: 100%;
}
.dynamic ol li ol li:not(:first-child), .dynamic ol li ul li:not(:first-child),
.dynamic ul li ol li:not(:first-child),
.dynamic ul li ul li:not(:first-child) {
  margin-top: 20px;
}
.dynamic ol {
  list-style: none;
  counter-reset: li;
}
.dynamic ol > li {
  position: relative;
  padding-left: var(--dynamic-ol-indent);
}
.dynamic ol > li::before {
  content: counter(li) ".";
  counter-increment: li;
  font-size: 1.7rem;
  color: var(--secondary-color);
  position: absolute;
  display: inline-block;
  left: 0;
  top: calc(var(--dynamic-p-font-line-height) / 2);
  transform: translate(0, -50%);
}
@media screen and (max-width: 600px) {
  .dynamic ol > li::before {
    top: 0.75em;
  }
}
.dynamic ol > li ol > li::before {
  content: counter(li, lower-alpha) ".";
}
.dynamic ol > li ol > li ol > li {
  padding-left: var(--dynamic-ol-indent-last);
}
.dynamic ol > li ol > li ol > li::before {
  content: counter(li, lower-roman) ".";
}
.dynamic ul > li {
  padding-left: var(--dynamic-ul-indent);
  position: relative;
}
.dynamic ul > li::before {
  content: "";
  width: 6px;
  height: 6px;
  border: 1px solid var(--dynamic-ul-bullet-color);
  background: var(--dynamic-ul-bullet-color);
  position: absolute;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 50%;
  box-sizing: border-box;
  top: calc(var(--dynamic-p-font-line-height) / 2);
}
.dynamic ul > li > ul li::before {
  background: transparent;
}
.dynamic ul > li > ul li > ul li::before {
  background: var(--dynamic-ul-bullet-color);
}
.dynamic blockquote {
  position: relative;
  margin-top: 35px;
  margin-bottom: 35px;
  padding-left: 60px;
}
@media screen and (max-width: 600px) {
  .dynamic blockquote {
    padding-left: 55px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic blockquote {
    padding-left: 50px;
  }
}
@media screen and (max-width: 400px) {
  .dynamic blockquote {
    padding-left: 45px;
  }
}
.dynamic blockquote::before {
  content: "“";
  font-family: var(--font-family-2);
  font-size: 8.7rem;
  font-weight: 700;
  color: var(--dynamic-quotes-color);
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 600px) {
  .dynamic blockquote::before {
    font-size: 8rem;
  }
}
@media screen and (max-width: 500px) {
  .dynamic blockquote::before {
    font-size: 7.5rem;
  }
}
@media screen and (max-width: 400px) {
  .dynamic blockquote::before {
    font-size: 7rem;
  }
}
.dynamic blockquote p {
  font-family: var(--font-family-2);
  font-size: 2.5rem;
  line-height: 1.6em;
  padding-top: 5px;
}
@media screen and (max-width: 1024px) {
  .dynamic blockquote p {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic blockquote p {
    font-size: 1.9rem;
  }
}
.dynamic .emphasis {
  border: 1px solid var(--dynamic-table-border-color);
  margin-top: 30px;
  margin-bottom: 65px;
  padding: 45px 40px;
}
.dynamic .emphasis > :first-child {
  margin-top: 0;
}
.dynamic .emphasis > :last-child {
  margin-bottom: 0;
}
.dynamic .table-wrapper {
  overflow-x: auto;
  margin-top: 30px;
  margin-bottom: 65px;
}
.dynamic .table-wrapper table {
  overflow-x: hidden;
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}
.dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
  font-family: var(--font-family-1);
  padding: 20px 35px;
  line-height: 1.5625em;
  border: 1px solid var(--dynamic-table-border-color);
  font-size: 1.7rem;
  vertical-align: middle;
  color: var(--dynamic-main-color);
}
@media screen and (max-width: 768px) {
  .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 375px) {
  .dynamic .table-wrapper table tr th, .dynamic .table-wrapper table tr td {
    font-size: 1.5rem;
  }
}
.dynamic .table-wrapper table tr th > :first-child, .dynamic .table-wrapper table tr td > :first-child {
  margin-top: 0;
}
.dynamic .table-wrapper table tr th > p:last-child, .dynamic .table-wrapper table tr th > pre:last-child, .dynamic .table-wrapper table tr th > strong:last-child, .dynamic .table-wrapper table tr th > em:last-child, .dynamic .table-wrapper table tr th > a:last-child, .dynamic .table-wrapper table tr th > span:last-child, .dynamic .table-wrapper table tr th > sub:last-child, .dynamic .table-wrapper table tr th > sup:last-child, .dynamic .table-wrapper table tr th > ul:last-child, .dynamic .table-wrapper table tr th > ol:last-child, .dynamic .table-wrapper table tr td > p:last-child, .dynamic .table-wrapper table tr td > pre:last-child, .dynamic .table-wrapper table tr td > strong:last-child, .dynamic .table-wrapper table tr td > em:last-child, .dynamic .table-wrapper table tr td > a:last-child, .dynamic .table-wrapper table tr td > span:last-child, .dynamic .table-wrapper table tr td > sub:last-child, .dynamic .table-wrapper table tr td > sup:last-child, .dynamic .table-wrapper table tr td > ul:last-child, .dynamic .table-wrapper table tr td > ol:last-child {
  margin-bottom: 0 !important;
}
.dynamic .table-wrapper table tr th {
  background-color: var(--dynamic-table-header-background);
  font-weight: normal;
  color: var(--dynamic-table-header-color);
  border-top-color: var(--dynamic-table-header-background);
  border-bottom-color: var(--dynamic-table-header-background);
  text-align: left;
}
.dynamic .table-wrapper table tr th:first-child {
  border-left-color: var(--dynamic-table-header-background);
}
.dynamic .table-wrapper table tr th:last-child {
  border-right-color: var(--dynamic-table-header-background);
}
.dynamic .table-wrapper table tr td.secondary-header {
  background-color: var(--dynamic-table-secondary-header-background);
}
.dynamic pre {
  font-family: var(--font-family-1);
  white-space: normal;
  font-size: 1.4rem;
  line-height: 1.7;
  color: var(--dynamic-note-color);
  margin-top: 25px;
  margin-bottom: 25px;
}
@media screen and (max-width: 600px) {
  .dynamic pre {
    font-size: 1.2rem;
  }
}
.dynamic .table-wrapper + pre,
.dynamic .emphasis + pre {
  margin-top: -45px;
}
.dynamic .table-wrapper + .table-wrapper, .dynamic .table-wrapper + .emphasis,
.dynamic .emphasis + .table-wrapper,
.dynamic .emphasis + .emphasis {
  margin-top: -35px;
}
.dynamic picture {
  width: 780px;
  height: 450px;
  margin-top: 110px;
}
.dynamic img {
  position: relative;
  max-width: 100%;
}
.dynamic img.fr-dib {
  /* block */
  display: block;
  margin: 30px auto;
  float: none;
  vertical-align: top;
}
.dynamic img.fr-dib.fr-fil {
  margin-left: 0;
}
.dynamic img.fr-dib.fr-fir {
  margin-right: 0;
}
.dynamic img.fr-dii {
  /* Inline */
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin: 10px 20px;
  max-width: calc(100% - 40px);
}
.dynamic img.fr-dii.fr-fil {
  float: left;
  margin: 10px 20px 10px 0;
  max-width: calc(100% - 20px);
}
.dynamic img.fr-dii.fr-fir {
  float: right;
  margin: 10px 0 10px 20px;
  max-width: calc(100% - 20px);
}
.dynamic .fr-img-caption img {
  width: 100%;
}
.dynamic .fr-img-caption.fr-dib {
  /* block */
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none;
  vertical-align: middle;
  text-align: center;
}
.dynamic .fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
.dynamic .fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
.dynamic .fr-img-caption.fr-dib .fr-img-wrap .fr-inner {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.7;
  color: var(--dynamic-note-color);
  margin-top: 25px;
  margin-bottom: 25px;
}
.dynamic .fr-img-caption.fr-dii {
  /* Inline */
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin: 10px 20px;
  max-width: calc(100% - 40px);
  text-align: center;
}
.dynamic .fr-img-caption.fr-dii.fr-fil {
  float: left;
  margin-left: 0;
  text-align: left;
  max-width: calc(100% - 20px);
}
.dynamic .fr-img-caption.fr-dii.fr-fir {
  float: right;
  margin-right: 0;
  text-align: right;
  max-width: calc(100% - 20px);
}
.dynamic video {
  max-width: 560px;
  max-height: 315px;
  width: 100%;
}
.dynamic .videoWrapper {
  max-width: 700px;
}
.dynamic .videoWrapper .ytVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.dynamic .videoWrapper .ytVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

:root {
  --document__font-size: 3rem;
  --document__subtitle-font-size: 1.4rem;
  --document__in-rubric-font-size: 1.7em;
  --document__height: 145px;
  --document__in-rubric-height: 64px;
  --document__thumb-width: 140px;
  --document__thumb-height: 140px;
  --document__thumb-width-rubric: 60px;
  --document__thumb-height-rubric: 60px;
  --document__color: var(--primary-color);
  --document__color-hover:var(--secondary-color);
}
@media screen and (max-width: 1024px) {
  :root {
    --document__font-size: 2.2rem;
    --document__height: 120px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --document__font-size: 1.8rem;
    --document__height: 100px;
  }
}

.documents .document {
  position: relative;
  border-bottom: 1px solid var(--primary-color);
}
.documents .document:first-child {
  border-top: 1px solid var(--primary-color);
}
.documents .document__link {
  position: relative;
  cursor: pointer;
  padding: 20px 40px;
  min-height: var(--document__height);
  display: flex;
  align-items: center;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .documents .document__link {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 500px) {
  .documents .document__link {
    padding: 20px 0;
  }
}
.documents .document__picture {
  width: var(--document__thumb-width);
  height: var(--document__thumb-height);
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  margin-right: 30px;
  overflow: hidden;
  flex-shrink: 0;
}
@media screen and (max-width: 600px) {
  .documents .document__picture {
    display: none;
  }
}
.documents .document__content {
  padding-right: 75px;
}
.documents .document__subtitle {
  font-family: var(--font-family-1);
  font-size: var(--document__subtitle-font-size);
  color: var(--document__color);
  font-weight: 600;
  line-height: 1.7em;
  transition: color 0.3s;
}
.documents .document__title {
  font-family: var(--font-family-2);
  line-height: 1.6;
  color: var(--document__color);
  font-size: var(--document__font-size);
  font-weight: 700;
  transition: color 0.3s;
}
.documents .document__icon {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .documents .document__icon {
    right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .documents .document__icon {
    right: 15px;
  }
}
.documents .document__svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  fill: var(--document__color);
  transform: translate(-50%, -50%);
  transition: transform 300ms var(--ease__out-cubic), stroke 300ms;
  transition: fill 0.3s;
}
.documents .document__link:hover .document__subtitle {
  color: var(--document__color-hover);
}
.documents .document__link:hover .document__title {
  color: var(--document__color-hover);
}
.documents .document__link:hover .document__svg {
  fill: var(--document__color-hover);
}
.documents--in-rubric .document {
  border-color: var(--primary-color);
}
.documents--in-rubric .document:first-child {
  border-color: var(--primary-color);
  margin-top: 40px;
}
.documents--in-rubric .document .document__picture {
  width: var(--document__thumb-width-rubric);
  height: var(--document__thumb-height-rubric);
}
.documents--in-rubric .document .document__link {
  padding: 25px 0;
  min-height: var(--document__in-rubric-height);
}
.documents--in-rubric .document .document__subtitle {
  font-family: var(--font-family-1);
}
.documents--in-rubric .document .document__title {
  font-family: var(--font-family-1);
  font-size: var(--document__in-rubric-font-size);
  font-weight: 600;
}
.documents--in-rubric .document .document__svg {
  width: 18px;
  height: 18px;
}

:root {
  --gallery_overlay-color: var(--primary-color);
  --gallery_overlay-last-opacity: 0.8;
  --gallery_overlay-opacity-hover: 0.5;
  --gallery_height: 400px;
  --gallery_width: 920px;
  --gallery_margin: 20px;
}
@media screen and (max-width: 768px) {
  :root {
    --gallery_height: 335px;
    --gallery_margin: 15px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --gallery_height: 200px;
    --gallery_margin: 10px;
  }
}

.gallery {
  display: flex;
  flex-flow: row nowrap;
  max-width: var(--gallery_width);
  height: var(--gallery_height);
}
.gallery__left {
  flex: 1;
  margin-right: var(--gallery_margin);
}
.gallery__right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: calc((var(--gallery_height) - var(--gallery_margin)) / 2);
}
.gallery__block {
  position: relative;
  height: 100%;
}
.gallery__block:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--gallery_overlay-color);
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;
}
.gallery__link {
  display: block;
  height: 100%;
  outline: none;
}
.gallery__block {
  display: none;
}
.gallery__block-1, .gallery__block-2, .gallery__block-3 {
  display: block;
}
.gallery__block-1 .gallery__link, .gallery__block-2 .gallery__link, .gallery__block-3 .gallery__link {
  display: block;
  height: 100%;
}
.gallery__block-2, .gallery__block-3 {
  height: calc((var(--gallery_height) - var(--gallery_margin)) / 2);
}
.gallery__block-3:before {
  opacity: var(--gallery_overlay-last-opacity);
}
.gallery__block:hover:before {
  opacity: var(--gallery_overlay-opacity-hover);
}
.gallery__picture {
  width: 100%;
  height: 100%;
}
.gallery__plus {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.gallery__plus::before, .gallery__plus::after {
  content: "";
  position: absolute;
  background-color: white;
  height: 2px;
  width: 100%;
  transform: translate(-50%, -50%) rotate(0deg);
  top: 50%;
  left: 50%;
}
.gallery__plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

:root {
  --rubric__font-size: 3rem;
  --rubric__subtitle-font-size: 1.4rem;
  --rubric__height: 125px;
  --rubric__thumb-width: 140px;
  --rubric__thumb-height: 140px;
  --rubric__color: var(--primary-color);
  --rubric__color-hover:var(--secondary-color);
  --rubric__plus-size: 20px;
  --rubric__plus-color: var(--primary-color);
  --rubric__plus-color-hover: var(--secondary-color);
}
@media screen and (max-width: 700px) {
  :root {
    --rubric__font-size: 2.8rem;
    --rubric__height: 120px;
    --rubric__thumb-width: 120px;
    --rubric__thumb-height: 120px;
  }
}
@media screen and (max-width: 440px) {
  :root {
    --rubric__font-size: 2.5rem;
  }
}

.rubric {
  position: relative;
  border-bottom: 1px solid var(--primary-color);
  background: transparent;
  transition: border-bottom 700ms;
}
.rubric:first-child {
  border-top: 1px solid var(--primary-color);
}
.rubric__header {
  position: relative;
  cursor: pointer;
  padding: 20px 115px 20px 40px;
  min-height: var(--rubric__height);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .rubric__header {
    padding: 20px 75px 20px 30px;
  }
}
@media screen and (max-width: 500px) {
  .rubric__header {
    padding: 20px 50px 20px 0px;
  }
}
.rubric__picture {
  width: var(--rubric__thumb-width);
  height: var(--rubric__thumb-height);
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  margin-right: 30px;
  overflow: hidden;
  flex-shrink: 0;
}
@media screen and (max-width: 600px) {
  .rubric__picture {
    display: none;
  }
}
.rubric__subtitle {
  font-family: var(--font-family-1);
  font-size: var(--rubric__subtitle-font-size);
  color: var(--rubric__color);
  font-weight: 600;
  line-height: 1.7em;
  transition: color 0.3s;
}
.rubric__title {
  font-family: var(--font-family-2);
  font-size: var(--rubric__font-size);
  line-height: 1.6;
  color: var(--rubric__color);
  font-weight: 700;
  transition: color 0.3s;
}
.rubric__plus {
  position: absolute;
  width: var(--rubric__plus-size);
  height: var(--rubric__plus-size);
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.rubric__plus::before, .rubric__plus::after {
  content: "";
  position: absolute;
  width: var(--rubric__plus-size);
  height: 2px;
  background-color: var(--rubric__plus-color);
  left: 50%;
  top: 50%;
  transition: background-color 0.3s, opacity 0.3s;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.rubric__plus::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
@media screen and (max-width: 600px) {
  .rubric__plus {
    right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .rubric__plus {
    right: 0;
  }
}
.rubric__header:hover .rubric__subtitle {
  color: var(--rubric__color-hover);
}
.rubric__header:hover .rubric__title {
  color: var(--rubric__color-hover);
}
.rubric__header:hover .rubric__plus::before, .rubric__header:hover .rubric__plus::after {
  background-color: var(--rubric__plus-color-hover);
}
.rubric__content {
  height: 0;
  will-change: height;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .rubric__content {
    padding: 0;
  }
}
.rubric__transition {
  padding: 0 40px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 700ms var(--ease-in-out), transform 700ms var(--ease-in-out);
  will-change: transform;
}
@media screen and (max-width: 600px) {
  .rubric__transition {
    padding: 0 30px;
  }
}
@media screen and (max-width: 500px) {
  .rubric__transition {
    padding: 0;
  }
}
.rubric__transition > :first-child {
  margin-top: 0;
}
.rubric__icon-box {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
}
.rubric__icon-box-text {
  font-family: var(--font-family-1);
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 15px;
}
.rubric__icon-box-categories {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
  .rubric__icon-box-categories {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.rubric__icon-box-category {
  margin-top: 10px;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.rubric__icon-box-icon-container {
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
}
.rubric__icon-box-icon {
  width: 21px;
  height: 21px;
  fill: var(--primary-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rubric__icon-box-span {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  color: var(--primary-color);
}
.rubric__dynamic h3 {
  margin-top: 50px;
  margin-bottom: 25px;
  line-height: 1.45em;
  font-weight: 600;
  font-size: 2.2rem;
}
@media screen and (max-width: 375px) {
  .rubric__dynamic h3 {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 320px) {
  .rubric__dynamic h3 {
    font-size: 2rem;
  }
}
.rubric__dynamic h4 {
  margin-top: 50px;
  margin-bottom: 25px;
  line-height: 1.5em;
  font-size: 1.8rem;
  font-weight: 700;
}
.rubric__document, .rubric__gallery {
  margin-bottom: var(--y-spacing-60);
}
.rubric__document:last-child {
  padding-bottom: var(--y-spacing-30);
  margin-bottom: 0;
}
.rubric__document--section {
  margin-top: var(--y-spacing-60);
}
.rubric__document--section .rubric__document .document {
  border: none;
  margin-bottom: 10px;
}
.rubric__document--section .rubric__document .document__link {
  background: var(--secondary-light-color);
  transition: background 300ms;
}
.rubric__document--section .rubric__document .document__link:hover {
  background: var(--primary-color);
}
.rubric__document--section .rubric__document .document__link:hover .document__title {
  color: var(--primary-light-color);
}
.rubric__document--section .rubric__document .document__link:hover .document__svg {
  fill: var(--primary-light-color);
}
@media screen and (max-width: 500px) {
  .rubric__document--section .rubric__document .document__link .document__icon {
    right: 25px;
  }
}
.rubric__document--section .rubric__document .document__content {
  margin-left: 35px;
}
@media screen and (max-width: 600px) {
  .rubric__document--section .rubric__document .document__content {
    margin-left: 20px;
  }
}
@media screen and (max-width: 500px) {
  .rubric__document--section .rubric__document .document__content {
    margin-left: 15px;
  }
}
.rubric__document--section-header {
  display: flex;
  align-items: center;
}
.rubric__document--section-header .icon {
  width: 70px;
  height: 70px;
  background: var(--primary-light-color);
  border: 1px solid var(--other-3);
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rubric__document--section-header .icon .document__svg {
  width: 60%;
  height: 60%;
  fill: var(--primary-color);
}
@media screen and (max-width: 500px) {
  .rubric__document--section-header .icon .document__svg {
    width: 70%;
    height: 70%;
  }
}
@media screen and (max-width: 500px) {
  .rubric__document--section-header .icon {
    width: 55px;
    height: 55px;
  }
}
.rubric__document--section-header .rubric__title {
  color: var(--primary-color) !important;
  margin: 0 30px;
  flex-shrink: 0;
}
@media screen and (max-width: 600px) {
  .rubric__document--section-header .rubric__title {
    margin: 0 25px;
  }
}
@media screen and (max-width: 500px) {
  .rubric__document--section-header .rubric__title {
    margin: 0 20px;
  }
}
.rubric__document--section-header .line {
  width: 100%;
  height: 1px;
  background: #0c4654;
}
.rubric__dynamic:last-child {
  padding-bottom: var(--y-spacing-90);
  margin-bottom: 0;
}
.rubric__galery:last-child {
  padding-bottom: var(--y-spacing-90);
  margin-bottom: 0;
}
.rubric__share {
  margin-top: var(--y-spacing-60);
  margin-bottom: 0;
  padding-bottom: var(--y-spacing-60);
}

.fancybox-caption__body {
  font-family: var(--font-family-1);
}

/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened .rubric__subtitle, .rubric.js-accordion-opened .rubric__title {
  color: var(--secondary-color);
}
.rubric.js-accordion-opened .rubric__plus::before, .rubric.js-accordion-opened .rubric__plus::after {
  background-color: var(--rubric__plus-color-hover);
}
.rubric.js-accordion-opened .rubric__transition {
  opacity: 1;
  transform: none;
  transition: opacity 700ms var(--ease-in-out) 100ms, transform 700ms var(--ease-in-out) 100ms;
}
.rubric.js-accordion-opened .rubric__plus::after {
  opacity: 0;
}

:root {
  --share__color: var(--primary-color);
  --share__color-hover: var(--secondary-color);
}

.share {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: var(--share__color);
  transition: color 0.3s;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
}
.share__icon {
  width: 12px;
  height: 12px;
  fill: var(--share__color);
  transition: fill 0.3s;
  margin-right: 15px;
  position: relative;
  top: -1px;
}
.share:hover {
  color: var(--share__color-hover);
}
.share:hover .share__icon {
  fill: var(--share__color-hover);
}

.share-variant {
  position: absolute;
  top: 20px;
  right: var(--x-spacing-100);
  z-index: 10;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
}
@media screen and (max-width: 1600px) {
  .share-variant {
    right: var(--x-spacing-90);
  }
}
@media screen and (max-width: 600px) {
  .share-variant {
    display: none;
  }
}
.share-variant[data-fire] {
  opacity: 1;
  transform: none;
}
.share-variant__share-button {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--primary-color);
  transition: color 300ms;
  display: flex;
}
.share-variant__share-button:hover {
  color: var(--secondary-color);
}
.header--light .share-variant__share-button {
  color: var(--primary-light-color);
}
.header--light .share-variant__share-button:hover {
  color: var(--secondary-color);
}
.share-variant__share-button-svg {
  width: 18px;
  height: 18px;
  fill: var(--primary-color);
  transition: fill 300ms;
  position: relative;
  top: -2px;
}
.header--light .share-variant__share-button-svg {
  fill: var(--primary-light-color);
}
.share-variant__share-button:hover .share-variant__share-button-svg {
  fill: var(--secondary-color);
}
.share-variant__share-button-span {
  line-height: 18px;
  margin-left: 20px;
}

.banner__container {
  position: relative;
  height: calc(100vh - var(--header--height));
  min-height: 550px;
}
@media screen and (max-width: 1024px) {
  .banner__container {
    height: 500px;
    min-height: 500px;
  }
}
@media screen and (max-width: 800px) {
  .banner__container {
    padding: 50px var(--x-spacing-110);
    height: 100%;
    min-height: 600px;
  }
}
.banner__layer--shape {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0;
}
.banner__img--shape {
  width: 950px;
  fill: var(--secondary-light-color);
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
}
@media screen and (max-height: 800px), screen and (max-width: 1400px) {
  .banner__img--shape {
    width: 850px;
  }
}
@media screen and (max-height: 750px), screen and (max-width: 1300px) {
  .banner__img--shape {
    width: 750px;
  }
}
@media screen and (max-height: 650px), screen and (max-width: 1200px) {
  .banner__img--shape {
    width: 650px;
  }
}
@media screen and (max-width: 1024px) {
  .banner__img--shape {
    width: 550px;
  }
}
@media screen and (max-width: 950px) {
  .banner__img--shape {
    width: 500px;
  }
}
@media screen and (max-width: 800px) {
  .banner__img--shape {
    width: 900px;
  }
}
@media screen and (max-width: 650px) {
  .banner__img--shape {
    width: 800px;
  }
}
@media screen and (max-width: 550px) {
  .banner__img--shape {
    width: 650px;
  }
}
@media screen and (max-width: 400px) {
  .banner__img--shape {
    width: 500px;
  }
}
[data-fire] .banner__img--shape {
  opacity: 1;
  transform: none;
}
.banner__layer--content {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-bottom: 60px;
  padding-left: var(--x-spacing-110);
  justify-content: center;
  z-index: 2;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 200ms, transform 800ms var(--ease-out) 200ms;
}
@media screen and (max-width: 800px) {
  .banner__layer--content {
    padding: 0 0 50px;
  }
}
@media screen and (max-width: 600px) {
  .banner__layer--content {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 450px) {
  .banner__layer--content {
    padding-bottom: 30px;
  }
}
.banner__layer--content .huge-title {
  font-size: 10.5rem;
}
@media screen and (max-width: 1800px) {
  .banner__layer--content .huge-title {
    font-size: 9.5rem;
  }
}
@media screen and (max-width: 1700px) {
  .banner__layer--content .huge-title {
    font-size: 8.8rem;
  }
}
@media screen and (max-width: 1500px) {
  .banner__layer--content .huge-title {
    font-size: 8.1rem;
  }
}
@media screen and (max-width: 700px) {
  .banner__layer--content .huge-title {
    font-size: 6.2rem;
  }
}
@media screen and (max-width: 500px) {
  .banner__layer--content .huge-title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 400px) {
  .banner__layer--content .huge-title {
    font-size: 4.4rem;
  }
}
[data-fire] .banner__layer--content {
  opacity: 1;
  transform: none;
}
.banner__title {
  max-width: 775px;
}
@media screen and (max-width: 1800px) {
  .banner__title {
    max-width: 700px;
  }
}
@media screen and (max-width: 1700px) {
  .banner__title {
    max-width: 650px;
  }
}
@media screen and (max-width: 1500px) {
  .banner__title {
    max-width: 600px;
  }
}
.banner__title--bold {
  font-weight: 700;
}
.banner__button--play {
  margin-top: 45px;
  display: inline-flex;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .banner__button--play {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .banner__button--play {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .banner__button--play {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .banner__button--play {
    margin-top: 25px;
  }
}
@media screen and (max-width: 500px) {
  .banner__button--play {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .banner__button--play .play-button {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 600px) {
  .banner__button--play .play-button {
    width: 34px;
    height: 34px;
  }
}
@media screen and (max-width: 768px) {
  .banner__button--play .play-button:before {
    border-width: 5px 0 5px 8.7px;
  }
}
.banner__button--play:hover .banner__icon--play {
  background: var(--secondary-color);
}
.banner__text--play {
  margin-left: 15px;
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--primary-color);
  transition: color 300ms;
}
@media screen and (max-width: 1024px) {
  .banner__text--play {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .banner__text--play {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 600px) {
  .banner__text--play {
    font-size: 1.4rem;
  }
}
.banner__button--play:hover .banner__text--play {
  color: var(--secondary-color);
}
.banner__layer--circle {
  position: absolute;
  width: 650px;
  height: 650px;
  top: 50%;
  right: var(--x-spacing-110);
  border-radius: 50%;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-50%) scale(0);
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  transition: opacity 800ms linear 300ms, transform 800ms var(--ease-out) 300ms;
}
@media screen and (max-height: 850px), screen and (max-width: 1500px) {
  .banner__layer--circle {
    width: 620px;
    height: 620px;
  }
}
@media screen and (max-height: 800px), screen and (max-width: 1300px) {
  .banner__layer--circle {
    width: 540px;
    height: 540px;
  }
}
@media screen and (max-height: 750px) {
  .banner__layer--circle {
    width: 480px;
    height: 480px;
  }
}
@media screen and (max-height: 700px), screen and (max-width: 1100px) {
  .banner__layer--circle {
    width: 480px;
    height: 480px;
  }
}
@media screen and (max-width: 1024px) {
  .banner__layer--circle {
    width: 400px;
    height: 400px;
  }
}
@media screen and (max-width: 800px) {
  .banner__layer--circle {
    position: relative;
    width: 70%;
    height: 100%;
    padding-top: 70%;
    top: 0;
    right: 0;
    transform: scale(0);
  }
}
@media screen and (max-width: 650px) {
  .banner__layer--circle {
    width: 80%;
    padding-top: 80%;
  }
}
@media screen and (max-width: 550px) {
  .banner__layer--circle {
    width: 100%;
    padding-top: 100%;
  }
}
.banner__layer--circle:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--primary-light-rgb-color), 0.1);
  z-index: 1;
}
[data-fire] .banner__layer--circle {
  opacity: 1;
  transform: translateY(-50%);
}
@media screen and (max-width: 800px) {
  [data-fire] .banner__layer--circle {
    transform: none;
  }
}
.banner__picture--circle, .banner__img--circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.banner__video--circle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
.banner__layer--bottom {
  position: absolute;
  bottom: 50px;
  left: var(--x-spacing-110);
  z-index: 2;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 400ms, transform 800ms var(--ease-out) 400ms;
}
@media screen and (max-width: 800px) {
  .banner__layer--bottom {
    display: none;
  }
}
[data-fire] .banner__layer--bottom {
  opacity: 1;
  transform: none;
}
.banner__button--scrolldown {
  display: inline-flex;
  align-items: center;
  margin-right: 60px;
}
@media screen and (max-width: 1200px) {
  .banner__button--scrolldown {
    margin-right: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .banner__button--scrolldown {
    margin-right: 40px;
  }
}
@media screen and (max-width: 768px) {
  .banner__button--scrolldown {
    margin-right: 30px;
  }
}
.banner__text--scrolldown {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.5;
  color: var(--primary-color);
  transition: box-shadow 300ms, color 300ms;
}
.banner__button--scrolldown:hover .banner__text--scrolldown {
  color: var(--secondary-color);
  box-shadow: inset 0 -1px 0 0 var(--secondary-color);
}
.banner__icon--scrolldown {
  margin-left: 15px;
  width: 20px;
  height: 20px;
  fill: none;
  stroke: var(--primary-color);
  transform: rotate(90deg);
  transition: stroke 300ms;
}
.banner__button--scrolldown:hover .banner__icon--scrolldown {
  stroke: var(--secondary-color);
}

.image-with-text__container {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .image-with-text__container {
    flex-wrap: wrap;
  }
}
.image-with-text__image-item {
  position: relative;
  width: 40%;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .image-with-text__image-item {
    width: 45%;
  }
}
@media screen and (max-width: 768px) {
  .image-with-text__image-item {
    width: 100%;
  }
}
.image-with-text__picture {
  position: relative;
  padding-top: 121%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .image-with-text__picture {
    height: 600px;
    padding-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .image-with-text__picture {
    height: 500px;
  }
}
@media screen and (max-width: 500px) {
  .image-with-text__picture {
    height: auto;
    padding-top: 121%;
  }
}
.image-with-text__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.image-with-text__content-item {
  display: flex;
  align-items: center;
  width: 60%;
  padding: 0 var(--x-spacing-110);
}
@media screen and (max-width: 1200px) {
  .image-with-text__content-item {
    width: 55%;
  }
}
@media screen and (max-width: 768px) {
  .image-with-text__content-item {
    width: 100%;
  }
}
.image-with-text__content {
  position: relative;
  max-width: 450px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .image-with-text__content {
    margin-top: 60px;
    max-width: 100%;
  }
}
.image-with-text__title {
  position: relative;
}
.image-with-text__button {
  margin-top: 80px;
}
@media screen and (max-width: 1400px) {
  .image-with-text__button {
    margin-top: 70px;
  }
}
@media screen and (max-width: 1300px) {
  .image-with-text__button {
    margin-top: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .image-with-text__button {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1100px) {
  .image-with-text__button {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .image-with-text__button {
    margin-top: 30px;
  }
}
.image-with-text__shape {
  position: absolute;
  width: 215px;
  top: -100px;
  left: -100px;
  fill: var(--secondary-light-color);
}
@media screen and (max-width: 1400px) {
  .image-with-text__shape {
    width: 200px;
    top: -75px;
    left: -75px;
  }
}
@media screen and (max-width: 1200px) {
  .image-with-text__shape {
    width: 160px;
    top: -60px;
    left: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .image-with-text__shape {
    width: 120px;
    top: -50px;
    left: -20px;
  }
}
@media screen and (max-width: 768px) {
  .image-with-text__shape {
    width: 90px;
    top: -30px;
    left: -10px;
  }
}

.title-next-to-text__container {
  display: flex;
  align-items: center;
  padding: 0 var(--x-spacing-110);
}
@media screen and (max-width: 850px) {
  .title-next-to-text__container {
    flex-wrap: wrap;
  }
}
.title-next-to-text__title-item {
  position: relative;
  width: 50%;
}
@media screen and (max-width: 850px) {
  .title-next-to-text__title-item {
    width: 100%;
  }
}
.title-next-to-text__shape {
  position: absolute;
  width: 260px;
  height: 260px;
  top: 30px;
  left: -70px;
  fill: var(--secondary-light-color);
}
@media screen and (max-width: 1200px) {
  .title-next-to-text__shape {
    width: 220px;
    height: 220px;
    left: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .title-next-to-text__shape {
    width: 180px;
    height: 180px;
    left: -30px;
  }
}
@media screen and (max-width: 850px) {
  .title-next-to-text__shape {
    width: 150px;
    height: 150px;
    left: -20px;
    top: -10px;
  }
}
@media screen and (max-width: 500px) {
  .title-next-to-text__shape {
    width: 110px;
    height: 110px;
    left: -10px;
    top: 0px;
  }
}
.title-next-to-text__title {
  position: relative;
}
.title-next-to-text__title--bold {
  font-weight: 700;
}
.title-next-to-text__text-item {
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 50%;
}
@media screen and (max-width: 850px) {
  .title-next-to-text__text-item {
    width: 100%;
    justify-content: flex-start;
    margin-top: 50px;
  }
}
@media screen and (max-width: 850px) {
  .title-next-to-text__text-item {
    margin-top: 40px;
  }
}
.title-next-to-text__content {
  max-width: 300px;
}
@media screen and (max-width: 850px) {
  .title-next-to-text__content {
    max-width: 500px;
  }
}
.title-next-to-text__text {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.5;
  color: var(--primary-color);
}
.title-next-to-text__link {
  margin-top: 50px;
}
@media screen and (max-width: 1200px) {
  .title-next-to-text__link {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .title-next-to-text__link {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .title-next-to-text__link {
    margin-top: 20px;
  }
}

.page-title__container {
  display: flex;
  flex-direction: column;
}
.page-title__container--with-inside-image .page-title__content {
  justify-content: space-between;
  align-items: center;
}
.page-title__container--with-inside-image .page-title__picture-inside {
  margin-top: 90px;
}
@media screen and (max-width: 900px) {
  .page-title__container--with-inside-image .page-title__picture-inside {
    margin-top: 0;
  }
}
@media screen and (max-width: 900px) {
  .page-title__container--with-inside-image .page-title__picture-inside {
    display: none;
  }
}
.page-title__container--with-inside-image .page-title__picture {
  display: none;
}
@media screen and (max-width: 900px) {
  .page-title__container--with-inside-image .page-title__picture {
    display: block;
  }
}
@media screen and (max-width: 900px) {
  .page-title__container--with-inside-image .page-title__line {
    width: 700px;
    right: 0px;
    bottom: -250px;
  }
}
@media screen and (max-width: 600px) {
  .page-title__container--with-inside-image .page-title__line {
    width: 400px;
    right: 0px;
    bottom: -140px;
  }
}
.page-title__container--with-inside-image .page-title__picture-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.page-title__container--with-inside-image .page-title__picture {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  padding-top: 148%;
}
.page-title__container--with-inside-image .page-title__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-title__container:not(.page-title__container--with-inside-image) .page-title__content {
  padding-bottom: var(--y-spacing-110);
  padding-top: var(--y-spacing-110);
}
.page-title__content {
  min-height: 440px;
  padding-left: var(--x-spacing-120);
  display: flex;
  overflow: hidden;
  position: relative;
}
.page-title__content--variant {
  min-height: 560px;
}
@media screen and (max-width: 900px) {
  .page-title__content {
    min-height: 0;
    padding-top: var(--y-spacing-140);
    padding-bottom: var(--y-spacing-140);
  }
  .page-title__content--variant {
    min-height: 0;
  }
}
.page-title__text {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  max-width: 725px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}
[data-fire] .page-title__text {
  opacity: 1;
  transform: none;
}
.page-title__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--secondary-color);
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media screen and (max-width: 400px) {
  .page-title__surtitle {
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
}
.page-title__surtitle--date {
  font-weight: 700;
}
.page-title__title {
  font-weight: 700;
  margin-bottom: 60px;
}
.page-title__title:last-child() {
  margin-bottom: 0;
}
@media screen and (max-width: 900px) {
  .page-title__title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 400px) {
  .page-title__title {
    margin-bottom: 15px;
  }
}
.page-title .smaller {
  font-size: 7rem;
}
@media screen and (max-width: 1400px) {
  .page-title .smaller {
    font-size: 6.5rem;
  }
}
@media screen and (max-width: 1300px) {
  .page-title .smaller {
    font-size: 6rem;
  }
}
@media screen and (max-width: 1200px) {
  .page-title .smaller {
    font-size: 5.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .page-title .smaller {
    font-size: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .page-title .smaller {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 600px) {
  .page-title .smaller {
    font-size: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .page-title .smaller {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 400px) {
  .page-title .smaller {
    font-size: 3.2rem;
  }
}
.page-title .much-smaller {
  font-size: 5rem;
}
@media screen and (max-width: 768px) {
  .page-title .much-smaller {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 600px) {
  .page-title .much-smaller {
    font-size: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .page-title .much-smaller {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 400px) {
  .page-title .much-smaller {
    font-size: 3.2rem;
  }
}
.page-title__button {
  display: inline-flex;
  align-items: center;
  margin-right: 60px;
}
@media screen and (max-width: 1200px) {
  .page-title__button {
    margin-right: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .page-title__button {
    margin-right: 40px;
  }
}
@media screen and (max-width: 768px) {
  .page-title__button {
    margin-right: 30px;
  }
}
.page-title__button-text {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  line-height: 1.5;
  color: var(--primary-color);
  transition: box-shadow 300ms, color 300ms;
}
.page-title__button:hover .page-title__button-text {
  color: var(--secondary-color);
  box-shadow: inset 0 -1px 0 0 var(--secondary-color);
}
.page-title__button-icon {
  margin-left: 15px;
  width: 20px;
  height: 20px;
  fill: none;
  stroke: var(--primary-color);
  transform: rotate(90deg);
  transition: stroke 300ms;
}
.page-title__button:hover .page-title__button-icon {
  stroke: var(--secondary-color);
}
.page-title__picture-inside-container {
  padding-right: 90px;
  margin-left: 30px;
  width: 480px;
  min-width: 480px;
}
@media screen and (max-width: 1024px) {
  .page-title__picture-inside-container {
    width: 400px;
    min-width: 400px;
  }
}
@media screen and (max-width: 900px) {
  .page-title__picture-inside-container {
    display: none;
  }
}
.page-title__picture-inside {
  padding-top: 148%;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  position: relative;
  z-index: -2;
}
.page-title__picture-inside .credit {
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 1.5rem;
  padding: 10px 25px;
  background-color: rgba(12, 70, 84, 0.6);
  font-family: var(--font-family-1);
  z-index: 10;
}
.page-title__picture-inside.videoThumb::after {
  content: "";
  background-color: #000;
  opacity: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  transition: opacity 0.3s ease-in;
}
.page-title__picture-inside.videoThumb > div.arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  border: solid 1px #fff;
  z-index: 1;
  opacity: 1;
  border-radius: 50%;
  transition: all 0.3s;
}
.page-title__picture-inside.videoThumb > div.arrow span {
  margin: -8px 0 0 -4px;
  border-width: 8px 0 8px 13.9px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 1;
  transition: border-color 500ms;
}
.page-title__img-inside {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[data-fire] .page-title__picture-inside {
  opacity: 1;
  transform: none;
}
.page-title__shape-container {
  width: 750px;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0;
  overflow: hidden;
  z-index: 1;
}
.page-title__shape {
  width: 520px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  stroke: var(--secondary-light-color);
  fill: var(--secondary-light-color);
  position: absolute;
  right: 0;
  bottom: -50px;
  z-index: -3;
}
@media screen and (max-width: 900px) {
  .page-title__shape {
    width: 420px;
    right: -20px;
    bottom: -1px;
  }
}
@media screen and (max-width: 600px) {
  .page-title__shape {
    width: 320px;
    bottom: -20px;
  }
}
@media screen and (max-width: 500px) {
  .page-title__shape {
    width: 220px;
    bottom: -1px;
  }
}
@media screen and (max-width: 400px) {
  .page-title__shape {
    width: 175px;
    right: 0;
    bottom: -20px;
  }
}
.page-title__shape--variant {
  stroke: var(--other-4);
  fill: var(--other-4);
}
[data-fire] .page-title__shape {
  opacity: 0.5;
  transform: none;
}
.page-title__line {
  width: 1564px;
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 2px;
  position: absolute;
  right: -120px;
  bottom: -500px;
  z-index: -1;
}
@media screen and (max-width: 900px) {
  .page-title__line {
    width: 1000px;
    right: -50px;
    bottom: -300px;
  }
}
@media screen and (max-width: 800px) {
  .page-title__line {
    stroke-width: 4px;
  }
}
@media screen and (max-width: 600px) {
  .page-title__line {
    width: 800px;
    right: -100px;
    bottom: -250px;
  }
}
@media screen and (max-width: 500px) {
  .page-title__line {
    width: 700px;
    right: -125px;
    bottom: -225px;
  }
}
@media screen and (max-width: 400px) {
  .page-title__line {
    width: 600px;
    right: -100px;
    bottom: -240px;
  }
}
.page-title__picture {
  position: relative;
  padding-top: 52%;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
}
@media screen and (max-width: 1400px) {
  .page-title__picture {
    height: auto;
    max-height: 800px;
  }
}
.page-title__picture .credit {
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 1.5rem;
  padding: 10px 25px;
  background-color: rgba(12, 70, 84, 0.6);
  font-family: var(--font-family-1);
  z-index: 10;
}
.page-title__picture.videoThumb::after {
  content: "";
  background-color: #000;
  opacity: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  transition: opacity 0.3s ease-in;
}
.page-title__picture.videoThumb > div.arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  border: solid 1px #fff;
  z-index: 1;
  opacity: 1;
  border-radius: 50%;
  transition: all 0.3s;
}
.page-title__picture.videoThumb > div.arrow span {
  margin: -8px 0 0 -4px;
  border-width: 8px 0 8px 13.9px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 1;
  transition: border-color 500ms;
}
.page-title__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[data-fire] .page-title__picture {
  opacity: 1;
  transform: none;
}
.page-title--variant-1 .page-title__container {
  background: var(--primary-color);
}
.page-title--variant-1 .page-title__surtitle, .page-title--variant-1 .page-title__title, .page-title--variant-1 .page-title__button-text {
  color: #fff;
}
.page-title--variant-1 .page-title__button-icon {
  stroke: #fff;
}

.site-slider {
  --site-slider__arrow-icon--dimension: 22px;
  --site-slider__slick-list--side-padding: 30vw;
  --site-slider__slide--side-padding: 60px;
}
@media screen and (max-width: 1600px) {
  .site-slider {
    --site-slider__slick-list--side-padding: 25vw;
  }
}
@media screen and (max-width: 1400px) {
  .site-slider {
    --site-slider__slick-list--side-padding: 20vw;
  }
}
@media screen and (max-width: 1200px) {
  .site-slider {
    --site-slider__slick-list--side-padding: 15vw;
  }
}
@media screen and (max-width: 1024px) {
  .site-slider {
    --site-slider__slick-list--side-padding: 20vw;
  }
}
@media screen and (max-width: 600px) {
  .site-slider {
    --site-slider__slick-list--side-padding: 10vw;
  }
}
@media screen and (max-width: 1200px) {
  .site-slider {
    --site-slider__slide--side-padding: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .site-slider {
    --site-slider__slide--side-padding: 30px;
  }
}
@media screen and (max-width: 850px) {
  .site-slider {
    --site-slider__slide--side-padding: var(--x-spacing-100);
  }
}

.site-slider {
  position: relative;
}
.site-slider__container {
  position: relative;
  padding-top: 50px;
}
@media screen and (max-width: 850px) {
  .site-slider__container {
    padding-top: 0;
  }
}
.site-slider__line {
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 2.5px;
  opacity: 0;
}
@media screen and (max-width: 1300px) {
  .site-slider__line {
    top: 150px;
  }
}
@media screen and (max-width: 1150px) {
  .site-slider__line {
    top: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .site-slider__line {
    top: 130px;
  }
}
@media screen and (max-width: 850px) {
  .site-slider__line {
    top: 100px;
  }
}
@media screen and (max-width: 800px) {
  .site-slider__line {
    stroke-width: 4px;
  }
}
@media screen and (max-width: 600px) {
  .site-slider__line {
    top: 50px;
  }
}
[data-fire] .site-slider__line {
  opacity: 1;
}
.site-slider__navigation {
  position: absolute;
  bottom: calc(100% - var(--site-slider__arrow-icon--dimension));
  right: calc(100% - var(--site-slider__slick-list--side-padding) + var(--site-slider__slide--side-padding));
}
@media screen and (max-width: 1024px) {
  .site-slider__navigation {
    right: auto;
    left: var(--x-spacing-110);
  }
}
@media screen and (max-width: 850px) {
  .site-slider__navigation {
    display: none;
  }
}
.site-slider__arrow--prev {
  margin-right: 35px;
}
.site-slider__arrow-icon {
  width: var(--site-slider__arrow-icon--dimension);
  height: var(--site-slider__arrow-icon--dimension);
  fill: none;
  stroke: var(--primary-color);
  transition: stroke 300ms;
}
.site-slider__arrow-icon--prev {
  transform: rotate(180deg);
}
.site-slider__arrow:hover .site-slider__arrow-icon {
  stroke: var(--secondary-color);
}
.site-slider__slider .slick-list {
  padding: 0 var(--site-slider__slick-list--side-padding) !important;
}
@media screen and (max-width: 850px) {
  .site-slider__slider .slick-list {
    padding: 0 var(--site-slider__slick-list--side-padding) 0 0 !important;
  }
}
.site-slider__slide {
  padding: 0 var(--site-slider__slide--side-padding);
}
@media screen and (max-width: 500px) {
  .site-slider__slide {
    padding: 0 0 0 var(--site-slider__slide--side-padding);
  }
}
.site-slider__slide-content {
  width: 100%;
}
.site-slider__picture-container {
  position: relative;
}
.site-slider__picture {
  position: relative;
  padding-top: 52%;
  overflow: hidden;
}
.site-slider__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 600ms var(--ease-out);
}
.site-slider__slide-content:hover .site-slider__img {
  transform: scale(1.1);
}
.site-slider__icon-container {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 68px;
  height: 68px;
}
@media screen and (max-width: 1300px) {
  .site-slider__icon-container {
    width: 54px;
    height: 54px;
    right: 25px;
    bottom: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .site-slider__icon-container {
    width: 48px;
    height: 48px;
    right: 20px;
    bottom: 20px;
  }
}
.site-slider__icon-container:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--primary-light-color);
  border-radius: 50%;
  transition: transform 600ms var(--ease-out);
}
.site-slider__slide-content:hover .site-slider__icon-container:before {
  transform: scale(1.1);
}
.site-slider__slide-icon {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 50%;
  left: 50%;
  fill: none;
  stroke: var(--primary-color);
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1300px) {
  .site-slider__slide-icon {
    width: 22px;
    height: 22px;
  }
}
.site-slider__subtitle {
  margin-bottom: 10px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.5;
  color: var(--secondary-color);
}
.site-slider__slide--image-first .site-slider__picture-container {
  margin-bottom: 30px;
}
@media screen and (max-width: 500px) {
  .site-slider__slide--image-first .site-slider__picture-container {
    margin-bottom: 20px;
  }
}
.site-slider__slide--text-first .site-slider__picture-container {
  margin-top: 30px;
}
@media screen and (max-width: 500px) {
  .site-slider__slide--text-first .site-slider__picture-container {
    margin-top: 20px;
  }
}
.site-slider__slide--text-first {
  padding-top: 120px;
}
@media screen and (max-width: 1400px) {
  .site-slider__slide--text-first {
    padding-top: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .site-slider__slide--text-first {
    padding-top: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .site-slider__slide--text-first {
    padding-top: 60px;
  }
}
@media screen and (max-width: 768px) {
  .site-slider__slide--text-first {
    padding-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .site-slider__slide--text-first {
    padding-top: 20px;
  }
}
@media screen and (max-width: 500px) {
  .site-slider__slide--text-first {
    padding-top: 0;
  }
}

.form__title {
  margin-bottom: 60px;
}
@media screen and (max-width: 1024px) {
  .form__title {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .form__title {
    margin-bottom: 40px;
  }
}
.form__text {
  padding: 15px 0 var(--y-spacing-80);
}
.form__grid-gaps {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
}
@media screen and (max-width: 1200px) {
  .form__grid-gaps {
    width: calc(100% + 50px);
    margin-right: -25px;
    margin-left: -25px;
  }
}
@media screen and (max-width: 1024px) {
  .form__grid-gaps {
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media screen and (max-width: 900px) {
  .form__grid-gaps {
    width: calc(100% + 10px);
    margin-right: -5px;
    margin-left: -5px;
  }
}
.form__column {
  padding-right: 40px;
  padding-left: 40px;
}
@media screen and (max-width: 1200px) {
  .form__column {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .form__column {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 900px) {
  .form__column {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.form__column--btn {
  padding-left: 0;
}
.form__column--align-center {
  display: flex;
  justify-content: center;
}
.form ::-webkit-input-placeholder {
  color: rgba(var(--color__primary), 0.2);
  font-weight: normal;
}
.form ::-moz-placeholder {
  color: rgba(var(--color__primary), 0.2);
  font-weight: normal;
}
.form :-ms-input-placeholder {
  color: rgba(var(--color__primary), 0.2);
  font-weight: normal;
}
.form :-moz-placeholder {
  color: rgba(var(--color__primary), 0.2);
  font-weight: normal;
}
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: var(--color-primary);
  transition: none;
  border-bottom: 1px solid var(--secondary-light-color) !important;
}
.form input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: var(--color-primary);
  transition: none;
  border-bottom: 2px solid var(--primary-color) !important;
}
.form .form__note {
  margin-top: 10px;
  font-family: var(--font-family-1);
  white-space: normal;
  font-size: 1.4rem;
  line-height: 1.7;
  color: var(--dynamic-note-color);
}
.form .form__note a {
  color: var(--dynamic-main-color);
  text-decoration: none;
  box-shadow: inset 0 -0.4em 0 0 rgba(var(--dynamic-main-color-rgb), 0.2);
  transition: box-shadow 0.3s;
  display: inline;
}
.form .form__note a:hover {
  box-shadow: inset 0 -1.1em 0 0 rgba(var(--dynamic-main-color-rgb), 0.2);
}
.form .form__note a svg {
  margin-left: 8px;
  width: 11px;
  height: 11px;
  stroke: var(--dynamic-main-color);
  fill: var(--dynamic-main-color);
}
.form .form__note a[href$=".pdf"] svg, .form .form__note a[href$=".doc"] svg, .form .form__note a[href$=".zip"] svg {
  width: 12px;
  height: 12px;
}

.no-margin-top {
  margin-top: 0 !important;
}

#messageWrapper h3, #sengagerFormWrapper h3 {
  font-weight: 700;
}

.download-case__picture-container {
  width: 100%;
  height: 465px;
  padding: 25px 85px;
  position: relative;
  display: block;
}
@media screen and (max-width: 1024px) {
  .download-case__picture-container {
    padding: 25px 50px;
  }
}
@media screen and (max-width: 768px) {
  .download-case__picture-container {
    padding: 25px 30px;
  }
}
@media screen and (max-width: 600px) {
  .download-case__picture-container {
    height: 365px;
    padding: 25px;
  }
}
.download-case__picture {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.download-case__img {
  transform: scale(1);
  transition: transform 600ms var(--ease-out);
}
.download-case__picture-container:hover .download-case__img {
  transform: scale(1.1);
}
.download-case__content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media screen and (max-width: 600px) {
  .download-case__content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.download-case__text {
  max-width: 400px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 600px) {
  .download-case__text {
    width: 100%;
    margin-bottom: 30px;
  }
}
.download-case__category, .download-case__title {
  color: #fff;
}
.download-case__category {
  font-family: var(--font-family-1);
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 30px;
}
@media screen and (max-width: 600px) {
  .download-case__category {
    margin-bottom: 20px;
  }
}
.download-case__icon {
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  background: #fff;
  position: relative;
  z-index: 2;
}
.download-case__icon::before {
  content: "";
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  transform: scale(1);
  transition: transform 600ms var(--ease-out);
}
@media screen and (max-width: 900px) {
  .download-case__icon::before {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}
@media screen and (max-width: 600px) {
  .download-case__icon::before {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  }
}
@media screen and (max-width: 900px) {
  .download-case__icon {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}
@media screen and (max-width: 600px) {
  .download-case__icon {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  }
}
.download-case__picture-container:hover .download-case__icon::before {
  transform: scale(1.1);
}
.download-case__svg {
  width: 35px;
  height: 35px;
  stroke: var(--primary-color);
  stroke-width: 2px;
  fill: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}
@media screen and (max-width: 900px) {
  .download-case__svg {
    width: 30px;
    height: 30px;
  }
}

.capsules-slider {
  --capsules-slider__arrow-icon--dimension: 23px;
  --capsules-slider__slick-list--side-padding: 15vw;
  --capsules-slider__slide--side-padding: 60px;
}
@media screen and (max-width: 1600px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 13vw;
  }
}
@media screen and (max-width: 1400px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 12vw;
  }
}
@media screen and (max-width: 1200px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 11vw;
  }
}
@media screen and (max-width: 1024px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 9vw;
  }
}
@media screen and (max-width: 768px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 30px;
  }
}
@media screen and (max-width: 600px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 25px;
  }
}
@media screen and (max-width: 450px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 20px;
  }
}
@media screen and (max-width: 375px) {
  .capsules-slider {
    --capsules-slider__slick-list--side-padding: 15px;
  }
}
@media screen and (max-width: 1400px) {
  .capsules-slider {
    --capsules-slider__slide--side-padding: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .capsules-slider {
    --capsules-slider__slide--side-padding: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .capsules-slider {
    --capsules-slider__slide--side-padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .capsules-slider {
    --capsules-slider__slide--side-padding: 10px;
  }
}
@media screen and (max-width: 500px) {
  .capsules-slider {
    --capsules-slider__slide--side-padding: 5px;
  }
}

.capsules-slider {
  position: relative;
  background: var(--primary-color);
  will-change: transform;
  overflow: hidden;
}
.capsules-slider__container {
  padding: var(--y-spacing-190) 0;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out);
}
@media screen and (max-width: 500px) {
  .capsules-slider__container {
    padding-top: 85px;
    padding-bottom: 85px;
  }
}
[data-fire] .capsules-slider__container {
  opacity: 1;
  transform: none;
}
.capsules-slider__shape {
  position: absolute;
  height: 85%;
  top: 7.5%;
  left: 50%;
  opacity: 0.2;
  transform: translateX(-50%);
}
@media screen and (max-width: 600px) {
  .capsules-slider__shape {
    top: 50px;
    height: calc(100% - 100px);
  }
}
@media screen and (max-width: 500px) {
  .capsules-slider__shape {
    top: 40px;
    height: calc(100% - 80px);
  }
}
.capsules-slider__title {
  position: relative;
  text-align: center;
  z-index: 2;
}
.capsules-slider__navigation {
  position: absolute;
  bottom: calc(100% - var(--capsules-slider__arrow-icon--dimension));
  left: calc(100% - var(--capsules-slider__slick-list--side-padding) + var(--capsules-slider__slide--side-padding));
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .capsules-slider__navigation {
    display: none;
  }
}
.capsules-slider__arrow--prev {
  margin-right: 35px;
}
.capsules-slider__arrow-icon {
  width: var(--capsules-slider__arrow-icon--dimension);
  height: var(--capsules-slider__arrow-icon--dimension);
  fill: none;
  stroke: var(--primary-light-color);
  transition: stroke 300ms;
}
.capsules-slider__arrow-icon--prev {
  transform: rotate(180deg);
}
.capsules-slider__arrow:hover .capsules-slider__arrow-icon {
  stroke: rgba(var(--primary-light-rgb-color), 0.7);
}
.capsules-slider__slider-container {
  position: relative;
}
.capsules-slider__slider {
  margin-top: -20px;
}
.capsules-slider__slider .slick-list {
  padding: 0 var(--capsules-slider__slick-list--side-padding) !important;
}
.capsules-slider__slide {
  position: relative;
  padding: 0 var(--capsules-slider__slide--side-padding);
}
.capsules-slider__link {
  width: 100%;
}
.capsules-slider__picture-container {
  position: relative;
  padding-top: 66%;
  width: 100%;
}
@media screen and (max-width: 450px) {
  .capsules-slider__picture-container {
    padding-top: 70%;
  }
}
.capsules-slider__picture {
  position: absolute;
  top: 7.5%;
  right: 0;
  bottom: 7.5%;
  left: 0;
  overflow: hidden;
  transition: top 500ms var(--ease-out), bottom 500ms var(--ease-out);
}
@media screen and (max-width: 450px) {
  .capsules-slider__picture {
    top: 0;
    bottom: 0;
  }
}
.capsules-slider__slider .slick-slide.slick-cloned {
  pointer-events: none;
}
.capsules-slider__slider .slick-slide.slick-current .capsules-slider__picture, .capsules-slider__slider .slick-slide.slickAnimate .capsules-slider__picture {
  top: 0%;
  bottom: 0%;
}
.capsules-slider__picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5018382353) 0%, rgba(0, 0, 0, 0) 25%);
  z-index: 1;
}
.capsules-slider__img {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  left: 0;
  transition: transform 600ms var(--ease-out);
}
.capsules-slider__link:hover .capsules-slider__img {
  transform: scale(1.05);
}
.capsules-slider__slide-content {
  position: absolute;
  width: 440px;
  padding: 60px 80px 60px 30px;
  right: 0;
  bottom: 10%;
  background: var(--secondary-color);
  opacity: 0;
  z-index: 2;
  transition: background 300ms, opacity 500ms;
}
@media screen and (max-width: 1200px) {
  .capsules-slider__slide-content {
    padding: 50px 75px 50px 25px;
  }
}
@media screen and (max-width: 1024px) {
  .capsules-slider__slide-content {
    padding: 40px 70px 40px 20px;
  }
}
@media screen and (max-width: 768px) {
  .capsules-slider__slide-content {
    position: relative;
    margin-top: -67px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    max-width: 350px;
    padding: 30px 90px 30px 20px;
  }
}
@media screen and (max-width: 600px) {
  .capsules-slider__slide-content {
    margin-top: -50px;
  }
}
@media screen and (max-width: 500px) {
  .capsules-slider__slide-content {
    padding: 25px 60px 25px 25px;
    margin-top: -40px;
  }
}
@media screen and (max-width: 400px) {
  .capsules-slider__slide-content {
    margin-top: -25px;
    margin-left: 20px;
  }
}
.capsules-slider__slider .slick-slide.slick-current .capsules-slider__slide-content, .capsules-slider__slider .slick-slide.slickAnimate .capsules-slider__slide-content {
  opacity: 1;
}
.capsules-slider__link:hover .capsules-slider__slide-content {
  background: var(--primary-light-color);
}
.capsules-slider__slide-subtitle {
  display: block;
  margin-bottom: 10px;
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-dark-color);
  line-height: 1.5;
  text-transform: uppercase;
  opacity: 0.6;
}
@media screen and (max-width: 768px) {
  .capsules-slider__slide-subtitle {
    margin-bottom: 5px;
  }
}
.capsules-slider__slide-title {
  font-family: var(--font-family-2);
  transition: color 300ms;
}
.capsules-slider__link:hover .capsules-slider__slide-title {
  color: var(--secondary-color);
}
.capsules-slider__play {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
@media screen and (max-width: 768px) {
  .capsules-slider__play {
    right: 15px;
    bottom: 15px;
    width: 38px;
    height: 38px;
  }
}
@media screen and (max-width: 500px) {
  .capsules-slider__play {
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .capsules-slider__play:before {
    border-width: 6.5px 0 6.5px 11.3px;
  }
}
@media screen and (max-width: 500px) {
  .capsules-slider__play:before {
    border-width: 5px 0 5px 8.7px;
  }
}
.capsules-slider__link:hover .capsules-slider__play {
  background: var(--secondary-color);
}
.capsules-slider__link:hover .capsules-slider__play:before {
  border-color: transparent transparent transparent var(--primary-light-color);
}

.news-featured {
  --news-featured__item-list--side-gap: 55px;
}
@media screen and (max-width: 1600px) {
  .news-featured {
    --news-featured__item-list--side-gap: 50px;
  }
}
@media screen and (max-width: 1400px) {
  .news-featured {
    --news-featured__item-list--side-gap: 40px;
  }
}
@media screen and (max-width: 1300px) {
  .news-featured {
    --news-featured__item-list--side-gap: 30px;
  }
}
@media screen and (max-width: 1300px) {
  .news-featured {
    --news-featured__item-list--side-gap: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .news-featured {
    --news-featured__item-list--side-gap: 10px;
  }
}
@media screen and (max-width: 500px) {
  .news-featured {
    --news-featured__item-list--side-gap: 0px;
  }
}

.news-featured__container {
  position: relative;
  padding: 0 var(--x-spacing-110);
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out);
}
[data-fire] .news-featured__container {
  opacity: 1;
  transform: none;
}
.news-featured__line {
  position: absolute;
  top: 250px;
  right: 0;
  left: 0;
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 2.5px;
}
@media screen and (max-width: 850px) {
  .news-featured__line {
    stroke-width: 4px;
  }
}
.news-featured__title-item {
  position: relative;
  width: 50%;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms, transform 800ms var(--ease-out);
}
@media screen and (max-width: 850px) {
  .news-featured__title-item {
    width: 100%;
  }
}
.news-featured__title-item[data-fire] {
  opacity: 1;
  transform: none;
}
.news-featured__shape {
  position: absolute;
  width: 260px;
  height: 260px;
  top: -30px;
  left: -70px;
  fill: var(--secondary-light-color);
  transform: rotate(60deg);
}
@media screen and (max-width: 1200px) {
  .news-featured__shape {
    width: 220px;
    height: 220px;
    left: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .news-featured__shape {
    width: 180px;
    height: 180px;
    left: -30px;
  }
}
@media screen and (max-width: 850px) {
  .news-featured__shape {
    width: 150px;
    height: 150px;
    left: -20px;
    top: -10px;
  }
}
@media screen and (max-width: 500px) {
  .news-featured__shape {
    width: 110px;
    height: 110px;
    left: -10px;
    top: 0px;
  }
}
.news-featured__title {
  position: relative;
}
.news-featured__text {
  position: relative;
  max-width: 250px;
  margin-top: 80px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.6;
  color: var(--primary-color);
}
@media screen and (max-width: 1400px) {
  .news-featured__text {
    margin-top: 70px;
  }
}
@media screen and (max-width: 1200px) {
  .news-featured__text {
    margin-top: 60px;
  }
}
@media screen and (max-width: 1024px) {
  .news-featured__text {
    margin-top: 50px;
  }
}
@media screen and (max-width: 900px) {
  .news-featured__text {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .news-featured__text {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .news-featured__text {
    margin-top: 20px;
  }
}
.news-featured__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  width: calc(100% + var(--news-featured__item-list--side-gap) * 2);
  margin-left: calc(0px - var(--news-featured__item-list--side-gap));
  margin-right: calc(0px - var(--news-featured__item-list--side-gap));
}
@media screen and (max-width: 1400px) {
  .news-featured__list {
    margin-top: 90px;
  }
}
@media screen and (max-width: 1200px) {
  .news-featured__list {
    margin-top: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .news-featured__list {
    margin-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  .news-featured__list {
    margin-top: 60px;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 600px) {
  .news-featured__list {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .news-featured__list {
    margin-top: 40px;
  }
}
@media screen and (max-width: 400px) {
  .news-featured__list {
    margin-top: 30px;
  }
}
.news-featured__list-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
  padding: 0 var(--news-featured__item-list--side-gap);
}
@media screen and (max-width: 768px) {
  .news-featured__list-item {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .news-featured__list-item {
    width: 100%;
  }
}
.news-featured__list-item:nth-child(3n-1) {
  top: -170px;
}
@media screen and (max-width: 1300px) {
  .news-featured__list-item:nth-child(3n-1) {
    top: -140px;
  }
}
@media screen and (max-width: 1200px) {
  .news-featured__list-item:nth-child(3n-1) {
    top: -120px;
  }
}
@media screen and (max-width: 1024px) {
  .news-featured__list-item:nth-child(3n-1) {
    top: -100px;
  }
}
@media screen and (max-width: 768px) {
  .news-featured__list-item:nth-child(3n-1) {
    top: 0;
  }
}
@media screen and (max-width: 768px) {
  .news-featured__list-item:nth-child(3n-1), .news-featured__list-item:nth-child(3n) {
    top: -50px;
  }
}
@media screen and (max-width: 500px) {
  .news-featured__list-item:nth-child(3n-1), .news-featured__list-item:nth-child(3n) {
    margin-top: 30px;
    top: 0px;
  }
}
@media screen and (max-width: 768px) and (min-width: 501px) {
  .news-featured__list-item:nth-child(3n) {
    display: none;
  }
}
.news-featured__button {
  position: absolute;
  bottom: -130px;
}
@media screen and (max-width: 1300px) {
  .news-featured__button {
    bottom: -120px;
  }
}
@media screen and (max-width: 1200px) {
  .news-featured__button {
    bottom: -100px;
  }
}
@media screen and (max-width: 1024px) {
  .news-featured__button {
    bottom: -90px;
  }
}
@media screen and (max-width: 768px) {
  .news-featured__button {
    display: none;
  }
}
.news-featured__button-mobile {
  margin-top: 60px;
}
@media screen and (min-width: 769px) {
  .news-featured__button-mobile {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .news-featured__button-mobile {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .news-featured__button-mobile {
    margin-top: 40px;
  }
}
@media screen and (max-width: 400px) {
  .news-featured__button-mobile {
    margin-top: 30px;
  }
}

.news-block {
  position: relative;
  width: 100%;
  padding-top: 148%;
  will-change: transform;
}
.news-block__picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.news-block__picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(var(--primary-rgb-color), 1) 5%, rgba(var(--primary-rgb-color), 0) 60%);
  z-index: 1;
}
.news-block__img {
  transition: transform 600ms var(--ease-out);
}
.news-block:hover .news-block__img {
  transform: scale(1.1);
}
.news-block__icon {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 68px;
  height: 68px;
}
@media screen and (max-width: 1300px) {
  .news-block__icon {
    width: 54px;
    height: 54px;
    right: 25px;
    bottom: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .news-block__icon {
    width: 48px;
    height: 48px;
    right: 20px;
    bottom: 20px;
  }
}
.news-block__icon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--primary-light-color);
  border-radius: 50%;
  transition: transform 600ms var(--ease-out);
}
.news-block:hover .news-block__icon:before {
  transform: scale(1.1);
}
.news-block__svg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  fill: none;
  stroke: var(--primary-color);
  stroke-width: 1px;
  transform: translate(-50%, -50%);
}
.news-block__content {
  display: flex;
  position: absolute;
  padding: 100px 40px 50px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
}
@media screen and (max-width: 1300px) {
  .news-block__content {
    padding: 100px 30px 40px;
  }
}
@media screen and (max-width: 1200px) {
  .news-block__content {
    padding: 100px 25px 30px;
  }
}
@media screen and (max-width: 1100px) {
  .news-block__content {
    padding: 100px 25px 25px;
  }
}
@media screen and (max-width: 500px) {
  .news-block__content {
    padding: 100px 40px 50px;
  }
}
@media screen and (max-width: 400px) {
  .news-block__content {
    padding: 100px 30px 35px;
  }
}
@media screen and (max-width: 350px) {
  .news-block__content {
    padding: 100px 25px 25px;
  }
}
.news-block__date {
  margin-bottom: 20px;
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--primary-light-color);
}
@media screen and (max-width: 1200px) {
  .news-block__date {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 500px) {
  .news-block__date {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 400px) {
  .news-block__date {
    margin-bottom: 10px;
  }
}
.news-block__title {
  font-family: var(--font-family-1);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.6;
  color: var(--primary-light-color);
}
@media screen and (max-width: 1200px) {
  .news-block__title {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 1024px) {
  .news-block__title {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 900px) {
  .news-block__title {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 500px) {
  .news-block__title {
    font-size: 2rem;
  }
}
@media screen and (max-width: 400px) {
  .news-block__title {
    font-size: 1.9rem;
  }
}

.title-on-shape__container {
  position: relative;
  height: 600px;
}
@media screen and (max-width: 1200px) {
  .title-on-shape__container {
    height: 550px;
  }
}
@media screen and (max-width: 1024px) {
  .title-on-shape__container {
    height: 500px;
  }
}
@media screen and (max-width: 768px) {
  .title-on-shape__container {
    height: 450px;
  }
}
@media screen and (max-width: 600px) {
  .title-on-shape__container {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .title-on-shape__container {
    height: 300px;
  }
}
.title-on-shape__shape-container {
  position: absolute;
  width: 65%;
  max-width: 600px;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 600px) {
  .title-on-shape__shape-container {
    max-width: 400px;
  }
}
.title-on-shape__shape {
  position: absolute;
  height: inherit;
  top: -20px;
  left: 0;
  fill: var(--secondary-light-color);
  transform: translateX(-50%);
}
.title-on-shape__content {
  position: absolute;
  padding: 0 var(--x-spacing-110);
  top: calc(50% + 50px);
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
  opacity: 0;
  transition: opacity 800ms, top 800ms var(--ease-out);
}
.title-on-shape__content[data-fire] {
  opacity: 1;
  top: 50%;
}
@media screen and (max-width: 600px) {
  .title-on-shape__title br {
    display: none;
  }
}
.title-on-shape__button {
  margin-top: 40px;
}

.block-intro__content-item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  padding: 0 var(--x-spacing-170);
  padding-top: var(--y-spacing-100);
}
@media screen and (max-width: 768px) {
  .block-intro__content-item {
    padding: 0 var(--x-spacing-110);
  }
}
[data-fire] .block-intro__content-item {
  opacity: 1;
  transform: none;
}
.block-intro__content {
  position: relative;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .block-intro__content {
    margin-top: 60px;
  }
}
.block-intro__title {
  position: relative;
}
.block-intro__shape {
  position: absolute;
  width: 215px;
  top: -50px;
  left: -100px;
  fill: var(--secondary-light-color);
}
@media screen and (max-width: 1400px) {
  .block-intro__shape {
    width: 200px;
    left: -75px;
  }
}
@media screen and (max-width: 1200px) {
  .block-intro__shape {
    width: 160px;
    left: -50px;
  }
}
@media screen and (max-width: 768px) {
  .block-intro__shape {
    width: 90px;
    top: -30px;
    left: -10px;
  }
}

.showcase__container {
  padding: var(--y-spacing-150) var(--x-spacing-200);
  margin-top: var(--y-spacing-150);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media screen and (max-width: 900px) {
  .showcase__container {
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
  }
}
.showcase__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.showcase__shape {
  position: absolute;
  width: 215px;
  top: -50px;
  left: -100px;
  fill: #fff;
}
@media screen and (max-width: 1600px) {
  .showcase__shape {
    width: 200px;
  }
}
@media screen and (max-width: 1300px) {
  .showcase__shape {
    width: 180px;
  }
}
@media screen and (max-width: 1100px) {
  .showcase__shape {
    width: 160px;
    left: 0;
    top: -25px;
  }
}
@media screen and (max-width: 600px) {
  .showcase__shape {
    width: 130px;
  }
}
@media screen and (max-width: 400px) {
  .showcase__shape {
    width: 100px;
  }
}
.showcase__text {
  max-width: 600px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1400px) {
  .showcase__text {
    max-width: 500px;
  }
}
@media screen and (max-width: 1100px) {
  .showcase__text {
    padding-left: var(--x-spacing-100);
  }
}
@media screen and (max-width: 900px) {
  .showcase__text {
    margin-bottom: 50px;
  }
}
[data-fire] .showcase__text {
  opacity: 1;
  transform: none;
}
.showcase__category {
  font-weight: 700;
  margin-bottom: 30px;
}
@media screen and (max-width: 900px) {
  .showcase__category {
    margin-bottom: 15px;
  }
}
.showcase__title {
  font-size: 7rem;
  margin-bottom: 50px;
}
@media screen and (max-width: 900px) {
  .showcase__title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1200px) {
  .showcase__title {
    font-size: 6rem;
  }
}
@media screen and (max-width: 700px) {
  .showcase__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 500px) {
  .showcase__title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 400px) {
  .showcase__title {
    font-size: 3.5rem;
  }
}
.showcase__button {
  pointer-events: none;
}
.showcase__container:hover .showcase__button {
  background: var(--primary-color);
}
.showcase__picture-container {
  position: absolute;
  top: 50%;
  right: var(--x-spacing-200);
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .showcase__picture-container {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
  }
}
.showcase__picture {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
  width: 430px;
  height: 430px;
  min-width: 430px;
  min-height: 430px;
  border-radius: 50%;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  overflow: hidden;
}
@media screen and (max-width: 1400px) {
  .showcase__picture {
    width: 400px;
    height: 400px;
    min-width: 400px;
    min-height: 400px;
  }
}
@media screen and (max-width: 1200px) {
  .showcase__picture {
    width: 350px;
    height: 350px;
    min-width: 350px;
    min-height: 350px;
  }
}
@media screen and (max-width: 900px) {
  .showcase__picture {
    width: 430px;
    height: 430px;
    min-width: 430px;
    min-height: 430px;
  }
}
@media screen and (max-width: 500px) {
  .showcase__picture {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
  }
}
[data-fire] .showcase__picture {
  opacity: 1;
  transform: none;
}
.showcase__img {
  transform: scale(1);
  transition: transform 600ms var(--ease-out);
}
.showcase__container:hover .showcase__img {
  transform: scale(1.1);
}
.showcase__shape-container {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
}
.showcase__line {
  width: 1564px;
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 2px;
  position: absolute;
  right: -60px;
  bottom: -600px;
}
@media screen and (max-width: 1300px) {
  .showcase__line {
    right: -100px;
  }
}
@media screen and (max-width: 1100px) {
  .showcase__line {
    right: -160px;
  }
}
@media screen and (max-width: 1024px) {
  .showcase__line {
    right: -190px;
  }
}
@media screen and (max-width: 900px) {
  .showcase__line {
    right: -380px;
    bottom: -500px;
  }
}
@media screen and (max-width: 600px) {
  .showcase__line {
    width: 1300px;
    bottom: -400px;
  }
}
@media screen and (max-width: 440px) {
  .showcase__line {
    width: 1100px;
    bottom: -350px;
    right: -300px;
  }
}

.contact-box {
  --content-width: 520px;
}
@media screen and (max-width: 1024px) {
  .contact-box {
    --content-width: 420px;
  }
}
@media screen and (max-width: 768px) {
  .contact-box {
    --content-width: 320px;
  }
}

.contact-box__container {
  display: flex;
}
@media screen and (max-width: 500px) {
  .contact-box__container {
    flex-direction: column;
    max-height: 100%;
  }
}
.contact-box__content {
  width: var(--content-width);
  background: var(--primary-color);
  padding: var(--y-spacing-110) 120px var(--y-spacing-130);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 1400px) {
  .contact-box__content {
    padding: var(--y-spacing-120) var(--x-spacing-120);
  }
}
@media screen and (max-width: 500px) {
  .contact-box__content {
    width: 100%;
  }
}
.contact-box__content-top, .contact-box__content-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-box__title {
  color: #fff;
  margin-bottom: 30px;
}
.contact-box__title.small-title {
  font-weight: 600;
}
.contact-box__subtitle {
  display: block;
  font-size: 2rem;
}
.contact-box__address, .contact-box__phones {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-box__phones {
  margin-top: 20px;
}
.contact-box__phones .paragraph:not(:last-child) {
  margin-bottom: 20px;
}
.contact-box__text {
  color: #fff;
}
.contact-box__numbers {
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  box-shadow: inset 0 -0.4em 0 0 #5c828c;
  transition: box-shadow 0.3s;
  display: inline;
}
.contact-box__numbers:hover {
  box-shadow: inset 0 -1.1em 0 0 #5c828c;
}
.contact-box__button {
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  transition: color 300ms;
  padding-right: 25px;
  margin-top: 50px;
  position: relative;
}
.contact-box__button:hover {
  color: var(--other-5);
}
@media screen and (max-width: 600px) {
  .contact-box__button {
    margin-top: 30px;
  }
}
.contact-box__button-icon {
  width: 10px;
  height: 17px;
  stroke: #fff;
  transition: stroke 300ms;
  stroke-width: 2px;
  fill: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.contact-box__button:hover .contact-box__button-icon {
  stroke: var(--other-5);
}
.contact-box__picture {
  width: calc(100% - var(--content-width));
}
@media screen and (max-width: 500px) {
  .contact-box__picture {
    width: 100%;
  }
}

.news-list {
  --news-list__item-list--side-gap: 55px;
}
@media screen and (max-width: 1600px) {
  .news-list {
    --news-list__item-list--side-gap: 50px;
  }
}
@media screen and (max-width: 1400px) {
  .news-list {
    --news-list__item-list--side-gap: 40px;
  }
}
@media screen and (max-width: 1300px) {
  .news-list {
    --news-list__item-list--side-gap: 30px;
  }
}
@media screen and (max-width: 1200px) {
  .news-list {
    --news-list__item-list--side-gap: 20px;
  }
}
@media screen and (max-width: 950px) {
  .news-list {
    --news-list__item-list--side-gap: 10px;
  }
}
@media screen and (max-width: 500px) {
  .news-list {
    --news-list__item-list--side-gap: 0px;
  }
}

.news-list__container {
  position: relative;
  padding: 0 var(--x-spacing-110);
}
.news-list__line {
  position: absolute;
  top: 250px;
  right: 0;
  left: 0;
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 2.5px;
}
.news-list__title-item {
  position: relative;
  width: 50%;
}
@media screen and (max-width: 850px) {
  .news-list__title-item {
    width: 100%;
  }
}
.news-list .field {
  margin-bottom: 0;
}
.news-list__shape {
  position: absolute;
  width: 260px;
  height: 260px;
  top: -30px;
  left: -70px;
  fill: var(--secondary-light-color);
  transform: rotate(60deg);
}
@media screen and (max-width: 1200px) {
  .news-list__shape {
    width: 220px;
    height: 220px;
    left: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .news-list__shape {
    width: 180px;
    height: 180px;
    left: -30px;
  }
}
@media screen and (max-width: 850px) {
  .news-list__shape {
    width: 150px;
    height: 150px;
    left: -20px;
    top: -10px;
  }
}
@media screen and (max-width: 500px) {
  .news-list__shape {
    width: 110px;
    height: 110px;
    left: -10px;
    top: 0px;
  }
}
.news-list__title {
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .news-list__title {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .news-list__title {
    margin-bottom: 30px;
  }
}
.news-list__form {
  width: 260px;
}
@media screen and (max-width: 400px) {
  .news-list__form {
    width: 100%;
  }
}
.news-list__text {
  position: relative;
  max-width: 250px;
  margin-top: 80px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.6;
  color: var(--primary-color);
}
@media screen and (max-width: 1400px) {
  .news-list__text {
    margin-top: 70px;
  }
}
@media screen and (max-width: 1200px) {
  .news-list__text {
    margin-top: 60px;
  }
}
@media screen and (max-width: 1024px) {
  .news-list__text {
    margin-top: 50px;
  }
}
@media screen and (max-width: 900px) {
  .news-list__text {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .news-list__text {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .news-list__text {
    margin-top: 20px;
  }
}
.news-list__list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + var(--news-list__item-list--side-gap) * 2);
  margin-left: calc(0px - var(--news-list__item-list--side-gap));
  margin-right: calc(0px - var(--news-list__item-list--side-gap));
}
@media screen and (max-width: 1024px) {
  .news-list__list {
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  .news-list__list {
    margin-top: 60px;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 500px) {
  .news-list__list {
    margin-top: 20px;
  }
}
.news-list__list-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  width: 33.33%;
  padding: 0 var(--news-list__item-list--side-gap);
}
@media screen and (max-width: 1600px) {
  .news-list__list-item {
    margin-top: 100px;
  }
}
@media screen and (max-width: 1400px) {
  .news-list__list-item {
    margin-top: 80px;
  }
}
@media screen and (max-width: 1300px) {
  .news-list__list-item {
    margin-top: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .news-list__list-item {
    margin-top: 40px;
  }
}
@media screen and (max-width: 950px) {
  .news-list__list-item {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .news-list__list-item {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .news-list__list-item {
    width: 100%;
  }
}
.news-list__list-item:nth-child(3n-1) {
  top: -170px;
}
@media screen and (max-width: 1600px) {
  .news-list__list-item:nth-child(3n-1) {
    top: -150px;
  }
}
@media screen and (max-width: 1400px) {
  .news-list__list-item:nth-child(3n-1) {
    top: -130px;
  }
}
@media screen and (max-width: 1300px) {
  .news-list__list-item:nth-child(3n-1) {
    top: -100px;
  }
}
@media screen and (max-width: 1200px) {
  .news-list__list-item:nth-child(3n-1) {
    top: -70px;
  }
}
@media screen and (max-width: 1024px) {
  .news-list__list-item:nth-child(3n-1) {
    top: -40px;
  }
}
@media screen and (max-width: 768px) {
  .news-list__list-item:nth-child(3n-1) {
    top: 0;
  }
}
@media screen and (max-width: 768px) {
  .news-list__list-item:nth-child(3n-1), .news-list__list-item:nth-child(3n) {
    top: -50px;
  }
}
@media screen and (max-width: 500px) {
  .news-list__list-item:nth-child(3n-1), .news-list__list-item:nth-child(3n) {
    margin-top: 30px;
    top: 0px;
  }
}
@media screen and (max-width: 768px) and (min-width: 501px) {
  .news-list__list-item:nth-child(3n) {
    display: none;
  }
}

.pagination__container {
  padding: 0 50px;
  margin-bottom: var(--y-spacing-140);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  .pagination__container {
    padding: 0;
  }
}
.pagination__prev, .pagination__next {
  width: 20px;
  height: 16px;
}
.pagination .inactive {
  pointer-events: none;
}
.pagination .inactive svg {
  opacity: 0.5;
  cursor: auto;
}
.pagination__prev {
  transform: rotate(180deg);
}
.pagination__prev-svg, .pagination__next-svg {
  width: 100%;
  height: 100%;
  stroke: var(--primary-color);
  fill: none;
  transition: stroke 300ms var(--ease-in-out), fill 300ms var(--ease-in-out);
  cursor: pointer;
}
.pagination__prev-svg:hover, .pagination__next-svg:hover {
  stroke: var(--secondary-color);
}
.pagination__pages-list {
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .pagination__pages-list {
    padding: 0 25px;
  }
}
.pagination__page {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
}
@media screen and (max-width: 400px) {
  .pagination__page {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
}
.pagination__page--active {
  background: var(--primary-color);
}
.pagination__page--active .pagination__page-link {
  color: #fff;
  pointer-events: none;
  cursor: auto;
}
.pagination__page:last-child() {
  margin-right: 0;
}
.pagination__page.dots {
  width: 20px;
  min-width: 20px;
}
.pagination__page-link {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  color: var(--primary-color);
  transition: color 300ms var(--ease-in-out);
  text-align: center;
  position: absolute;
  line-height: 40px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.pagination__page-link:hover {
  color: var(--secondary-color);
}
@media screen and (max-width: 400px) {
  .pagination__page-link {
    line-height: 30px;
  }
}

.pagination-variant__container {
  padding-right: var(--x-spacing-200);
  padding-bottom: var(--y-spacing-100);
  padding-left: var(--x-spacing-200);
}
.pagination-variant__grid {
  display: flex;
  justify-content: space-between;
  padding: 40px 50px;
  border-top: 1px solid var(--primary-color);
}
@media screen and (max-width: 1024px) {
  .pagination-variant__grid {
    padding: 40px 0;
  }
}
@media screen and (max-width: 600px) {
  .pagination-variant__grid {
    flex-wrap: wrap;
  }
}
.pagination-variant__content {
  display: flex;
  justify-content: space-between;
  width: 320px;
}
@media screen and (max-width: 768px) {
  .pagination-variant__content {
    width: 300px;
  }
}
@media screen and (max-width: 600px) {
  .pagination-variant__content {
    width: 100%;
    margin-top: 20px;
  }
}
.pagination-variant__back, .pagination-variant__prev, .pagination-variant__next {
  font-family: var(--font-family-1);
  font-size: 1.7rem;
  color: var(--primary-color);
  transition: color 300ms var(--ease-in-out);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.pagination-variant__back:hover, .pagination-variant__prev:hover, .pagination-variant__next:hover {
  color: var(--secondary-color);
}
.pagination-variant__back span, .pagination-variant__prev span, .pagination-variant__next span {
  line-height: 14px;
}
.pagination-variant__back-svg, .pagination-variant__prev-svg, .pagination-variant__next-svg {
  width: 20px;
  height: 16px;
  stroke: var(--primary-color);
  fill: none;
  position: relative;
  top: -1px;
  transition: stroke 300ms var(--ease-in-out);
  cursor: pointer;
}
.pagination-variant__prev:hover .pagination-variant__prev-svg {
  stroke: var(--secondary-color);
}
.pagination-variant__next:hover .pagination-variant__next-svg {
  stroke: var(--secondary-color);
}
.pagination-variant__back-svg, .pagination-variant__prev-svg {
  transform: rotate(180deg);
  margin-right: 20px;
}
.pagination-variant__next-svg {
  margin-left: 20px;
}
.pagination-variant .inactive {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.similar-news__title-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 700px) {
  .similar-news__title-item {
    flex-direction: column;
    justify-content: auto;
  }
}
.similar-news__shape {
  position: absolute;
  width: 260px;
  height: 260px;
  top: -70px;
  left: -90px;
  fill: var(--secondary-light-color);
  transform: rotate(60deg);
}
@media screen and (max-width: 1200px) {
  .similar-news__shape {
    width: 220px;
    height: 220px;
    left: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .similar-news__shape {
    width: 180px;
    height: 180px;
    left: -30px;
  }
}
@media screen and (max-width: 850px) {
  .similar-news__shape {
    width: 150px;
    height: 150px;
    left: -20px;
    top: -10px;
  }
}
@media screen and (max-width: 500px) {
  .similar-news__shape {
    width: 110px;
    height: 110px;
    left: -10px;
    top: 0px;
  }
}
.similar-news__title {
  font-family: var(--font-family-2);
  font-size: 7rem;
  font-weight: 700;
  line-height: 1.1;
  color: var(--primary-color);
  position: relative;
}
@media screen and (max-width: 768px) {
  .similar-news__title {
    font-size: 6.5rem;
  }
}
@media screen and (max-width: 700px) {
  .similar-news__title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  .similar-news__title {
    font-size: 6rem;
  }
}
@media screen and (max-width: 500px) {
  .similar-news__title {
    font-size: 5.5rem;
  }
}
@media screen and (max-width: 450px) {
  .similar-news__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 400px) {
  .similar-news__title {
    font-size: 4.5rem;
  }
}
.similar-news__button {
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--secondary-color);
  transition: color 300ms;
  align-self: flex-end;
  padding-right: 25px;
  position: relative;
}
.similar-news__button:hover {
  color: var(--primary-color);
}
@media screen and (max-width: 700px) {
  .similar-news__button {
    align-self: flex-start;
  }
}
.similar-news__button-icon {
  width: 10px;
  height: 17px;
  stroke: var(--secondary-color);
  transition: stroke 300ms;
  stroke-width: 2px;
  fill: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.similar-news__button:hover .similar-news__button-icon {
  stroke: var(--primary-color);
}
.similar-news__news-list {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 700px) {
  .similar-news__news-list {
    margin-top: 50px;
  }
}
.similar-news__news {
  width: 100%;
  border-top: 1px solid var(--primary-color);
}
.similar-news__news:last-child() {
  border-bottom: 1px solid var(--primary-color);
}
.similar-news__link {
  padding: 20px 0;
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 600px) {
  .similar-news__link {
    padding: 35px 0;
  }
}
.similar-news__picture {
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 50%;
  margin-right: 50px;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .similar-news__picture {
    width: 140px;
    height: 140px;
    min-width: 140px;
    min-height: 140px;
  }
}
@media screen and (max-width: 600px) {
  .similar-news__picture {
    display: none;
  }
}
.similar-news__picture-img {
  transform: scale(1);
  transition: transform 600ms var(--ease-out);
}
.similar-news__link:hover .similar-news__picture-img {
  transform: scale(1.1);
}
.similar-news__content {
  display: flex;
  flex: 1;
  align-items: flex-end;
}
.similar-news__content-wrapper {
  flex: 1;
  padding-right: 50px;
}
@media screen and (max-width: 768px) {
  .similar-news__content-wrapper {
    padding-right: 40px;
  }
}
@media screen and (max-width: 600px) {
  .similar-news__content-wrapper {
    padding-right: 30px;
  }
}
.similar-news__surtitle {
  font-family: var(--font-family-1);
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--secondary-color);
  text-transform: uppercase;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .similar-news__surtitle {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .similar-news__surtitle {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 500px) {
  .similar-news__surtitle {
    margin-bottom: 12px;
  }
}
.similar-news__news-title {
  font-family: var(--font-family-1);
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3;
  color: var(--primary-color);
  transition: color 300ms;
}
@media screen and (max-width: 768px) {
  .similar-news__news-title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  .similar-news__news-title {
    font-size: 2rem;
  }
}
.similar-news__icon {
  position: relative;
  width: 70px;
  height: 70px;
}
@media screen and (max-width: 768px) {
  .similar-news__icon {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .similar-news__icon {
    width: 40px;
    height: 40px;
  }
}
.similar-news__icon:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
  background: var(--secondary-light-color);
  transition: transform 600ms var(--ease-out);
}
.similar-news__link:hover .similar-news__icon:before {
  transform: scale(1.1);
}
.similar-news__svg {
  width: 23px;
  height: 19px;
  fill: none;
  stroke: var(--primary-color);
  transition: stroke 300ms;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .similar-news__svg {
    width: 18px;
    height: 15px;
  }
}
@media screen and (max-width: 600px) {
  .similar-news__svg {
    width: 16px;
    height: 12px;
  }
}

.site-block {
  display: block;
}
@media screen and (max-width: 500px) {
  .site-block {
    padding: 0 0 0 var(--site-block__block--side-padding);
  }
}
.site-block__picture-container {
  position: relative;
  margin-bottom: 30px;
}
@media screen and (max-width: 500px) {
  .site-block__picture-container {
    margin-bottom: 20px;
  }
}
.site-block__picture {
  height: 330px;
  position: relative;
  padding-top: 52%;
  overflow: hidden;
}
.site-block__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: transform 600ms var(--ease-out);
}
.site-block:hover .site-block__img {
  transform: scale(1.1);
}
.site-block__icon-container {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 68px;
  height: 68px;
  background: var(--primary-light-color);
  border-radius: 50%;
}
@media screen and (max-width: 1300px) {
  .site-block__icon-container {
    width: 54px;
    height: 54px;
    right: 25px;
    bottom: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .site-block__icon-container {
    width: 48px;
    height: 48px;
    right: 20px;
    bottom: 20px;
  }
}
.site-block__icon {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 50%;
  left: 50%;
  fill: none;
  stroke: var(--primary-color);
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1300px) {
  .site-block__icon {
    width: 22px;
    height: 22px;
  }
}
.site-block__icon-container:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--primary-light-color);
  border-radius: 50%;
  transform: scale(1);
  transition: transform 600ms var(--ease-out);
}
.site-block:hover .site-block__icon-container:before {
  transform: scale(1.1);
}
.site-block__subtitle {
  margin-bottom: 10px;
  font-family: var(--font-family-1);
  font-size: 1.4rem;
  line-height: 1.5;
  color: var(--secondary-color);
}

.errors {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
}
.errors__container {
  background: var(--secondary-light-color);
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .errors__container {
    width: 100%;
    padding: 50px;
  }
}
@media screen and (max-width: 768px) {
  .errors__container {
    width: 100%;
    padding: 50px 25px;
  }
}
.errors__home {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.errors__link {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 15px;
  transform: translateY(-2px);
}
.errors__svg {
  width: 100%;
  height: 100%;
  fill: var(--primary-color);
  stroke: none;
  transition: fill 300ms var(--ease-out);
}
.errors__svg:hover {
  fill: var(--secondary-color);
}
.errors__title {
  color: var(--primary-color);
  text-align: center;
  padding-bottom: 50px;
}
.errors__text {
  color: var(--primary-color);
}
.errors__btn-home {
  margin-top: 50px;
  margin-left: 0 !important;
}

@media screen and (min-width: 1025px) {
  .page--home .page__container--line .page__line {
    top: 70%;
  }
}

.engagedMunicipalities {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--y-spacing-190) 0;
}
.engagedMunicipalities__title {
  font-size: 17px;
  font-family: var(--font-family-1);
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 60px;
}
.engagedMunicipalities__container {
  overflow: hidden;
  display: flex;
  width: 100%;
}
.engagedMunicipalities__container:hover .engagedMunicipalities__txt {
  animation-play-state: paused;
}
.engagedMunicipalities__txt {
  font-size: 80px;
  font-family: var(--font-family-2);
  white-space: nowrap;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  animation: scrollTxtCities 30s linear infinite;
}
.engagedMunicipalities__txt > a {
  color: var(--primary-color);
}
.engagedMunicipalities__txt > a:hover {
  opacity: 0.5;
}
.engagedMunicipalities__dot {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: var(--primary-color);
  margin: auto 30px;
}
.engagedMunicipalities > .button {
  margin-top: 60px;
}
@media screen and (min-width: 769px) {
  .engagedMunicipalities {
    padding-bottom: 0;
  }
}

@keyframes scrollTxtCities {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}