//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.site-slider {
    --site-slider__arrow-icon--dimension: 22px;
    --site-slider__slick-list--side-padding: 30vw;
    @media screen and (max-width: 1600px) { --site-slider__slick-list--side-padding: 25vw; }
    @media screen and (max-width: 1400px) { --site-slider__slick-list--side-padding: 20vw; }
    @media screen and (max-width: 1200px) { --site-slider__slick-list--side-padding: 15vw; }
    @media screen and (max-width: 1024px) { --site-slider__slick-list--side-padding: 20vw; }
    @media screen and (max-width: 600px) { --site-slider__slick-list--side-padding: 10vw; }
    --site-slider__slide--side-padding: 60px;
    @media screen and (max-width: 1200px) { --site-slider__slide--side-padding: 40px; }
    @media screen and (max-width: 1024px) { --site-slider__slide--side-padding: 30px; }
    @media screen and (max-width: 850px)  { --site-slider__slide--side-padding: var(--x-spacing-100); }
}


// ----------------
// Un medium title
// ----------------
.site-slider {
    position: relative;

    &__container {
        position: relative;
        padding-top: 50px;

        @media screen and (max-width: 850px) { padding-top: 0; }
    }

    &__line {
        position: absolute;
        top: 100px;
        right: 0;
        left: 0;
        fill: none;
        stroke: var(--secondary-color);
        stroke-width: 2.5px;
        opacity: 0;

        @media screen and (max-width: 1300px) { top: 150px; }
        @media screen and (max-width: 1150px) { top: 200px; }
        @media screen and (max-width: 1024px) { top: 130px; }
        @media screen and (max-width: 850px)  { top: 100px; }
        @media screen and (max-width: 800px)  { stroke-width: 4px; }
        @media screen and (max-width: 600px)  { top: 50px; }
    }

    [data-fire] &__line {
        opacity: 1;
    }

    &__navigation {
        position: absolute;
        bottom: calc(100% - var(--site-slider__arrow-icon--dimension));
        right: calc(100% - var(--site-slider__slick-list--side-padding) + var(--site-slider__slide--side-padding));
  
        @media screen and (max-width: 1024px) { right: auto; left: var(--x-spacing-110); }
        @media screen and (max-width: 850px)  { display: none; }
    }

    &__arrow {

        &--prev {
            margin-right: 35px;
        }
    }

    &__arrow-icon {
        width: var(--site-slider__arrow-icon--dimension);
        height: var(--site-slider__arrow-icon--dimension);
        fill: none;
        stroke: var(--primary-color);
        transition: stroke 300ms;

        &--prev {
            transform: rotate(180deg);
        }
    }

    &__arrow:hover &__arrow-icon {
        stroke: var(--secondary-color);
    }

    &__slider {

    }

    &__slider .slick-list {
        padding: 0 var(--site-slider__slick-list--side-padding) !important;
        @media screen and (max-width: 850px) { padding: 0 var(--site-slider__slick-list--side-padding) 0 0 !important; }
    }

    &__slide {
        padding: 0 var(--site-slider__slide--side-padding);

        @media screen and (max-width: 500px) { padding: 0 0 0 var(--site-slider__slide--side-padding);}
    }

    &__slide-content {
        width: 100%;
    }

    &__picture-container {
        position: relative;
    }
    
    &__picture {
        position: relative;
        padding-top: 52%;
        overflow: hidden;
    }
    
    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: transform 600ms var(--ease-out);
    }

    &__slide-content:hover &__img {
        transform: scale(1.1);
    }

    &__icon-container {
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 68px;
        height: 68px;
        
        @media screen and (max-width: 1300px) { width: 54px; height: 54px; right: 25px; bottom: 25px; }
        @media screen and (max-width: 1024px) { width: 48px; height: 48px; right: 20px; bottom: 20px; }
    }
    
    &__icon-container:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--primary-light-color);
        border-radius: 50%;
        transition: transform 600ms var(--ease-out);
    }

    &__slide-content:hover &__icon-container:before {
        transform: scale(1.1);
    }
    
    &__slide-icon {
        position: absolute;
        width: 26px;
        height: 26px;
        top: 50%;
        left: 50%;
        fill: none;
        stroke: var(--primary-color);
        transform: translate(-50%, -50%);

        @media screen and (max-width: 1300px) { width: 22px; height: 22px; }
    }

    &__subtitle {
        margin-bottom: 10px;
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.5;
        color: var(--secondary-color);
    }

    &__title {

    }

    &__slide--image-first &__picture-container {
        margin-bottom: 30px;
        @media screen and (max-width: 500px) { margin-bottom: 20px; }
    }

    &__slide--text-first &__picture-container {
        margin-top: 30px;
        @media screen and (max-width: 500px) { margin-top: 20px; }
    }

    &__slide--text-first  {
        padding-top: 120px;

        @media screen and (max-width: 1400px) { padding-top: 100px; }
        @media screen and (max-width: 1200px) { padding-top: 80px; }
        @media screen and (max-width: 1024px) { padding-top: 60px; }
        @media screen and (max-width: 768px)  { padding-top: 40px; }
        @media screen and (max-width: 600px)  { padding-top: 20px; }
        @media screen and (max-width: 500px)  { padding-top: 0; }
    }
}