//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.field--file {
    --file-padding: 20px 60px 20px 20px;
    --file-border-top: 0;
    --file-border-right: var(--file-border-top);
    --file-border-bottom: var(--file-border-top);
    --file-border-left: var(--file-border-top);

    --file-font-family: var(--font-family-1);
    --file-font-size: 1.4rem;
    --file-font-weight: 600;
    --file-line-height: 1.5;
    --file-text-transform: inherit;
    --file-letter-spacing: inherit;
    --file-background-color: var(--secondary-light-color);
    --file-over-background: var(--primary-color);
    --file-color: var(--primary-color);
    --file-over-color: #fff;
    
    --file-x-svg-dimension: 18px;
    --file-x-svg-top: calc(50% - (var(--file-x-svg-dimension) / 2));
    --file-x-svg-right: 20px;

    --file-x-color: var(--file-color);
    --file-x-over-color: var(--secondary-color);

    --file-svg-color: var(--file-color);
    --file-svg-over-color: var(--file-over-color);
}


// ------------------
// Un document
// ------------------
.field {
    &--file &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &--file &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: var(--file-padding);
        border-top: var(--file-border-top);
        border-right: var(--file-border-right);
        border-bottom: var(--file-border-bottom);
        border-left: var(--file-border-left);
        cursor: pointer;
        background-color: var(--file-background-color);
        transition: color 300ms, background 300ms;

        &:hover {
            background: var(--file-over-background);
            color: var(--file-over-color);
        }
    }
    
    &--file &__text {
        font-family: var(--file-font-family);
        font-size: var(--file-font-size);
        font-weight: var(--file-font-weight);
        line-height: var(--file-line-height);
        text-transform: var(--file-text-transform);
        letter-spacing: var(--file-letter-spacing);
        color: var(--file-color);
        transition: color 300ms;
    }

    &--file &__svg {
        position: absolute;
        width: var(--file-x-svg-dimension);
        height: var(--file-x-svg-dimension);
        top: var(--file-x-svg-top);
        right: var(--file-x-svg-right);
        fill: var(--file-svg-color);
        transition: fill 300ms, opacity 300ms;
    }

    &--file &__x {
        position: absolute;
        width: var(--file-x-svg-dimension);
        height: var(--file-x-svg-dimension);
        top: 22px;
        right: var(--file-x-svg-right);
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transition: opacity 300ms, visibility 0ms ease 300ms;
    }

    &--file &__x:before,  &__x:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        top: 50%;
        left: 50%;
        background: var(--file-x-color);
        transition: background 300ms;

        @media screen and (max-width: 600px) { width: 20px; }
    } 

    &--file &__x:before {
        transform: translate(-50%, -50%) rotate(45deg);
    } 

    &--file &__x:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    } 
}

.field {
    &--file[data-file]:not([data-file=""]) &__label {
        pointer-events: none;
    }

    &--file[data-file]:not([data-file=""]) &__svg {
        opacity: 0;
    }

    &--file[data-file]:not([data-file=""]) &__x {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &--file &__label:hover &__text {
        color: var(--file-over-color);
    }

    &--file &__label:hover &__svg {
        fill: var(--file-svg-over-color);
    }

    &--file &__x:hover:before, &--file &__x:hover:after {
        background: var(--file-x-over-color);
    }
}