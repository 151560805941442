//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.field--select {
    --tail-label-padding: 20px 40px 20px 0;
    --tail-label-font-family: var(--font-family-1);
    --tail-label-font-size: 1.7rem;
    --tail-label-font-weight: normal;
    --tail-label-line-height: 1.5;
    --tail-label-text-transform: inherit;
    --tail-label-letter-spacing: inherit;

    --tail-label-color: var(--primary-color);
    --tail-label-color-active: var(--primary-rgb-color);
    --tail-label-background: transparent;
    --tail-label-background-active: transparent;
    --tail-label-background-hover: transparent;
    --tail-label-border: 1px solid var(--primary-color);
    --tail-label-border-active: 1px solid var(--primary-rgb-color);
    --tail-label-transition: background 500ms, border-color 500ms;

    --tail-label-pointer-color: var(--secondary-color);
    --tail-label-pointer-color-active: var(--secondary-color);
    
    --tail-option-padding: 20px 40px;
    --tail-option-font-family: var(--font-family-1);
    --tail-option-font-size: 1.7rem;
    --tail-option-font-weight: 400;
    --tail-option-line-height: 1.5;
    --tail-option-text-transform: inherit;
    --tail-option-letter-spacing: inherit;

    --tail-option-color: var(--primary-color);
    --tail-option-background: transparent;
    
    --tail-option-color-hover: var(--primary-color);
    --tail-option-background-hover: var(--secondary-light-color);

    --tail-option-color-selected: var(--secondary-color);
    --tail-option-background-selected: var(--secondary-light-color);

    --field-label-font-family: var(--font-family-1);
    --field-label-font-size: 1.5rem;
    --field-label-font-weight: normal;
    --field-label-line-height: 1;
    --field-label-text-transform: inherit;
    --field-label-letter-spacing: inherit;
    --field-label-color: var(--primary-color);
}


// ------------------
// Un menu déroulant
// ------------------
.field {
    &--select &__label {
        display: block;
        padding: var(--field-label-padding);
        font-family: var(--field-label-font-family);
        font-size: var(--field-label-font-size);
        font-weight: var(--field-label-font-weight);
        line-height: var(--field-label-line-height);
        color: var(--field-label-color);
    }

    &--select .tail-select {
        position: relative;
        z-index: 1;
        
        svg {
            position: absolute;
            width: 10px;
            height: 18px;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: transform 500ms, fill 500ms;
            stroke: var(--tail-label-pointer-color);
            stroke-width: 2px;
            fill: none;
        }
    }
    
    &--category .tail-select { 
        svg { 
            stroke: var(--primary-color); 
            right: 40px;

            @media screen and (max-width: 768px) { right: 30px; }
        } 
    }

    &--select .select-label {
        padding: var(--tail-label-padding);
        border: 0;
        border-bottom: var(--tail-label-border);
        background: var(--tail-label-background);
        transition: var(--tail-label-transition);
        cursor: pointer;
        user-select: none;
        
        &:hover { background: var(--tail-label-background-hover); }
    }

    &--category .select-label { 
        border: var(--tail-label-border); 
        transition: border 2000ms;
        border-radius: 100px;
        padding-right: 60px;
        padding-left: 40px;

        @media screen and (max-width: 768px) { padding-right: 30px; padding-right: 50px; }
    }

    &--select .label-inner {
        display: block;
        position: relative;
        font-family: var(--tail-label-font-family);
        font-size: var(--tail-label-font-size);
        font-weight: var(--tail-label-font-weight);
        line-height: var(--tail-label-line-height);
        text-transform: var(--tail-label-text-transform);
        letter-spacing: var(--tail-label-letter-spacing);
        color: var(--tail-label-color);
    }

    &--category .label-inner { 
        font-size: 1.4rem;
        font-weight: 600; 
    }

    &--select .tail-select.no-classes svg { transform: translateY(-50%) rotate(90deg) }

    &--select .select-dropdown {
        padding-top: 65px;
        position: absolute;
        width: 100%;
        top: 0;
        left: -40px;
        right: -40px;
        opacity: 0;
        background: #fff;
        border-top: 1px solid var(--color8);
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 500ms var(--ease-in-out), visibility 0ms ease 500ms;
        box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.04);
        -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.04);
        -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.04);
        width: auto;
    }

    &--category .select-dropdown {
        border-radius: 30px;
        right: 0;
        left: 0;
    }

    &--select .dropdown-inner {
        overflow: auto;
        max-height: 0;
        transition: max-height 500ms var(--ease-in-out);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    &--category .dropdown-inner {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    &--category .dropdown-inner::-webkit-scrollbar { display: none; }

    &--select .dropdown-option {
        padding: var(--tail-option-padding);
        font-family: var(--tail-option-font-family);
        font-size: var(--tail-option-font-size);
        font-weight: var(--tail-option-font-weight);
        line-height: var(--tail-option-line-height);
        text-transform: var(--tail-option-text-transform);
        letter-spacing: var(--tail-option-letter-spacing);
        color: var(--tail-option-color);
        background: var(--tail-option-background);
        cursor: pointer;
        transition: background 300ms, color 300ms;
        user-select: none;
        
        &:hover {
            color: var(--tail-option-color-hover);
            background: var(--tail-option-background-hover);
        }
        
        &.selected {
            color: var(--tail-option-color-selected);
            background: var(--tail-option-background-selected);
        }
    }

    &--category .dropdown-option {
        font-size: 1.4rem;
        font-weight: 600;
    }

    // Select natif
    &--select select {
        width: 100%; 
        padding: var(--tail-label-padding);
        font-family: var(--tail-label-font-family);
        font-size: var(--tail-label-font-size);
        font-weight: var(--tail-label-font-weight);
        line-height: var(--tail-label-line-height);
        text-transform: var(--tail-label-text-transform);
        letter-spacing: var(--tail-label-letter-spacing);
        color: var(--tail-label-color);
        border: none;
        border-bottom: var(--tail-label-border);
        background: transparent;
        outline: none;
        border-radius: 0;
        -webkit-appearance: none;
        background: url('../medias/images/icons/chevron-select-natif.svg') no-repeat calc(100% - 20px) 50% / 12px 12px;
    }
    
    &--category select {
        font-size: 1.4rem;
        font-weight: 600; 
        border: var(--tail-label-border); 
        border-radius: 100px;
        padding-right: 40px;
        padding-left: 40px;
        background: url('../medias/images/icons/chevron-category.svg') no-repeat calc(100% - 40px) 50% / 12px 12px;

        @media screen and (max-width: 768px) { padding-right: 30px; padding-left: 30px; background-position-x: calc(100% - 30px); }
        @media screen and (max-width: 600px) { padding-right: 25px; padding-left: 25px; background-position-x: calc(100% - 25px); }
        @media screen and (max-width: 500px) { padding: 15px 20px; background-position-x: calc(100% - 20px); }
    }

    select::-ms-expand { display: none; }

    &--select .tail-select.active {

        svg {
            transform: translateY(-50%) rotate(-90deg);
            stroke: var(--tail-label-pointer-color-active);
        }

        .select-label {
            background: var(--tail-label-background-active);
            border-bottom: 1px solid rgba($color: var(--tail-label-color-active), $alpha: 0.5);
        }
        .label-inner{
            color: rgba($color: var(--tail-label-color-active), $alpha: 0.5);
        }
        .select-dropdown {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transition: opacity 500ms var(--ease-in-out);
        }
        .dropdown-inner {
            max-height: 195px!important;
        }
    }

    &--category .tail-select.active {

        svg {
            stroke: var(--primary-color);
        }

        .select-label {
            border: 1px solid #fff;
            transition: border 300ms;
            border-radius: 30px;
            height: 63px;
        }
    }
}

