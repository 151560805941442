.first-loading {
    display: none;
}

.default-transition {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    
    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        background: var(--primary-light-color);
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    }
} 