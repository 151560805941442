// ----------
// La page signataires
// ----------

.page--signataires {
    $prefix: ".page";

    #{$prefix}__container--block-intro {
        padding: var(--y-spacing-120) var(--x-spacing-200);
        margin-bottom: var(--y-spacing-100);

        @media screen and (max-width: 768px)  { padding: var(--y-spacing-120) 0; }
    }
}
