.pagination {
    
    &__container {
        padding: 0 50px;
        margin-bottom: var(--y-spacing-140);
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 500px) { padding: 0; }
    }

    &__prev, &__next {
        width: 20px;
        height: 16px;
    }

    .inactive {
        pointer-events: none;

        svg { 
            opacity: 0.5; 
            cursor: auto;
        }
    }

    &__prev { transform: rotate(180deg); }

    &__prev-svg, &__next-svg {
        width: 100%;
        height: 100%;
        stroke: var(--primary-color);
        fill: none;
        transition: stroke 300ms var(--ease-in-out), fill 300ms var(--ease-in-out);
        cursor: pointer;

        &:hover { stroke: var(--secondary-color); }
    }

    &__pages-list {
        padding: 0 60px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 600px) { padding: 0 25px; }
    }

    &__page {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
        }

        &--active {
            background: var(--primary-color);

            .pagination__page-link {
                color: #fff;
                pointer-events: none;
                cursor: auto;
            }
        }

        &:last-child() { margin-right: 0; }

        &.dots {
            width: 20px;
            min-width: 20px;
        }
    }

    &__page-link {
        font-family: var(--font-family-1);
        font-size: 1.7rem;
        color: var(--primary-color);
        transition: color 300ms var(--ease-in-out);
        text-align: center;
        position: absolute;
        line-height: 40px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:hover { color: var(--secondary-color); }

        @media screen and (max-width: 400px) { line-height: 30px; }
    }
}

.pagination-variant {
    &__container {
        padding-right: var(--x-spacing-200);
        padding-bottom: var(--y-spacing-100);
        padding-left: var(--x-spacing-200);
    }

    &__grid {
        display: flex;
        justify-content: space-between;
        padding: 40px 50px;
        border-top: 1px solid var(--primary-color);

        @media screen and (max-width: 1024px) { padding: 40px 0; }
        @media screen and (max-width: 600px) { flex-wrap: wrap; }
    }
    
    &__content {
        display: flex;
        justify-content: space-between;
        width: 320px;

        @media screen and (max-width: 768px) { width: 300px; }
        @media screen and (max-width: 600px) { width: 100%; margin-top: 20px; }
    }

    &__back, &__prev, &__next {
        font-family: var(--font-family-1);
        font-size: 1.7rem;
        color: var(--primary-color);
        transition: color 300ms var(--ease-in-out);
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover { color: var(--secondary-color); }

        span { line-height: 14px; }
    }

    &__back-svg, &__prev-svg, &__next-svg {
        width: 20px;
        height: 16px;
        stroke: var(--primary-color);
        fill: none;
        position: relative;
        top: -1px;
        transition: stroke 300ms var(--ease-in-out);
        cursor: pointer;
    }

    &__prev:hover &__prev-svg { stroke: var(--secondary-color); }
    &__next:hover &__next-svg { stroke: var(--secondary-color); }

    &__back-svg, &__prev-svg { 
        transform: rotate(180deg);
        margin-right: 20px; 
    }

    &__next-svg { margin-left: 20px; }

    .inactive { 
        opacity: 0.5;
        cursor: auto;
        pointer-events: none;
    }
}