//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.menu-button {

}


//----------------
// Bouton du menu
//----------------
.menu-button {
    position: relative;
    width: 36px;
    height: 18px;

    @media screen and (max-width: 1024px) { width: 34px; }

    &__lines {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__line {
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background: var(--primary-color);
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: 300ms;
    }

    &:hover &__line {
        background: var(--secondary-color);
    }
    
    &__line--1 { top: 0px; }
    &__line--2, &__line--3 { top: 50%; }
    &__line--4 { bottom: 0px; }
}

.menu-button--light {
    .menu-button__line {
        background: var(--primary-light-color);
    }
}