//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.title-next-to-text {

}


// ------------------------------------------------------
// Un titre à gauche et un texte avec un bouton à droite
// ------------------------------------------------------
.title-next-to-text {
    &__container {
        display: flex;
        align-items: center;
        padding: 0 var(--x-spacing-110);

        @media screen and (max-width: 850px) { flex-wrap: wrap; }
    }

    &__title-item {
        position: relative;
        width: 50%;

        @media screen and (max-width: 850px) { width: 100%; }
    }
    
    &__shape {
        position: absolute;
        width: 260px;
        height: 260px;
        top: 30px;
        left: -70px;
        fill: var(--secondary-light-color);
        
        @media screen and (max-width: 1200px) { width: 220px; height: 220px; left: -60px; }
        @media screen and (max-width: 1024px) { width: 180px; height: 180px; left: -30px; }
        @media screen and (max-width: 850px)  { width: 150px; height: 150px; left: -20px; top: -10px; }
        @media screen and (max-width: 500px)  { width: 110px; height: 110px; left: -10px; top: 0px; }
    }
    
    &__title {
        position: relative;
        
        &--bold {
            font-weight: 700;
        }
    }
    
    &__text-item {
        display: flex;
        position: relative;
        justify-content: flex-end;
        width: 50%;

        @media screen and (max-width: 850px) {
            width: 100%;
            justify-content: flex-start;
            margin-top: 50px;
        }
        @media screen and (max-width: 850px) { margin-top: 40px; }
    }

    &__content {
        max-width: 300px;

        @media screen and (max-width: 850px) { max-width: 500px; }
    }

    &__text {
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.5;
        color: var(--primary-color);
    }

    &__link {
        margin-top: 50px;

        @media screen and (max-width: 1200px) { margin-top: 40px; }
        @media screen and (max-width: 1024px) { margin-top: 30px; }
        @media screen and (max-width: 768px) { margin-top: 20px; }
    }
}