// ------------
// Les layouts
// ------------
.layout {

}

.main {
    background-color: white;
    position: relative;

    &__share{
        margin-left: auto;
        padding-left: 30px;
        @media screen and (max-width: 768px) { display: none; }
    }

    &__content{
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        padding: 0 var(--x-spacing-200);
        margin: var(--y-spacing-100) 0;
        position: relative;
    }

    [data-fire] &__content {
        opacity: 1;
        transform: none;
    }

    &__gallery,
    &__rubrics,
    &__documents,
    &__form{
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        padding: 0 var(--x-spacing-200);
        margin: var(--y-spacing-100) 0;
        background: white;

        .main__title{
            font-weight: 700;
            color: var(--primary-color);
            margin-top: var(--y-spacing-50);
            margin-bottom: var(--y-spacing-60);

            &:first-child{
                padding-top: 0;
            }
        }
    }

    [data-fire] &__gallery,
    [data-fire] &__rubrics,
    [data-fire] &__documents,
    [data-fire] &__form {
        opacity: 1;
        transform: none;
    }

    &__documents {
        &:not(:last-child) {
            padding-bottom: 50px;
        }
    }

    &__documents-desc{
        margin-top: -20px;
        margin-bottom: var(--y-spacing-80);
    }

    &__components{
        h2{
            font-family: var(--font-family-2);
            font-size: 5rem;
            line-height: 1.15;
            color: var(--primary-color);
            margin-bottom: var(--y-spacing-50);

            @media screen and (max-width: 1400px) { font-size: 4.8rem; }
            @media screen and (max-width: 1300px) { font-size: 4.5rem; }
            @media screen and (max-width: 1200px) { font-size: 4.3rem; }
            @media screen and (max-width: 1024px) { font-size: 4.2rem; }
            @media screen and (max-width: 768px)  { font-size: 4rem; }
            @media screen and (max-width: 600px)  { font-size: 3.8rem; }
            @media screen and (max-width: 500px)  { font-size: 3.5rem; }
            @media screen and (max-width: 450px)  { font-size: 3.2rem; }
            @media screen and (max-width: 400px)  { font-size: 3rem; }
        }
    }
}

.show-back-to-top { 
    
    .back-to-top {
        opacity: 1;

        &__button { pointer-events: all; }
    }
}