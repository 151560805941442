//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.title-on-shape {
    
}


// -------------------------------------
// Les actualités sur la page d'accueil
// -------------------------------------
.title-on-shape {
    &__container {
        position: relative;
        height: 600px;

        @media screen and (max-width: 1200px) { height: 550px; }
        @media screen and (max-width: 1024px) { height: 500px; }
        @media screen and (max-width: 768px)  { height: 450px; }
        @media screen and (max-width: 600px)  { height: 350px; }
        @media screen and (max-width: 500px)  { height: 300px; }
    }

    &__shape-container {
        position: absolute;
        width: 65%;
        max-width: 600px;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 600px) { max-width: 400px; }
    }

    &__shape {
        position: absolute;
        height: inherit;
        top: -20px;
        left: 0;
        fill: var(--secondary-light-color);
        transform: translateX(-50%);
    }
    
    &__content {
        position: absolute;
        padding: 0 var(--x-spacing-110);
        top: calc(50% + 50px);
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 100%;
        opacity: 0;
        transition: opacity 800ms, top 800ms var(--ease-out);
    }

    &__content[data-fire] {
        opacity: 1;
        top: 50%;
    }

    &__title {
        br { @media screen and (max-width: 600px) { display: none; }}
    }

    &__button {
        margin-top: 40px;
    }
}