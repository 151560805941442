//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.small-title {

}


// ---------------
// Un small title
// ---------------
.small-title {
    font-family: var(--font-family-1);
    font-size: 2.5rem;
    line-height: 1.15;
    color: var(--primary-color);
    
    @media screen and (max-width: 1400px) { font-size: 2.3rem; }
    @media screen and (max-width: 1024px) { font-size: 2.2rem; }
    @media screen and (max-width: 768px) { font-size: 2rem; }

    &--bold { font-weight: 700; }
}