//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.banner {

}


// ----------------------
// La bannière d'accueil
// ----------------------
.banner {
    &__container {
        position: relative;
        height: calc(100vh - var(--header--height));
        min-height: 550px;

        @media screen and (max-width: 1024px) { height: 500px; min-height: 500px; }
        @media screen and (max-width: 800px) {
            padding: 50px var(--x-spacing-110);
            height: 100%;
            min-height: 600px;
        }
    }

    // La shape
    &__layer {
        &--shape {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 0;
        }
    }

    &__img {
        &--shape {
            width: 950px;
            fill: var(--secondary-light-color);
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;

            @media screen and (max-height: 800px), screen and (max-width: 1400px) { width: 850px; }
            @media screen and (max-height: 750px), screen and (max-width: 1300px) { width: 750px; }
            @media screen and (max-height: 650px), screen and (max-width: 1200px) { width: 650px; }
            @media screen and (max-width: 1024px) { width: 550px; }
            @media screen and (max-width: 950px)  { width: 500px; }
            @media screen and (max-width: 800px)  { width: 900px; }
            @media screen and (max-width: 650px)  { width: 800px; }
            @media screen and (max-width: 550px)  { width: 650px; }
            @media screen and (max-width: 400px)  { width: 500px; }
        }
    }

    [data-fire] &__img--shape {
        opacity: 1;
        transform: none;
    }

    // Le contenu
    &__layer {
        &--content {
            display: flex;
            position: relative;
            flex-direction: column;
            height: 100%;
            padding-bottom: 60px;
            padding-left: var(--x-spacing-110);
            justify-content: center;
            z-index: 2;
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 800ms linear 200ms, transform 800ms var(--ease-out) 200ms;

            @media screen and (max-width: 800px) { padding: 0 0 50px; } 
            @media screen and (max-width: 600px) { padding-bottom: 40px; }
            @media screen and (max-width: 450px) { padding-bottom: 30px; }

            .huge-title {
                font-size: 10.5rem;
                @media screen and (max-width: 1800px) { font-size: 9.5rem; }
                @media screen and (max-width: 1700px) { font-size: 8.8rem; }
                @media screen and (max-width: 1500px) { font-size: 8.1rem; }
                @media screen and (max-width: 700px) { font-size: 6.2rem; }
                @media screen and (max-width: 500px) { font-size: 5rem; }
                @media screen and (max-width: 400px) { font-size: 4.4rem; }
            }
        }
    }

    [data-fire] &__layer--content {
        opacity: 1;
        transform: none;
    }

    &__title {
        max-width: 775px;

        @media screen and (max-width: 1800px) { max-width: 700px; }
        @media screen and (max-width: 1700px) { max-width: 650px; }
        @media screen and (max-width: 1500px) { max-width: 600px; }

        &--bold {
            font-weight: 700;
        }
    }

    &__button {
        &--play {
            margin-top: 45px;
            display: inline-flex;
            align-items: center;

            @media screen and (max-width: 1200px) { margin-top: 50px; }
            @media screen and (max-width: 1024px) { margin-top: 40px; }
            @media screen and (max-width: 768px)  { margin-top: 30px; }
            @media screen and (max-width: 600px)  { margin-top: 25px; }
            @media screen and (max-width: 500px)  { margin-top: 20px; }

            .play-button {
                @media screen and (max-width: 768px) { width: 40px; height: 40px; }
                @media screen and (max-width: 600px) { width: 34px; height: 34px; }

                &:before {
                    @media screen and (max-width: 768px) { border-width: 5px 0 5px 8.7px; }
                }
            }
        }
    }

    &__button--play:hover &__icon--play {
        background: var(--secondary-color);
    }

    &__text {
        &--play {
            margin-left: 15px;
            font-family: var(--font-family-1);
            font-size: 1.7rem;
            font-weight: 700;
            color: var(--primary-color);
            transition: color 300ms;

            @media screen and (max-width: 1024px) { font-size: 1.6rem; }
            @media screen and (max-width: 768px) { font-size: 1.5rem; }
            @media screen and (max-width: 600px) { font-size: 1.4rem; }
        }
    }
    
    &__button--play:hover &__text--play {
        color: var(--secondary-color);
    }

    // La vidéo et l'image
    &__layer {
        &--circle {
            position: absolute;
            width: 650px;
            height: 650px;
            top: 50%;
            right: var(--x-spacing-110);
            border-radius: 50%;
            overflow: hidden;
            opacity: 0;
            transform: translateY(-50%) scale(0);
            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
            transition: opacity 800ms linear 300ms, transform 800ms var(--ease-out) 300ms;

            @media screen and (max-height: 850px), screen and (max-width: 1500px) { width: 620px; height: 620px; }
            @media screen and (max-height: 800px), screen and (max-width: 1300px) { width: 540px; height: 540px; }
            @media screen and (max-height: 750px) { width: 480px; height: 480px; }
            @media screen and (max-height: 700px), screen and (max-width: 1100px) { width: 480px; height: 480px; }
            @media screen and (max-width: 1024px) { width: 400px; height: 400px; }
            @media screen and (max-width: 800px) {
                position: relative;
                width: 70%;
                height: 100%;
                padding-top: 70%;
                top: 0;
                right: 0;
                transform: scale(0);
            }
            @media screen and (max-width: 650px) {
                width: 80%;
                padding-top: 80%;
            }
            @media screen and (max-width: 550px) {
                width: 100%;
                padding-top: 100%;
            }
        }

        &--circle:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(var(--primary-light-rgb-color), 0.1);
            z-index: 1;
        }
    }

    [data-fire] &__layer--circle {
        opacity: 1;
        transform: translateY(-50%);

        @media screen and (max-width: 800px) { transform: none; }
    }

    &__picture,
    &__img {
        &--circle {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__video {
        &--circle {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }
    }

    // La navigation dans le bas
    &__layer {
        &--bottom {
            position: absolute;
            bottom: 50px;
            left: var(--x-spacing-110);
            z-index: 2;
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 800ms linear 400ms, transform 800ms var(--ease-out) 400ms;

            @media screen and (max-width: 800px) { display: none; }
        }
    }

    [data-fire] &__layer--bottom {
        opacity: 1;
        transform: none;
    }

    // Le boutton de scroll
    &__button {
        &--scrolldown {
            display: inline-flex;
            align-items: center;
            margin-right: 60px;

            @media screen and (max-width: 1200px) { margin-right: 50px; }
            @media screen and (max-width: 1024px) { margin-right: 40px; }
            @media screen and (max-width: 768px)  { margin-right: 30px; }
        }
    }

    &__text {
        &--scrolldown {
            font-family: var(--font-family-1);
            font-size: 1.7rem;
            line-height: 1.5;
            color: var(--primary-color);
            transition: box-shadow 300ms, color 300ms;
        }
    }
            
    &__button--scrolldown:hover &__text--scrolldown {
        color: var(--secondary-color);
        box-shadow: inset 0 -1px 0 0 var(--secondary-color);
    }

    &__icon {
        &--scrolldown {
            margin-left: 15px;
            width: 20px;
            height: 20px;
            fill: none;
            stroke: var(--primary-color);
            transform: rotate(90deg);
            transition: stroke 300ms;
        }
    }
            
    &__button--scrolldown:hover &__icon--scrolldown {
        stroke: var(--secondary-color);
    }
}