// Petite modification ligne svg page Accueil
.page--home .page__container--line .page__line{
    @media screen and (min-width: 1025px){
        top: 70%;
    }
}

// Section municipalités engagés
.engagedMunicipalities{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--y-spacing-190) 0;
    &__title{
        font-size: 17px;
        font-family: var(--font-family-1);
        font-weight: bold;
        color: var(--primary-color);
        margin-bottom: 60px;
    }
    &__container{
        overflow: hidden;
        display: flex;
        width: 100%;
        &:hover{
            .engagedMunicipalities__txt{
                animation-play-state: paused;
            }
        }
    }
    &__txt{
        font-size: 80px;
        font-family: var(--font-family-2);
        white-space: nowrap;
        color: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 100%;
        animation: scrollTxtCities 30s linear infinite;
        > a{
            color: var(--primary-color);
            &:hover {
                opacity: 0.5;
            }
        }
    }
    &__dot{
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        background-color: var(--primary-color);
        margin: auto 30px;
    }
    > .button{
        margin-top: 60px;
    }
    @media screen and (min-width: 769px){
        padding-bottom: 0;
    }
}


@keyframes scrollTxtCities {
    0%{
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}



