
.page-title {
    
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__container--with-inside-image { 
        .page-title__content { 
            justify-content: space-between; 
            align-items: center;
        } 

        .page-title__picture-inside {
            margin-top: 90px;

            @media screen and (max-width: 900px) { margin-top: 0; }
            @media screen and (max-width: 900px) { display: none; }
        }

        .page-title__picture {
            display: none;

            @media screen and (max-width: 900px) { display: block; }
        }

        .page-title__line { 
            @media screen and (max-width: 900px) { 
                width: 700px;
                right: 0px;
                bottom: -250px;
            }
            @media screen and (max-width: 600px) { 
                width: 400px;
                right: 0px;
                bottom: -140px;
            }
        }

        .page-title__picture-container {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
        }

        .page-title__picture { 
            position: relative;
            max-width: 100%;
            max-height: 100%;
            padding-top: 148%;
        }

        .page-title__img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__container:not(.page-title__container--with-inside-image) &__content {
        padding-bottom: var(--y-spacing-110); 
        padding-top: var(--y-spacing-110);
    }
    
    &__content {
        min-height: 440px;
        padding-left: var(--x-spacing-120);
        display: flex;
        overflow: hidden;
        position: relative;

        &--variant { min-height: 560px; }

        @media screen and (max-width: 900px) { 
            min-height: 0;
            padding-top: var(--y-spacing-140);
            padding-bottom: var(--y-spacing-140);

            &--variant { min-height: 0; }
        }
    }

    // Côté gauche
    &__text {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        max-width: 725px;
        padding-right: 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        z-index: 2;
    }

    [data-fire] &__text {
        opacity: 1;
        transform: none;
    }

    &__surtitle {
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--secondary-color);
        text-transform: uppercase;
        margin-bottom: 20px;

        @media screen and (max-width: 400px) { 
            font-size: 1.1rem;
            margin-bottom: 15px; 
        }

        &--date { font-weight: 700; }
    }

    &__title { 
        font-weight: 700;
        margin-bottom: 60px; 

        &:last-child() { margin-bottom: 0; }

        @media screen and (max-width: 900px) { margin-bottom: 30px; }
        @media screen and (max-width: 400px) { margin-bottom: 15px; }
    }

    .smaller { 
        font-size: 7rem; 

        @media screen and (max-width: 1400px) { font-size: 6.5rem; }
        @media screen and (max-width: 1300px) { font-size: 6rem; }
        @media screen and (max-width: 1200px) { font-size: 5.5rem; }
        @media screen and (max-width: 1024px) { font-size: 5rem; }
        @media screen and (max-width: 768px)  { font-size: 4.5rem; }
        @media screen and (max-width: 600px)  { font-size: 4rem; }
        @media screen and (max-width: 500px)  { font-size: 3.5rem; }
        @media screen and (max-width: 400px)  { font-size: 3.2rem; }
    }

    .much-smaller {
        font-size: 5rem;

        @media screen and (max-width: 768px)  { font-size: 4.5rem; }
        @media screen and (max-width: 600px)  { font-size: 4rem; }
        @media screen and (max-width: 500px)  { font-size: 3.5rem; }
        @media screen and (max-width: 400px)  { font-size: 3.2rem; }
    }

    &__button {
        display: inline-flex;
        align-items: center;
        margin-right: 60px;

        @media screen and (max-width: 1200px) { margin-right: 50px; }
        @media screen and (max-width: 1024px) { margin-right: 40px; }
        @media screen and (max-width: 768px)  { margin-right: 30px; }
    }

    &__button-text {
        font-family: var(--font-family-1);
        font-size: 1.7rem;
        line-height: 1.5;
        color: var(--primary-color);
        transition: box-shadow 300ms, color 300ms;
    }

    &__button:hover &__button-text {
        color: var(--secondary-color);
        box-shadow: inset 0 -1px 0 0 var(--secondary-color);
    }

    &__button-icon {
        margin-left: 15px;
        width: 20px;
        height: 20px;
        fill: none;
        stroke: var(--primary-color);
        transform: rotate(90deg);
        transition: stroke 300ms;
    }

    &__button:hover &__button-icon { stroke: var(--secondary-color); }


    // Côté droit
    &__picture-inside-container {
        padding-right: 90px;
        margin-left: 30px;
        width: 480px;
        min-width: 480px;

        @media screen and (max-width: 1024px) { width: 400px; min-width: 400px; }
        @media screen and (max-width: 900px) { display: none; }
    }

    &__picture-inside {
        padding-top: 148%;
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        position: relative;
        z-index: -2;

        .credit {
            position: absolute;
            left: 0;
            bottom: 0;
            color: white;
            font-size: 1.5rem;
            padding: 10px 25px;
            background-color: rgba(#0c4654, 0.6);
            font-family: var(--font-family-1);
            z-index: 10;
        }

        &.videoThumb {
            &::after{
                content: '';
                background-color: #000;
                opacity: 50%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                opacity: 0.3;
                transition: opacity 0.3s ease-in;
            }
            
            & > div.arrow{
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100px;
                height: 100px;
                transform: translate(-50%,-50%);
                border: solid 1px #fff;
                z-index: 1;
                opacity: 1;
                border-radius: 50%;
                transition: all 0.3s;
                
                span{
                    margin: -8px 0 0 -4px;
                    border-width: 8px 0 8px 13.9px;
                    border-color: transparent transparent transparent #fff;

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    z-index: 1;
                    transition: border-color 500ms;
                }

            }
        }
    }

    &__img-inside {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    [data-fire] &__picture-inside {
        opacity: 1;
        transform: none;
    }

    &__shape-container {
        width: 750px;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 0;
        overflow: hidden;
        z-index: 1;
    }

    &__shape {
        width: 520px;
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        stroke: var(--secondary-light-color);
        fill: var(--secondary-light-color);
        position: absolute;
        right: 0;
        bottom: -50px;
        z-index: -3;

        @media screen and (max-width: 900px) { 
            width: 420px; 
            right: -20px;
            bottom: -1px;
        }
        @media screen and (max-width: 600px) { 
            width: 320px; 
            bottom: -20px;
        }
        @media screen and (max-width: 500px) { 
            width: 220px; 
            bottom: -1px;
        }
        @media screen and (max-width: 400px) { 
            width: 175px; 
            right: 0;
            bottom: -20px;
        }

        &--variant {
            stroke: var(--other-4);
            fill: var(--other-4);
        }
    }

    [data-fire] &__shape {
        opacity: 0.5;
        transform: none;
    }

    &__line { 
        width: 1564px;
        fill: none;
        stroke: var(--secondary-color); 
        stroke-width: 2px;
        position: absolute;
        right: -120px;
        bottom: -500px;
        z-index: -1;

        @media screen and (max-width: 900px) {
            width: 1000px;
            right: -50px;
            bottom: -300px;
        }

        @media screen and (max-width: 800px) { stroke-width: 4px; }

        @media screen and (max-width: 600px) {
            width: 800px;
            right: -100px;
            bottom: -250px;
        }

        @media screen and (max-width: 500px) {
            width: 700px;
            right: -125px;
            bottom: -225px;
        }

        @media screen and (max-width: 400px) { 
            width: 600px;
            right: -100px;
            bottom: -240px;
        }
    }

    // Image (s'il y a lieu)
    &__picture {
        position: relative; 
        padding-top: 52%;
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;

        @media screen and (max-width: 1400px) {
            height: auto;
            max-height: 800px;
        }

        .credit {
            position: absolute;
            left: 0;
            bottom: 0;
            color: white;
            font-size: 1.5rem;
            padding: 10px 25px;
            background-color: rgba(#0c4654, 0.6);
            font-family: var(--font-family-1);
            z-index: 10;
        }

        &.videoThumb {
            &::after{
                content: '';
                background-color: #000;
                opacity: 50%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                opacity: 0.3;
                transition: opacity 0.3s ease-in;
            }
            
            & > div.arrow{
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100px;
                height: 100px;
                transform: translate(-50%,-50%);
                border: solid 1px #fff;
                z-index: 1;
                opacity: 1;
                border-radius: 50%;
                transition: all 0.3s;
                
                span{
                    margin: -8px 0 0 -4px;
                    border-width: 8px 0 8px 13.9px;
                    border-color: transparent transparent transparent #fff;

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    z-index: 1;
                    transition: border-color 500ms;
                }

            }
        }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    [data-fire] &__picture {
        opacity: 1;
        transform: none;
    }

    &--variant-1 {
        .page-title__container { background: var(--primary-color); }

        .page-title__surtitle, .page-title__title, .page-title__button-text { color: #fff; }
        
        .page-title__button-icon { stroke: #fff; }
    }
}