//--------------------
// Variables globales
//--------------------
:root {
    --header__container--padding-top: 40px;
    @media screen and (max-width: 1200px) { --header__container--padding-top: 35px; }
    @media screen and (max-width: 1024px) { --header__container--padding-top: 30px; }
    @media screen and (max-width: 768px)  { --header__container--padding-top: 25px; }
    @media screen and (max-width: 600px)  { --header__container--padding-top: 20px; }
    --header__logo-img--height: 100px;
    @media screen and (max-width: 1200px) { --header__logo-img--height: 85px; }
    @media screen and (max-width: 1024px) { --header__logo-img--height: 75px; }
    @media screen and (max-width: 768px)  { --header__logo-img--height: 65px; }
    @media screen and (max-width: 500px)  { --header__logo-img--height: 58px; }
    --header--height: calc(var(--header__container--padding-top) + var(--header__logo-img--height));
}


//-------------------
// Variables locales
//-------------------
.header {

}


// ----------
// Le header
// ----------
.header {
    position: relative;
    height: var(--header--height);
    z-index: 2;

    &__container {
        display: flex;
        padding: var(--header__container--padding-top) var(--x-spacing-100) 0;
        align-items: center;
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms, transform 800ms var(--ease-out);
    }

    [data-fire] &__container {
        opacity: 1;
        transform: none;
    }

    &__logo-img {
        height: var(--header__logo-img--height);
        
    }

    &__links-item {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
    }

    &__link {
        position: relative;
        margin-right: 40px;

        @media screen and (max-width: 1200px) { margin-right: 30px; }
        @media screen and (max-width: 1024px) { margin-right: 25px; }
        @media screen and (max-width: 900px)  { display: none; }

        &--count {
            padding-right: 25px;
        }
    }

    &__text {
        padding: 3px 0;
        font-family: var(--font-family-1);
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.3;
        color: var(--primary-color);
        transition: box-shadow 300ms, color 300ms;

        @media screen and (max-width: 1024px) { font-size: 1.4rem; }
    }

    &__link:hover &__text {
        color: var(--secondary-color);
        box-shadow: inset 0 -1px 0 0 var(--secondary-color);
    }

    &__count {
        position: absolute;
        width: 21px;
        height: 21px;
        top: -11px;
        right: 0;
        font-family: var(--font-family-1);
        font-size: 1rem;
        font-weight: 700;
        line-height: 21px;
        color: var(--primary-light-color);
        text-align: center;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: 0;
            left: 0;
            background: var(--primary-color);
            border-radius: 50%;
            z-index: -1;
            transition: background-color 300ms;
        }
    }
    
    &__link:hover &__count:before {
        background: var(--secondary-color);
    }

    &__button {
        margin-right: 30px;

        @media screen and (max-width: 1024px) { margin-right: 25px; }
        @media screen and (max-width: 500px)  { display: none; }
    }
}

.header {
    &--light { 
        background-color: var(--primary-color); 
    }

    &--light &__text {
        color: var(--primary-light-color);
    }

    &--light &__link:hover &__text {
        color: var(--secondary-color);
        box-shadow: inset 0 -1px 0 0 var(--secondary-color);
    }

    &--light &__count {
        color: var(--primary-color);
        transition: color 300ms;
    }
    
    &--light &__count:before {
        background-color: var(--primary-light-color);
    }

    &--light &__link:hover &__count {
        color: var(--primary-light-color);
    }

    &--light &__link:hover &__count:before {
        background: var(--secondary-color);
    }

    &--light &__button {
        @extend .button--white-hover;
    }

    &--light &__menu {
        @extend .menu-button--light;
    }
}