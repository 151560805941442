.block-intro {
    &__content-item {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms linear 100ms, transform 800ms var(--ease-out) 100ms;
        padding: 0 var(--x-spacing-170);
        padding-top: var(--y-spacing-100);

        @media screen and (max-width: 768px) { padding: 0 var(--x-spacing-110); }
    }

    [data-fire] &__content-item {
        opacity: 1;
        transform: none;
    }
    
    &__content {
        position: relative;
        margin: 0 auto;

        @media screen and (max-width: 768px) { margin-top: 60px; }
    }

    &__title {
        position: relative;
    }

    &__shape {
        position: absolute;
        width: 215px;
        top: -50px;
        left: -100px;
        fill: var(--secondary-light-color);

        @media screen and (max-width: 1400px) { width: 200px; left: -75px; }
        @media screen and (max-width: 1200px) { width: 160px; left: -50px; }
        @media screen and (max-width: 768px)  { width: 90px;  top: -30px; left: -10px; }
    }
}