.button {
    display: inline-flex;
    padding: 20px 40px;
    background: var(--secondary-color);
    align-items: center;
    border-radius: 50px;
    transition: background 300ms;

    @media screen and (max-width: 1200px) { padding: 18px 35px; }
    @media screen and (max-width: 1024px) { padding: 17px 32px; }
    @media screen and (max-width: 900px)  { padding: 16px 30px; }
    
    &:hover {
        background: var(--primary-color);
    }

    &__svg {
        width: 30px;
        height: 25px;
        fill: none;
        stroke: var(--primary-light-color);
        stroke-width: 2px;
        margin-right: 10px;
        transition: stroke 300ms;
    }

    &[target="_blank"],
    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"]{
        svg {
            content: '';
            fill: #fff;
            display: inline-block;
            margin-left: 10px;
            transition: fill 0.3s var(--ease__in-out);
            width: 13px;
            height: 20px;
            flex-shrink: 0;
        }
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"]{
        svg {
            width: 12px;
            height: 12px;
        }
    }
    
    &, 
    &__text {
        font-family: var(--font-family-1);
        font-size: 1.5rem;
        line-height: 1.5;
        font-weight: 700;
        color: var(--primary-light-color);
        transition: color 300ms, background 300ms;

        @media screen and (max-width: 1024px) { font-size: 1.4rem; }
    }
}

.button--white-hover {
    &:hover {
        background: var(--primary-light-color);
    }

    &:hover .button__svg {
        stroke: var(--secondary-color);
    }

    &:hover .button__text {
        color: var(--secondary-color);
    }
}