//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.news-list {
    --news-list__item-list--side-gap: 55px;

    @media screen and (max-width: 1600px) { --news-list__item-list--side-gap: 50px; }
    @media screen and (max-width: 1400px) { --news-list__item-list--side-gap: 40px; }
    @media screen and (max-width: 1300px) { --news-list__item-list--side-gap: 30px; }
    @media screen and (max-width: 1200px) { --news-list__item-list--side-gap: 20px; }
    @media screen and (max-width: 950px)  { --news-list__item-list--side-gap: 10px; }
    @media screen and (max-width: 500px)  { --news-list__item-list--side-gap: 0px; }
}


// -------------------------------------
// Les actualités sur la page d'actualités
// -------------------------------------
.news-list {
    &__container {
        position: relative;
        padding: 0 var(--x-spacing-110);
    }

    &__line {
        position: absolute;
        top: 250px;
        right: 0;
        left: 0;
        fill: none;
        stroke: var(--secondary-color);
        stroke-width: 2.5px;
    }

    &__title-item {
        position: relative;
        width: 50%;
        
        @media screen and (max-width: 850px) { width: 100%; }
    }

    .field {
        margin-bottom: 0;
    }

    &__shape {
        position: absolute;
        width: 260px;
        height: 260px;
        top: -30px;
        left: -70px;
        fill: var(--secondary-light-color);
        transform: rotate(60deg);
        
        @media screen and (max-width: 1200px) { width: 220px; height: 220px; left: -60px; }
        @media screen and (max-width: 1024px) { width: 180px; height: 180px; left: -30px; }
        @media screen and (max-width: 850px)  { width: 150px; height: 150px; left: -20px; top: -10px; }
        @media screen and (max-width: 500px)  { width: 110px; height: 110px; left: -10px; top: 0px; }
    }
    
    &__title {
        position: relative;
        margin-bottom: 50px;

        @media screen and (max-width: 1024px) { margin-bottom: 40px; }
        @media screen and (max-width: 768px)  { margin-bottom: 30px; }
    }

    &__form { 
        width: 260px; 

        @media screen and (max-width: 400px) { width: 100%; }
    }

    &__text {
        position: relative;
        max-width: 250px;
        margin-top: 80px;
        font-family: var(--font-family-1);
        font-size: 1.4rem;
        line-height: 1.6;
        color: var(--primary-color);

        @media screen and (max-width: 1400px) { margin-top: 70px; }
        @media screen and (max-width: 1200px) { margin-top: 60px; }
        @media screen and (max-width: 1024px) { margin-top: 50px; }
        @media screen and (max-width: 900px)  { margin-top: 40px; }
        @media screen and (max-width: 768px)  { margin-top: 30px; }
        @media screen and (max-width: 600px)  { margin-top: 20px; }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (var(--news-list__item-list--side-gap) * 2));
        margin-left: calc(0px - var(--news-list__item-list--side-gap));
        margin-right: calc(0px - var(--news-list__item-list--side-gap));

        @media screen and (max-width: 1024px) { margin-top: 50px; }
        @media screen and (max-width: 768px)  { margin-top: 60px; justify-content: flex-end; }
        @media screen and (max-width: 500px)  { margin-top: 20px;  }
    }

    &__list-item {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        margin-top: 110px;
        width: 33.33%;
        padding: 0 var(--news-list__item-list--side-gap);

        @media screen and (max-width: 1600px) { margin-top: 100px; }
        @media screen and (max-width: 1400px) { margin-top: 80px; }
        @media screen and (max-width: 1300px) { margin-top: 60px; }
        @media screen and (max-width: 1200px) { margin-top: 40px; }
        @media screen and (max-width: 950px)  { margin-top: 20px; }
        @media screen and (max-width: 768px)  { width: 50%; }
        @media screen and (max-width: 500px)  { width: 100%; }
    }

    &__list-item:nth-child(3n-1) {
        top: -170px;

        @media screen and (max-width: 1600px) { top: -150px; }
        @media screen and (max-width: 1400px) { top: -130px; }
        @media screen and (max-width: 1300px) { top: -100px; }
        @media screen and (max-width: 1200px) { top: -70px; }
        @media screen and (max-width: 1024px) { top: -40px; }
        @media screen and (max-width: 768px)  { top: 0; }
    }

    &__list-item:nth-child(3n-1),
    &__list-item:nth-child(3n) { 
        @media screen and (max-width: 768px) { top: -50px; }
        @media screen and (max-width: 500px) {
            margin-top: 30px;
            top: 0px;
        }
    }

    &__list-item:nth-child(3n) { 
        @media screen and (max-width: 768px) and (min-width: 501px) { display: none; }
    }
}