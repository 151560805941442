@import 'https://use.typekit.net/dss2fka.css';

@font-face {
    font-family: 'NoeDisplay';
    src: url('../fonts/noe-display/noe-display-regular.eot');
	src: url('../fonts/noe-display/noe-display-regular.woff2') format('woff2'),
		 url('../fonts/noe-display/noe-display-regular.woff') format('woff'),
		 url('../fonts/noe-display/noe-display-regular.ttf') format('truetype'),
		 url('../fonts/noe-display/noe-display-regular.svg#MaisonNeue-Demi') format('svg'),
		 url('../fonts/noe-display/noe-display-regular.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NoeDisplay';
    src: url('../fonts/noe-display/noedisplay-bold.eot');
	src: url('../fonts/noe-display/noedisplay-bold.woff2') format('woff2'),
		 url('../fonts/noe-display/noedisplay-bold.woff') format('woff'),
		 url('../fonts/noe-display/noedisplay-bold.ttf') format('truetype'),
		 url('../fonts/noe-display/noedisplay-bold.svg#MaisonNeue-Demi') format('svg'),
		 url('../fonts/noe-display/noedisplay-bold.eot?#iefix') format('embedded-opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}