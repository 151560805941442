// ------------------
// La page de formulaire
// ------------------

.page--form {
    $prefix: ".page";

    #{$prefix}__container--block-intro {
        padding: 0 var(--x-spacing-200);
        margin-bottom: var(--y-spacing-100);

        &:last-child() {
            margin-bottom: 0;
            padding-bottom: var(--y-spacing-100);
        }

        @media screen and (max-width: 768px) { padding: 0; }
    }
}