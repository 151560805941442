//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.medium-title {

}


// ----------------
// Un medium title
// ----------------
.medium-title {
    font-family: var(--font-family-1);
    font-size: 3rem;
    line-height: 1.15;
    color: var(--primary-color);
    
    @media screen and (max-width: 1200px) { font-size: 2.8rem; }
    @media screen and (max-width: 1024px) { font-size: 2.6rem; }
    @media screen and (max-width: 768px)  { font-size: 2.5rem; }
    @media screen and (max-width: 600px)  { font-size: 2.4rem; }

    &--bold { font-weight: 700; }
    &--white { color: var(--primary-light-color); }
}