//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.capsules-slider {
    --capsules-slider__arrow-icon--dimension: 23px;
    --capsules-slider__slick-list--side-padding: 15vw;
    @media screen and (max-width: 1600px) { --capsules-slider__slick-list--side-padding: 13vw; }
    @media screen and (max-width: 1400px) { --capsules-slider__slick-list--side-padding: 12vw; }
    @media screen and (max-width: 1200px) { --capsules-slider__slick-list--side-padding: 11vw; }
    @media screen and (max-width: 1024px) { --capsules-slider__slick-list--side-padding: 9vw; }
    @media screen and (max-width: 768px)  { --capsules-slider__slick-list--side-padding: 30px; }
    @media screen and (max-width: 600px)  { --capsules-slider__slick-list--side-padding: 25px; }
    @media screen and (max-width: 450px)  { --capsules-slider__slick-list--side-padding: 20px; }
    @media screen and (max-width: 375px)  { --capsules-slider__slick-list--side-padding: 15px; }
    --capsules-slider__slide--side-padding: 60px;
    @media screen and (max-width: 1400px) { --capsules-slider__slide--side-padding: 40px; }
    @media screen and (max-width: 1200px) { --capsules-slider__slide--side-padding: 30px; }
    @media screen and (max-width: 1024px) { --capsules-slider__slide--side-padding: 20px; }
    @media screen and (max-width: 768px)  { --capsules-slider__slide--side-padding: 10px; }
    @media screen and (max-width: 500px)  { --capsules-slider__slide--side-padding: 5px; }
}


// -----------------------
// Le slider des capsules
// -----------------------
.capsules-slider {
    position: relative;
    background: var(--primary-color);
    will-change: transform;
    overflow: hidden;

    &__container {
        padding: var(--y-spacing-190) 0;
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 800ms, transform 800ms var(--ease-out);

        @media screen and (max-width: 500px) {padding-top: 85px; padding-bottom: 85px;}
    }

    [data-fire] &__container {
        opacity: 1;
        transform: none;
    }

    &__shape {
        position: absolute;
        height: 85%;
        top: 7.5%;
        left: 50%;
        opacity: 0.2;
        transform: translateX(-50%);

        @media screen and (max-width: 600px) { top: 50px; height: calc(100% - 100px); }
        @media screen and (max-width: 500px) { top: 40px; height: calc(100% - 80px); }
    }

    &__title {
        position: relative;
        text-align: center;
        z-index: 2;
    }

    &__navigation {
        position: absolute;
        bottom: calc(100% - var(--capsules-slider__arrow-icon--dimension));
        left: calc(100% - var(--capsules-slider__slick-list--side-padding) + var(--capsules-slider__slide--side-padding));
        z-index: 2;

        @media screen and (max-width: 1200px)  { display: none; }
    }

    &__arrow {
        &--prev {
            margin-right: 35px;
        }
    }

    &__arrow-icon {
        width: var(--capsules-slider__arrow-icon--dimension);
        height: var(--capsules-slider__arrow-icon--dimension);
        fill: none;
        stroke: var(--primary-light-color);
        transition: stroke 300ms;

        &--prev {
            transform: rotate(180deg);
        }
    }

    &__arrow:hover &__arrow-icon {
        stroke: rgba(var(--primary-light-rgb-color), 0.7);
    }

    &__slider-container {
        position: relative;
    }

    &__slider {
        margin-top: -20px;
    }

    &__slider .slick-list {
        padding: 0 var(--capsules-slider__slick-list--side-padding) !important;
    }

    &__slide {
        position: relative;
        padding: 0 var(--capsules-slider__slide--side-padding);
    }

    &__link {
        width: 100%;
    }

    &__picture-container {
        position: relative;
        padding-top: 66%;
        width: 100%;

        @media screen and (max-width: 450px) { padding-top: 70%; }
    }
    
    &__picture {
        position: absolute;
        top: 7.5%;
        right: 0;
        bottom: 7.5%;
        left: 0;
        overflow: hidden;
        transition: top 500ms var(--ease-out), bottom 500ms var(--ease-out);

        @media screen and (max-width: 450px) { top: 0; bottom: 0; }
    }

    &__slider .slick-slide.slick-cloned {
        pointer-events: none;
    }

    &__slider .slick-slide.slick-current &__picture,
    &__slider .slick-slide.slickAnimate &__picture {
        top: 0%;
        bottom: 0%;
    }

    &__picture:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.5018382352941176) 0%, rgba(0,0,0,0) 25%);
        z-index: 1;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        left: 0;
        transition: transform 600ms var(--ease-out);
    }

    &__link:hover  &__img {
        transform: scale(1.05);
    }

    &__slide-content {
        position: absolute;
        width: 440px;
        padding: 60px 80px 60px 30px;
        right: 0;
        bottom: 10%;
        background: var(--secondary-color);
        opacity: 0;
        z-index: 2;
        transition: background 300ms, opacity 500ms;

        @media screen and (max-width: 1200px) { padding: 50px 75px 50px 25px; }
        @media screen and (max-width: 1024px) { padding: 40px 70px 40px 20px; }
        @media screen and (max-width: 768px)  {
            position: relative;
            margin-top: -67px;
            margin-left: 20px;
            margin-right: 20px;
            width: auto;
            max-width: 350px;
            padding: 30px 90px 30px 20px;
        }
        @media screen and (max-width: 600px) { margin-top: -50px; }
        @media screen and (max-width: 500px) { padding: 25px 60px 25px 25px; margin-top: -40px; }
        @media screen and (max-width: 400px) { margin-top: -25px; margin-left: 20px; }
    }

    &__slider .slick-slide.slick-current &__slide-content,
    &__slider .slick-slide.slickAnimate &__slide-content {
        opacity: 1;
    }
    
    &__link:hover &__slide-content {
        background: var(--primary-light-color);
    }
    
    &__slide-subtitle {
        display: block;
        margin-bottom: 10px;
        font-family: var(--font-family-1);
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--primary-dark-color);
        line-height: 1.5;
        text-transform: uppercase;
        opacity: 0.6;

        @media screen and (max-width: 768px) { margin-bottom: 5px; }
    }
    
    &__slide-title {
        font-family: var(--font-family-2);
        transition: color 300ms;
    }

    &__link:hover &__slide-title {
        color: var(--secondary-color);
    }

    &__play {
        position: absolute;
        right: 20px;
        bottom: 20px;

        @media screen and (max-width: 768px) { right: 15px; bottom: 15px; width: 38px; height: 38px; }
        @media screen and (max-width: 500px) { right: 10px; bottom: 10px; width: 30px; height: 30px; }
        
        &:before {
            @media screen and (max-width: 768px) { border-width: 6.5px 0 6.5px 11.3px; }
            @media screen and (max-width: 500px) { border-width: 5px 0 5px 8.7px; }
        }
    }

    &__link:hover &__play {
        background: var(--secondary-color);
    }

    &__link:hover &__play:before {
        border-color: transparent transparent transparent var(--primary-light-color);
    }
}