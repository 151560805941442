:root {
    --rubric__font-size: 3rem;
    --rubric__subtitle-font-size: 1.4rem;

    --rubric__height: 125px;
    --rubric__thumb-width: 140px;
    --rubric__thumb-height: 140px;

    --rubric__color: var(--primary-color);
    --rubric__color-hover:var(--secondary-color);

    --rubric__plus-size: 20px;
    --rubric__plus-color: var(--primary-color);
    --rubric__plus-color-hover: var(--secondary-color);

    @media screen and (max-width: 700px) {
        --rubric__font-size: 2.8rem;
        --rubric__height: 120px;
        --rubric__thumb-width: 120px;
        --rubric__thumb-height: 120px;
    }

    @media screen and (max-width: 440px) { --rubric__font-size: 2.5rem; }
}

.rubric {
    position: relative;
    border-bottom: 1px solid var(--primary-color);
    background: transparent;
    transition: border-bottom 700ms;

    &:first-child { border-top: 1px solid var(--primary-color); }

    &__header {
        position: relative;
        cursor: pointer;
        padding: 20px 115px 20px 40px;
        min-height: var(--rubric__height);
        display: flex;
        align-items: center;

        @media screen and (max-width: 600px) { padding: 20px 75px 20px 30px; }
        @media screen and (max-width: 500px) { padding: 20px 50px 20px 0px; }
    }

    &__picture {
        width: var(--rubric__thumb-width);
        height: var(--rubric__thumb-height);
        border-radius: 50%;
        border: 1px solid var(--primary-color);
        margin-right: 30px;
        overflow: hidden;
        flex-shrink: 0;
        @media screen and (max-width: 600px)  { display: none; }
    }

    &__subtitle {
        font-family: var(--font-family-1);
        font-size: var(--rubric__subtitle-font-size);
        color: var(--rubric__color);
        font-weight: 600;
        line-height: 1.7em;
        transition: color 0.3s;
    }

    &__title {
        font-family: var(--font-family-2);
        font-size: var(--rubric__font-size);
        line-height: 1.6;
        color: var(--rubric__color);
        font-weight: 700;
        transition: color 0.3s;
    }

    &__plus {
        position: absolute;
        width: var(--rubric__plus-size);
        height: var(--rubric__plus-size);
        top: 50%;
        right: 40px;
        transform: translateY(-50%);

        &::before,
        &::after{
            content: '';
            position: absolute;
            width: var(--rubric__plus-size);
            height: 2px;
            background-color: var(--rubric__plus-color);
            left: 50%;
            top: 50%;
            transition: background-color 0.3s, opacity 0.3s;
            transform: translate(-50%,-50%);
            opacity: 1;
        }
        &::after{
            transform: translate(-50%,-50%) rotate(90deg);
        }

        @media screen and (max-width: 600px) { right: 30px; }
        @media screen and (max-width: 500px) { right: 0; }
    }

    &__header:hover{
        .rubric__subtitle { color:var(--rubric__color-hover); }
        .rubric__title { color:var(--rubric__color-hover); }
        .rubric__plus {
            &::before,
            &::after{
                background-color: var(--rubric__plus-color-hover);
            }
        }
    }

    &__content {
        height: 0;
        will-change: height;
        overflow: hidden;
        @media screen and (max-width: 1024px) { padding: 0; }
    }

    &__transition {
        padding: 0 40px;
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms var(--ease-in-out), transform 700ms var(--ease-in-out);
        will-change: transform;

        @media screen and (max-width: 600px) { padding: 0 30px; }
        @media screen and (max-width: 500px) { padding: 0; }
    }
    
    &__transition > :first-child { margin-top: 0; }

    &__icon-box {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
    }

    &__icon-box-text {
        font-family: var(--font-family-1);
        font-size: 1.8rem;
        font-weight: 700;
        color: var(--primary-color);
        margin-bottom: 15px;
    }

    &__icon-box-categories { 
        display: flex; 
        flex-wrap: wrap;

        @media screen and (max-width: 600px) { 
            flex-direction: column; 
            flex-wrap: nowrap;
        }
    }

    &__icon-box-category {
        margin-top: 10px;
        margin-right: 30px;
        display: flex;
        align-items: center;
    }

    &__icon-box-icon-container {
        width: 40px;
        height: 40px;
        border: 1px solid var(--primary-color);
        border-radius: 50%;
        margin-right: 15px;
        position: relative;
    }

    &__icon-box-icon {
        width: 21px;
        height: 21px;
        fill: var(--primary-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &__icon-box-span {
        font-family: var(--font-family-1);
        font-size: 1.7rem;
        color: var(--primary-color);
    }

    &__dynamic {
        h3 {
            margin-top: 50px;
            margin-bottom: 25px;
            line-height: 1.45em;
            font-weight: 600;
            font-size: 2.2rem;
            @media screen and (max-width: 375px) { font-size: 2.1rem; }
            @media screen and (max-width: 320px) { font-size: 2rem; }
        }
        h4 {
            margin-top: 50px;
            margin-bottom: 25px;
            line-height: 1.5em;
            font-size: 1.8rem;
            font-weight: 700;
        }
    }

    &__document, &__gallery { margin-bottom: var(--y-spacing-60); }

    &__document:last-child{
        padding-bottom: var(--y-spacing-30);
        margin-bottom: 0;
    }

    &__document--section {
            
        margin-top: var(--y-spacing-60);

        .rubric__document {
            .document {
                border: none; margin-bottom: 10px;

                &__link {
                    background: var(--secondary-light-color);
                    transition: background 300ms;

                    &:hover {
                        background: var(--primary-color);

                        .document__title {
                            color: var(--primary-light-color);
                        }
                        .document__svg {
                            fill: var(--primary-light-color);
                        }
                    }

                    .document__icon {
                        @media screen and (max-width: 500px) {
                            right: 25px;
                        }
                    }
                }

                &__content{
                    margin-left: 35px;

                    @media screen and (max-width: 600px) {
                        margin-left: 20px;
                    }

                    @media screen and (max-width: 500px) {
                        margin-left: 15px;
                    }
                }
            }
        }
    }

    &__document--section-header {
        display: flex;
        align-items: center;

        .icon {
            width: 70px;
            height: 70px;
            background: var(--primary-light-color);
            border: 1px solid var(--other-3);
            border-radius: 50%;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .document__svg {
                width: 60%;
                height: 60%;
                fill: var(--primary-color);

                @media screen and (max-width: 500px) {
                width: 70%;
                height: 70%;
                }
            }

            @media screen and (max-width: 500px) {
                width: 55px;
                height: 55px;
            }
        }

        .rubric__title {
            color: var(--primary-color) !important;
            margin: 0 30px;
            flex-shrink: 0;

            @media screen and (max-width: 600px) {
                margin: 0 25px;
            }

            @media screen and (max-width: 500px) {
                margin: 0 20px;
            }
        }

        .line {
            width: 100%;
            height: 1px;
            background: #0c4654;
        }
    }

    &__dynamic:last-child{
        padding-bottom: var(--y-spacing-90);
        margin-bottom: 0;
    }

    &__galery:last-child{
        padding-bottom: var(--y-spacing-90);
        margin-bottom: 0;
    }

    &__share {
        margin-top: var(--y-spacing-60);
        margin-bottom: 0;
        padding-bottom: var(--y-spacing-60);
    }

}

.fancybox-caption__body { font-family: var(--font-family-1); }


/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {

    .rubric__subtitle, .rubric__title { color: var(--secondary-color); }
    .rubric__plus {
        &::before,
        &::after{
            background-color: var(--rubric__plus-color-hover);
        }
    }

    .rubric__transition {
        opacity: 1;
        transform: none;
        transition: opacity 700ms var(--ease-in-out) 100ms, transform 700ms var(--ease-in-out) 100ms;
    }

    .rubric__plus {
        &::after{
            opacity: 0;
        }
    }
}