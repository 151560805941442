//-------------------
// Variables locales
//-------------------
.contact-box {
    --content-width: 520px;

    @media screen and (max-width: 1024px) { --content-width: 420px; }
    @media screen and (max-width: 768px) { --content-width: 320px; }
}


.contact-box {
    &__container { 
        display: flex; 

        @media screen and (max-width: 500px) { 
            flex-direction: column; 
            max-height: 100%;
        }
    }

    &__content {
        width: var(--content-width);
        background: var(--primary-color);
        padding: var(--y-spacing-110) 120px var(--y-spacing-130);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 1400px) { padding: var(--y-spacing-120) var(--x-spacing-120); }
        @media screen and (max-width: 500px) { width: 100%; }
    } 

    &__content-top, &__content-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    // &__content-top { margin-bottom: var(--y-spacing-90); } 

    &__title { 
        color: #fff;
        margin-bottom: 30px;

        &.small-title {
            font-weight: 600;
        }
    }

    &__subtitle {
        display: block;
        font-size: 2rem;
    }

    &__address, &__phones {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__phones { 
        margin-top: 20px;

        .paragraph:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__text { color: #fff; }

    &__numbers { 
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        box-shadow: inset 0 -0.4em 0 0 #5c828c;
        // box-shadow: inset 0 -0.4em 0 0 rgba(0, 200, 155,0.2);
        transition: box-shadow 0.3s;
        display: inline;

        &:hover{
            box-shadow: inset 0 -1.1em 0 0 #5c828c;
            // box-shadow: inset 0 -1.1em 0 0 rgba(0, 200, 155,0.2);
        }
    }

    &__button { 
        font-family: var(--font-family-1);
        font-size: 1.5rem;
        font-weight: 700;
        color: #fff;
        transition: color 300ms;
        padding-right: 25px;
        margin-top: 50px;
        position: relative;

        &:hover { color: var(--other-5); }

        @media screen and (max-width: 600px) { margin-top: 30px; }
    }

    &__button-icon {
        width: 10px;
        height: 17px;
        stroke: #fff;
        transition: stroke 300ms;
        stroke-width: 2px;
        fill: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    &__button:hover &__button-icon { stroke: var(--other-5); }

    &__picture { 
        width: calc(100% - var(--content-width)); 

        @media screen and (max-width: 500px) { width: 100%; }
    }

    // &__link {
    //     color: var(--dynamic-main-color);
    //     text-decoration: none;
    //     box-shadow: inset 0 -0.4em 0 0 rgba(var(--dynamic-main-color-rgb),0.2);
    //     transition: box-shadow 0.3s;
    //     display: inline;

    //     &:hover{
    //         box-shadow: inset 0 -1.1em 0 0 rgba(var(--dynamic-main-color-rgb),0.2);
    //     }
    // }
}