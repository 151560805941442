//--------------------
// Variables globales
//--------------------
:root {

}


//-------------------
// Variables locales
//-------------------
.link {

}


// -----------------------
// Un bouton de type lien
// -----------------------
.link {
    display: inline-flex;
    position: relative;
    padding-right: 25px;
    align-items: center;
    font-family: var(--font-family-1);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    color: var(--secondary-color);
    transition: color 300ms;

    &:before, &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 14px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: opacity 300ms;
    }

    &:before {
        background: url('../medias/images/icons/orange-pointer.svg') no-repeat center / contain;
        opacity: 1;
    }

    &:after {
        background: url('../medias/images/icons/blue-pointer.svg') no-repeat center / contain;
        opacity: 0;
    }
    
    &:hover {
        color: var(--primary-color);
    }

    &:hover:before {
        opacity: 0;
    }

    &:hover:after {
        opacity: 1;
    }
}